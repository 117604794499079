import React from "react";
import {Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {mt, w100} from "@pg-design/helpers-css";
import {countDistance} from "@pg-mono/geo-utils";
import {convertToLatLngLiteralOfPoland} from "@pg-mono/open-street-map";

import {validationMessages} from "../../form/utils/validation_messages";
import {IUserPoi} from "../types/IUserPoi";
import {isValidLocationObject} from "../utils/is_valid_location_object";
import {PlacesAutocomplete} from "./PlacesAutocomplete";

const defaultInitialValues = {
    location: {
        label: "",
        value: "" as unknown as number,
        coordinates: [] as number[]
    }
};

export type SinglePoiFormValuesType = typeof defaultInitialValues;

const validationSchema = Yup.object({
    location: Yup.mixed().test({message: validationMessages.required, test: (values) => isValidLocationObject(values)})
});

interface IProps {
    targetCoords: [number, number];
    onChange?: (userPoi: IUserPoi) => void;
}

export const SinglePoiForm = (props: IProps) => {
    const onSubmit = (values: SinglePoiFormValuesType, {setSubmitting}: FormikHelpers<SinglePoiFormValuesType>) => {
        if (props.targetCoords) {
            const {
                location: {label}
            } = values;

            const {lat, lng} = convertToLatLngLiteralOfPoland(values.location.coordinates);
            const {lng: targetLng, lat: targetLat} = convertToLatLngLiteralOfPoland(props.targetCoords);
            const distance = countDistance({lat, lng}, {lat: targetLat, lng: targetLng});

            setSubmitting(false);

            const newUserPoi = {id: Date.now(), name: "Moje miejsce", distance, lat, lng, tags: {address: label}};
            props.onChange?.(newUserPoi);
        }
    };

    return (
        <Formik initialValues={{...defaultInitialValues}} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {(formikProps) => {
                return (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div>
                            <PlacesAutocomplete name="location" placeholder="Wpisz adres" allowEditLastValue disableDropdownIndicator />
                        </div>

                        <Button css={[w100, mt(2)]} type="submit" disabled={formikProps.isSubmitting} variant="filled_primary">
                            Sprawdź czas dojazdu
                        </Button>
                    </form>
                );
            }}
        </Formik>
    );
};
import React from "react";
import {Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {mt, w100} from "@pg-design/helpers-css";
import {countDistance} from "@pg-mono/geo-utils";
import {convertToLatLngLiteralOfPoland} from "@pg-mono/open-street-map";

import {validationMessages} from "../../form/utils/validation_messages";
import {IUserPoi} from "../types/IUserPoi";
import {isValidLocationObject} from "../utils/is_valid_location_object";
import {PlacesAutocomplete} from "./PlacesAutocomplete";

const defaultInitialValues = {
    location: {
        label: "",
        value: "" as unknown as number,
        coordinates: [] as number[]
    }
};

export type SinglePoiFormValuesType = typeof defaultInitialValues;

const validationSchema = Yup.object({
    location: Yup.mixed().test({message: validationMessages.required, test: (values) => isValidLocationObject(values)})
});

interface IProps {
    targetCoords: [number, number];
    onChange?: (userPoi: IUserPoi) => void;
}

export const SinglePoiForm = (props: IProps) => {
    const onSubmit = (values: SinglePoiFormValuesType, {setSubmitting}: FormikHelpers<SinglePoiFormValuesType>) => {
        if (props.targetCoords) {
            const {
                location: {label}
            } = values;

            const {lat, lng} = convertToLatLngLiteralOfPoland(values.location.coordinates);
            const {lng: targetLng, lat: targetLat} = convertToLatLngLiteralOfPoland(props.targetCoords);
            const distance = countDistance({lat, lng}, {lat: targetLat, lng: targetLng});

            setSubmitting(false);

            const newUserPoi = {id: Date.now(), name: "Moje miejsce", distance, lat, lng, tags: {address: label}};
            props.onChange?.(newUserPoi);
        }
    };

    return (
        <Formik initialValues={{...defaultInitialValues}} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {(formikProps) => {
                return (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div>
                            <PlacesAutocomplete name="location" placeholder="Wpisz adres" allowEditLastValue disableDropdownIndicator />
                        </div>

                        <Button css={[w100, mt(2)]} type="submit" disabled={formikProps.isSubmitting} variant="filled_primary">
                            Sprawdź czas dojazdu
                        </Button>
                    </form>
                );
            }}
        </Formik>
    );
};
