import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {IRPStore} from "../../app/rp_reducer";
import {setActivePoi} from "../actions/set_poi_travel_directions";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType} from "../utils/PoiType";

const user_pin = require("../images/pins/user_pin.svg");

interface IHook {
    userPoiMarkers: IMarker[];
}

export const useUserPoi = (offer?: {geo_point: {coordinates: [number, number]}}): IHook => {
    const dispatch = useDispatch();

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates);

    const userPoiMarkers = useMemo(() => {
        return userPoi.map((poi) => getPoiMarker(poi, PoiType.USER, user_pin, {listenToActivePoiDirections: true}));
    }, [userPoi]);

    return {
        userPoiMarkers
    };
};
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {IRPStore} from "../../app/rp_reducer";
import {setActivePoi} from "../actions/set_poi_travel_directions";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType} from "../utils/PoiType";

const user_pin = require("../images/pins/user_pin.svg");

interface IHook {
    userPoiMarkers: IMarker[];
}

export const useUserPoi = (offer?: {geo_point: {coordinates: [number, number]}}): IHook => {
    const dispatch = useDispatch();

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates);

    const userPoiMarkers = useMemo(() => {
        return userPoi.map((poi) => getPoiMarker(poi, PoiType.USER, user_pin, {listenToActivePoiDirections: true}));
    }, [userPoi]);

    return {
        userPoiMarkers
    };
};
