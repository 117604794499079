import {Dispatch} from "redux";

import {createRequestActionTypes} from "@pg-mono/request-state";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {getRouteGeometry, IGetRouteParams} from "../api/get_route_geometry";

const Fetch_public_transport_route = "overpass/FETCH_PUBLIC_TRANSPORT_ROUTE";
export const fetchPublicTransportRouteTypes = createRequestActionTypes(Fetch_public_transport_route);

export const fetchPublicTransportRoute = (params: IGetRouteParams) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const storedPoiRoute = getState().maps.poi.poiRoute?.[params.poiId];

    if (storedPoiRoute) {
        return storedPoiRoute;
    }

    dispatch({type: fetchPublicTransportRouteTypes.start});

    return getRouteGeometry(params)
        .then((routeWithStops) => {
            dispatch({
                type: fetchPublicTransportRouteTypes.success,
                result: {
                    [params.poiId]: routeWithStops
                }
            });

            return routeWithStops;
        })
        .catch(() => {
            dispatch({type: fetchPublicTransportRouteTypes.error});
            return {};
        });
};
import {Dispatch} from "redux";

import {createRequestActionTypes} from "@pg-mono/request-state";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {getRouteGeometry, IGetRouteParams} from "../api/get_route_geometry";

const Fetch_public_transport_route = "overpass/FETCH_PUBLIC_TRANSPORT_ROUTE";
export const fetchPublicTransportRouteTypes = createRequestActionTypes(Fetch_public_transport_route);

export const fetchPublicTransportRoute = (params: IGetRouteParams) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const storedPoiRoute = getState().maps.poi.poiRoute?.[params.poiId];

    if (storedPoiRoute) {
        return storedPoiRoute;
    }

    dispatch({type: fetchPublicTransportRouteTypes.start});

    return getRouteGeometry(params)
        .then((routeWithStops) => {
            dispatch({
                type: fetchPublicTransportRouteTypes.success,
                result: {
                    [params.poiId]: routeWithStops
                }
            });

            return routeWithStops;
        })
        .catch(() => {
            dispatch({type: fetchPublicTransportRouteTypes.error});
            return {};
        });
};
