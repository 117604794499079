import {useEffect, useRef} from "react";

interface IParams {
    callback: (eventType: string) => void;
    once?: boolean;
}

export const useElementInteractionObserver = <TElementType extends HTMLElement>(params: IParams) => {
    const elementRef = useRef<TElementType>(null);

    useEffect(() => {
        const element = elementRef.current;

        if (!element) {
            return;
        }

        const handleClick = () => {
            params.callback("click");

            if (params.once) {
                element.removeEventListener("click", handleClick, true);
            }
        };

        const handleScroll = () => {
            params.callback("scroll");

            if (params.once) {
                element.removeEventListener("scroll", handleScroll, true);
            }
        };

        const handleTouch = () => {
            params.callback("touch");

            if (params.once) {
                element.removeEventListener("touchstart", handleTouch, true);
            }
        };

        element.addEventListener("click", handleClick, true);
        element.addEventListener("scroll", handleScroll, true);
        element.addEventListener("touchstart", handleTouch, true);

        return () => {
            element?.removeEventListener("click", handleClick, true);
            element?.removeEventListener("scroll", handleScroll, true);
            element?.removeEventListener("touchstart", handleTouch, true);
        };
    }, []);

    return elementRef;
};
import {useEffect, useRef} from "react";

interface IParams {
    callback: (eventType: string) => void;
    once?: boolean;
}

export const useElementInteractionObserver = <TElementType extends HTMLElement>(params: IParams) => {
    const elementRef = useRef<TElementType>(null);

    useEffect(() => {
        const element = elementRef.current;

        if (!element) {
            return;
        }

        const handleClick = () => {
            params.callback("click");

            if (params.once) {
                element.removeEventListener("click", handleClick, true);
            }
        };

        const handleScroll = () => {
            params.callback("scroll");

            if (params.once) {
                element.removeEventListener("scroll", handleScroll, true);
            }
        };

        const handleTouch = () => {
            params.callback("touch");

            if (params.once) {
                element.removeEventListener("touchstart", handleTouch, true);
            }
        };

        element.addEventListener("click", handleClick, true);
        element.addEventListener("scroll", handleScroll, true);
        element.addEventListener("touchstart", handleTouch, true);

        return () => {
            element?.removeEventListener("click", handleClick, true);
            element?.removeEventListener("scroll", handleScroll, true);
            element?.removeEventListener("touchstart", handleTouch, true);
        };
    }, []);

    return elementRef;
};
