import {useEffect, useRef} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useRoutedModalState} from "./use_routed_modal_state";

interface IParams {
    routeParam: string;
    value?: string;
    idOpenStoreState: boolean;
    disableOpenAction?: boolean;
    removeRouteParamFromUrlOnFirstMount?: boolean;
    setModalState: (state: boolean) => void;
}

export const useRoutedModalStateSync = (params: IParams) => {
    const location = useLocation();
    const history = useHistory();
    const routedModal = useRoutedModalState(params.routeParam, params.value);
    const prev = useRef({
        paramsModalState: params.idOpenStoreState,
        routedModalState: routedModal.modalState
    });

    const setPrev = (state: boolean) => {
        prev.current = {
            paramsModalState: state,
            routedModalState: state
        };
    };

    useEffect(() => {
        // TODO: temporary solution, in the future it will be necessary to create URL based modal navigation
        if (useRoutedModalStateSync.hasBeenMounted) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);

        if (params.routeParam && params.removeRouteParamFromUrlOnFirstMount && searchParams.has(params.routeParam)) {
            searchParams.delete(params.routeParam);
            history.replace({search: searchParams.toString()});
        }

        useRoutedModalStateSync.hasBeenMounted = true;
    }, []);

    useEffect(() => {
        if (params.idOpenStoreState !== prev.current.paramsModalState) {
            if (params.idOpenStoreState && params.disableOpenAction) {
                return;
            }

            if (params.idOpenStoreState) {
                routedModal.openModal();
                setPrev(true);
            } else {
                routedModal.closeModal();
                setPrev(false);
            }
        } else if (routedModal.modalState !== prev.current.routedModalState) {
            if (routedModal.modalState && params.disableOpenAction) {
                return;
            }

            params.setModalState(routedModal.modalState);
            setPrev(routedModal.modalState);
        }
    }, [routedModal.modalState, params.idOpenStoreState]);

    return {
        ...routedModal,
        isOpen: params.disableOpenAction ? false : routedModal.modalState,
        modalState: undefined
    };
};

useRoutedModalStateSync.hasBeenMounted = false;
import {useEffect, useRef} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useRoutedModalState} from "./use_routed_modal_state";

interface IParams {
    routeParam: string;
    value?: string;
    idOpenStoreState: boolean;
    disableOpenAction?: boolean;
    removeRouteParamFromUrlOnFirstMount?: boolean;
    setModalState: (state: boolean) => void;
}

export const useRoutedModalStateSync = (params: IParams) => {
    const location = useLocation();
    const history = useHistory();
    const routedModal = useRoutedModalState(params.routeParam, params.value);
    const prev = useRef({
        paramsModalState: params.idOpenStoreState,
        routedModalState: routedModal.modalState
    });

    const setPrev = (state: boolean) => {
        prev.current = {
            paramsModalState: state,
            routedModalState: state
        };
    };

    useEffect(() => {
        // TODO: temporary solution, in the future it will be necessary to create URL based modal navigation
        if (useRoutedModalStateSync.hasBeenMounted) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);

        if (params.routeParam && params.removeRouteParamFromUrlOnFirstMount && searchParams.has(params.routeParam)) {
            searchParams.delete(params.routeParam);
            history.replace({search: searchParams.toString()});
        }

        useRoutedModalStateSync.hasBeenMounted = true;
    }, []);

    useEffect(() => {
        if (params.idOpenStoreState !== prev.current.paramsModalState) {
            if (params.idOpenStoreState && params.disableOpenAction) {
                return;
            }

            if (params.idOpenStoreState) {
                routedModal.openModal();
                setPrev(true);
            } else {
                routedModal.closeModal();
                setPrev(false);
            }
        } else if (routedModal.modalState !== prev.current.routedModalState) {
            if (routedModal.modalState && params.disableOpenAction) {
                return;
            }

            params.setModalState(routedModal.modalState);
            setPrev(routedModal.modalState);
        }
    }, [routedModal.modalState, params.idOpenStoreState]);

    return {
        ...routedModal,
        isOpen: params.disableOpenAction ? false : routedModal.modalState,
        modalState: undefined
    };
};

useRoutedModalStateSync.hasBeenMounted = false;
