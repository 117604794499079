import React from "react";

import {componentContainerStyle, hiddenInputStyle, labelStyle, switcherStyle, switchIndicatorStyle, switchStyle, switchWrapStyle} from "./Switcher.module.css";

export type ISwitcherProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange"> & {
    checked: boolean;
    onChange: (value: boolean) => void;
    id: string;
    variant?: "small" | "big";
    labelContent?: JSX.Element | string;
} & ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export const Switcher = (props: ISwitcherProps) => {
    const {onChange, checked, variant, id, labelContent, ...inputProps} = props;

    const switchDynamicStyle = {
        ...(variant === "big" && {width: "4.8rem", height: "2.4rem", padding: "0.26rem"}),
        background: checked ? "var(--colors-info)" : "var(--colors-gray-500)"
    };
    const switchIndicatorDynamicStyle =
        variant === "big"
            ? {transform: checked ? "translateX(2.41rem)" : "none", width: "1.867rem", height: "1.867rem"}
            : {transform: checked ? "translateX(1.8rem)" : "none"};

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === " ") {
            onChange(checked);
        }
    };

    return (
        <div className={componentContainerStyle}>
            {labelContent && (
                <label className={labelStyle} htmlFor={id}>
                    {labelContent}
                </label>
            )}
            <div className={switcherStyle}>
                <input
                    className={hiddenInputStyle}
                    {...inputProps}
                    type="checkbox"
                    checked={checked}
                    onChange={({target}) => {
                        onChange(target.checked);
                    }}
                    onKeyDown={onKeyDown}
                    id={id}
                />
                <div className={switchWrapStyle}>
                    <div className={switchStyle} style={switchDynamicStyle}>
                        <div className={switchIndicatorStyle} style={switchIndicatorDynamicStyle} />
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";

import {componentContainerStyle, hiddenInputStyle, labelStyle, switcherStyle, switchIndicatorStyle, switchStyle, switchWrapStyle} from "./Switcher.module.css";

export type ISwitcherProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange"> & {
    checked: boolean;
    onChange: (value: boolean) => void;
    id: string;
    variant?: "small" | "big";
    labelContent?: JSX.Element | string;
} & ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export const Switcher = (props: ISwitcherProps) => {
    const {onChange, checked, variant, id, labelContent, ...inputProps} = props;

    const switchDynamicStyle = {
        ...(variant === "big" && {width: "4.8rem", height: "2.4rem", padding: "0.26rem"}),
        background: checked ? "var(--colors-info)" : "var(--colors-gray-500)"
    };
    const switchIndicatorDynamicStyle =
        variant === "big"
            ? {transform: checked ? "translateX(2.41rem)" : "none", width: "1.867rem", height: "1.867rem"}
            : {transform: checked ? "translateX(1.8rem)" : "none"};

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === " ") {
            onChange(checked);
        }
    };

    return (
        <div className={componentContainerStyle}>
            {labelContent && (
                <label className={labelStyle} htmlFor={id}>
                    {labelContent}
                </label>
            )}
            <div className={switcherStyle}>
                <input
                    className={hiddenInputStyle}
                    {...inputProps}
                    type="checkbox"
                    checked={checked}
                    onChange={({target}) => {
                        onChange(target.checked);
                    }}
                    onKeyDown={onKeyDown}
                    id={id}
                />
                <div className={switchWrapStyle}>
                    <div className={switchStyle} style={switchDynamicStyle}>
                        <div className={switchIndicatorStyle} style={switchIndicatorDynamicStyle} />
                    </div>
                </div>
            </div>
        </div>
    );
};
