import {FormFieldType, toQueryValues} from "@pg-mono/form";

import {useGetPropertyListQuery} from "../../property/api/get_property_list";
import {IPropertySortOption} from "../../property/interfaces/IPropertySortOption";
import {IGetPropertyListPayload} from "../../property/types/GetPropertyListPayload";
import {useAppSelector} from "../../store/hooks";
import {useUserPreferencesFilters} from "../../user/hooks/use_user_preferences_filters";
import {offerDetailPropertiesListConstraints} from "../constants/offer_detail_properties_list_constraints";
import {IOfferModalName} from "../types/OfferModalName";

export function useOfferModalProperties(modalName: IOfferModalName, userPreferenceFilters?: ReturnType<typeof useUserPreferencesFilters>) {
    const isOpen = useAppSelector((state) => state.offerModals[modalName].isOpen);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);
    const sortOption = useAppSelector((state) => state.offerModals[modalName].sort);
    const page = useAppSelector((state) => state.offerModals[modalName].page);
    const pageSize = useAppSelector((state) => state.offerModals[modalName].pageSize);
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);

    const offerModalPropertiesFieldTypes = {
        area: FormFieldType.InputRange,
        rooms: FormFieldType.SelectRangeNumber,
        floor_choices: FormFieldType.MultiCheckbox,
        price: FormFieldType.InputRange
    };

    const queryFilters = {...toQueryValues(offerModalPropertiesFieldTypes, filters)};

    const fullQuery: IGetPropertyListPayload = {
        ...offerDetailPropertiesListConstraints,
        offer: offerId,
        page,
        page_size: pageSize,
        sort: sortOption ? getQuerySort(sortOption) : sortOption,
        ...queryFilters,
        ...getParsedUserPreferencesFilters(userPreferenceFilters)
    };

    const {currentData, isLoading, isFetching, isUninitialized} = useGetPropertyListQuery(fullQuery, {
        skip: !isOpen
    });

    return {
        queryData: currentData,
        isLoading: isLoading || isFetching,
        isUninitialized
    };
}

export type IUseOfferDetailPropertiesQuery = ReturnType<typeof useOfferModalProperties>;

//  Utils
function getQuerySort(sortOption: IPropertySortOption) {
    const sortMark = sortOption.value === "asc" ? "" : "-";

    return `${sortMark}${sortOption.fieldName}`;
}

function getParsedUserPreferencesFilters(filters?: ReturnType<typeof useUserPreferencesFilters>) {
    if (!filters) {
        return {};
    }

    const {construction_end_date, floor_choices} = filters;

    const payload: Omit<IGetPropertyListPayload, "page"> = {};

    if (construction_end_date) {
        payload.construction_end_date = parseInt(construction_end_date, 10);
    }

    if (floor_choices) {
        payload.floor_choices = floor_choices.map((floor_choice) => parseInt(floor_choice, 10));
    }

    return payload;
}
import {FormFieldType, toQueryValues} from "@pg-mono/form";

import {useGetPropertyListQuery} from "../../property/api/get_property_list";
import {IPropertySortOption} from "../../property/interfaces/IPropertySortOption";
import {IGetPropertyListPayload} from "../../property/types/GetPropertyListPayload";
import {useAppSelector} from "../../store/hooks";
import {useUserPreferencesFilters} from "../../user/hooks/use_user_preferences_filters";
import {offerDetailPropertiesListConstraints} from "../constants/offer_detail_properties_list_constraints";
import {IOfferModalName} from "../types/OfferModalName";

export function useOfferModalProperties(modalName: IOfferModalName, userPreferenceFilters?: ReturnType<typeof useUserPreferencesFilters>) {
    const isOpen = useAppSelector((state) => state.offerModals[modalName].isOpen);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);
    const sortOption = useAppSelector((state) => state.offerModals[modalName].sort);
    const page = useAppSelector((state) => state.offerModals[modalName].page);
    const pageSize = useAppSelector((state) => state.offerModals[modalName].pageSize);
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);

    const offerModalPropertiesFieldTypes = {
        area: FormFieldType.InputRange,
        rooms: FormFieldType.SelectRangeNumber,
        floor_choices: FormFieldType.MultiCheckbox,
        price: FormFieldType.InputRange
    };

    const queryFilters = {...toQueryValues(offerModalPropertiesFieldTypes, filters)};

    const fullQuery: IGetPropertyListPayload = {
        ...offerDetailPropertiesListConstraints,
        offer: offerId,
        page,
        page_size: pageSize,
        sort: sortOption ? getQuerySort(sortOption) : sortOption,
        ...queryFilters,
        ...getParsedUserPreferencesFilters(userPreferenceFilters)
    };

    const {currentData, isLoading, isFetching, isUninitialized} = useGetPropertyListQuery(fullQuery, {
        skip: !isOpen
    });

    return {
        queryData: currentData,
        isLoading: isLoading || isFetching,
        isUninitialized
    };
}

export type IUseOfferDetailPropertiesQuery = ReturnType<typeof useOfferModalProperties>;

//  Utils
function getQuerySort(sortOption: IPropertySortOption) {
    const sortMark = sortOption.value === "asc" ? "" : "-";

    return `${sortMark}${sortOption.fieldName}`;
}

function getParsedUserPreferencesFilters(filters?: ReturnType<typeof useUserPreferencesFilters>) {
    if (!filters) {
        return {};
    }

    const {construction_end_date, floor_choices} = filters;

    const payload: Omit<IGetPropertyListPayload, "page"> = {};

    if (construction_end_date) {
        payload.construction_end_date = parseInt(construction_end_date, 10);
    }

    if (floor_choices) {
        payload.floor_choices = floor_choices.map((floor_choice) => parseInt(floor_choice, 10));
    }

    return payload;
}
