import {Dispatch} from "redux";

import {getExternalRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

export interface IFetchOsmPoiRoutesParams {
    poiId: number;
}

export type IOsmPoiRoutes = Record<string, IPublicTransportRoute[]>;

const FETCH_POI_ROUTES = "overpass/FETCH_POI_ROUTES";
export const fetchPoiRoutesTypes = createRequestActionTypes(FETCH_POI_ROUTES);

export const fetchOsmPoiRoutes = (params: IFetchOsmPoiRoutesParams) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const storedPoiRoutes = getState().maps.poi.poisRoutes?.[params.poiId];

    if (storedPoiRoutes) {
        return storedPoiRoutes;
    }

    const url = `${osmApiUrl}/geo-assets/poi/${params.poiId}/routes/`;

    dispatch({type: fetchPoiRoutesTypes.start});

    return getExternalRequest({}, url, 5000)
        .then((routes: IOsmPoiRoutes) => {
            const results = Object.keys(routes).reduce((acc, routeKey) => {
                acc[routeKey] = routes[routeKey];
                return acc;
            }, {} as IOsmPoiRoutes);

            dispatch({type: fetchPoiRoutesTypes.success, result: results});
            return results;
        })
        .catch(() => {
            dispatch({type: fetchPoiRoutesTypes.error});
            return {};
        });
};
import {Dispatch} from "redux";

import {getExternalRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

export interface IFetchOsmPoiRoutesParams {
    poiId: number;
}

export type IOsmPoiRoutes = Record<string, IPublicTransportRoute[]>;

const FETCH_POI_ROUTES = "overpass/FETCH_POI_ROUTES";
export const fetchPoiRoutesTypes = createRequestActionTypes(FETCH_POI_ROUTES);

export const fetchOsmPoiRoutes = (params: IFetchOsmPoiRoutesParams) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const storedPoiRoutes = getState().maps.poi.poisRoutes?.[params.poiId];

    if (storedPoiRoutes) {
        return storedPoiRoutes;
    }

    const url = `${osmApiUrl}/geo-assets/poi/${params.poiId}/routes/`;

    dispatch({type: fetchPoiRoutesTypes.start});

    return getExternalRequest({}, url, 5000)
        .then((routes: IOsmPoiRoutes) => {
            const results = Object.keys(routes).reduce((acc, routeKey) => {
                acc[routeKey] = routes[routeKey];
                return acc;
            }, {} as IOsmPoiRoutes);

            dispatch({type: fetchPoiRoutesTypes.success, result: results});
            return results;
        })
        .catch(() => {
            dispatch({type: fetchPoiRoutesTypes.error});
            return {};
        });
};
