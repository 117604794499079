import {detailOfferListConstraints} from "../constants/detail_offer_list_constraints";
import {DEFAULT_DISTANCE_NEARBY_OFFERS} from "../list/constants/offer_list";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";
import {IGetNearbyMapOffersPayloadParams} from "../types/IGetNearbyMapOffersPayloadParams";

export function getNearbyMapOffersPayload(params: IGetNearbyMapOffersPayloadParams): IGetOfferListPayload {
    const {
        page = 1,
        offer: {id, type: offerType}
    } = params;

    const AREA_FACTOR = 0.1;
    const AREA_MIN_DIFF = 5;
    const filters = {
        ...(params.offer.areaMin ? {area_0: Math.floor(Math.min(params.offer.areaMin * (1 - AREA_FACTOR), params.offer.areaMin - AREA_MIN_DIFF))} : {}),
        ...(params.offer.areaMax ? {area_1: Math.ceil(Math.max(params.offer.areaMax * (1 + AREA_FACTOR), params.offer.areaMax + AREA_MIN_DIFF))} : {}),
        ...(params.offer.roomsMin ? {rooms_0: params.offer.roomsMin} : {}),
        ...(params.offer.roomsMax ? {rooms_1: params.offer.roomsMax} : {})
    };

    return {
        ...detailOfferListConstraints,
        ...filters,
        page,
        page_size: 50,
        type: offerType,
        near_by_offer: id,
        exclude_offer: id,
        distance: params.distance || DEFAULT_DISTANCE_NEARBY_OFFERS
    };
}
import {detailOfferListConstraints} from "../constants/detail_offer_list_constraints";
import {DEFAULT_DISTANCE_NEARBY_OFFERS} from "../list/constants/offer_list";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";
import {IGetNearbyMapOffersPayloadParams} from "../types/IGetNearbyMapOffersPayloadParams";

export function getNearbyMapOffersPayload(params: IGetNearbyMapOffersPayloadParams): IGetOfferListPayload {
    const {
        page = 1,
        offer: {id, type: offerType}
    } = params;

    const AREA_FACTOR = 0.1;
    const AREA_MIN_DIFF = 5;
    const filters = {
        ...(params.offer.areaMin ? {area_0: Math.floor(Math.min(params.offer.areaMin * (1 - AREA_FACTOR), params.offer.areaMin - AREA_MIN_DIFF))} : {}),
        ...(params.offer.areaMax ? {area_1: Math.ceil(Math.max(params.offer.areaMax * (1 + AREA_FACTOR), params.offer.areaMax + AREA_MIN_DIFF))} : {}),
        ...(params.offer.roomsMin ? {rooms_0: params.offer.roomsMin} : {}),
        ...(params.offer.roomsMax ? {rooms_1: params.offer.roomsMax} : {})
    };

    return {
        ...detailOfferListConstraints,
        ...filters,
        page,
        page_size: 50,
        type: offerType,
        near_by_offer: id,
        exclude_offer: id,
        distance: params.distance || DEFAULT_DISTANCE_NEARBY_OFFERS
    };
}
