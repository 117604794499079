import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {onDesktop} from "@pg-design/helpers-css";

interface IOfferModalLayout {
    mobileImageOpened: boolean;
}

export const OfferModalLayout = styled.div<IOfferModalLayout>`
    ${({mobileImageOpened, theme}) => css`
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: ${mobileImageOpened ? "hidden" : "scroll"};
        background: ${theme.colors.gray[100]};

        ${onDesktop(css`
            overflow: visible;
            background: transparent;
        `)}
    `}
`;
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {onDesktop} from "@pg-design/helpers-css";

interface IOfferModalLayout {
    mobileImageOpened: boolean;
}

export const OfferModalLayout = styled.div<IOfferModalLayout>`
    ${({mobileImageOpened, theme}) => css`
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: ${mobileImageOpened ? "hidden" : "scroll"};
        background: ${theme.colors.gray[100]};

        ${onDesktop(css`
            overflow: visible;
            background: transparent;
        `)}
    `}
`;
