import {useCallback, useEffect, useMemo, useRef} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {RouteChangeValidator} from "@pg-mono/data-fetcher";

/**
 * A custom React hook that manages the state of a modal dialog in a React application.
 * The state of the modal is tied to a specific route parameter in the URL.
 *
 * Usage will trigger data fetcher.
 *
 * Consult SEO before using this hook as it may affect rankings by creating duplicate pages with new params
 *
 * @param {string} routeParam - The route parameter that will be used to control the modal state.
 * @param {string | number} value - The value of the route parameter that will open the modal.
 *
 * @returns an object containing the current modal state and functions to open and close the modal.
 */

export const useRoutedModalState = (routeParam: string, value?: string) => {
    const isManuallyOpened = useRef<boolean>(false);

    const location = useLocation();
    const history = useHistory();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    const paramValue = searchParams.get(routeParam);
    const modalState = paramValue === (value || routeParam);

    const openModal = useCallback(() => {
        isManuallyOpened.current = true;

        searchParams.set(routeParam, value || routeParam);

        history.push({search: searchParams.toString()});
    }, [searchParams, history]);

    const closeModal = useCallback(
        (forceClose?: boolean) => {
            if (!forceClose && isManuallyOpened.current) {
                history.goBack();
                return;
            }

            // since we don't know user history create new stack
            searchParams.delete(routeParam);
            history.push({search: searchParams.toString()});
        },
        [searchParams, history]
    );

    useEffect(() => {
        RouteChangeValidator.registerIgnoredParam(routeParam);
    }, []);

    return {
        modalState,
        paramValue,
        openModal,
        closeModal
    };
};
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {RouteChangeValidator} from "@pg-mono/data-fetcher";

/**
 * A custom React hook that manages the state of a modal dialog in a React application.
 * The state of the modal is tied to a specific route parameter in the URL.
 *
 * Usage will trigger data fetcher.
 *
 * Consult SEO before using this hook as it may affect rankings by creating duplicate pages with new params
 *
 * @param {string} routeParam - The route parameter that will be used to control the modal state.
 * @param {string | number} value - The value of the route parameter that will open the modal.
 *
 * @returns an object containing the current modal state and functions to open and close the modal.
 */

export const useRoutedModalState = (routeParam: string, value?: string) => {
    const isManuallyOpened = useRef<boolean>(false);

    const location = useLocation();
    const history = useHistory();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    const paramValue = searchParams.get(routeParam);
    const modalState = paramValue === (value || routeParam);

    const openModal = useCallback(() => {
        isManuallyOpened.current = true;

        searchParams.set(routeParam, value || routeParam);

        history.push({search: searchParams.toString()});
    }, [searchParams, history]);

    const closeModal = useCallback(
        (forceClose?: boolean) => {
            if (!forceClose && isManuallyOpened.current) {
                history.goBack();
                return;
            }

            // since we don't know user history create new stack
            searchParams.delete(routeParam);
            history.push({search: searchParams.toString()});
        },
        [searchParams, history]
    );

    useEffect(() => {
        RouteChangeValidator.registerIgnoredParam(routeParam);
    }, []);

    return {
        modalState,
        paramValue,
        openModal,
        closeModal
    };
};
