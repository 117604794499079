import React, {Fragment, ReactNode, useEffect, useRef} from "react";
import {css, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mb, pb} from "@pg-design/helpers-css";
import {BrandSearchIcon} from "@pg-design/icons";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {IInfiniteList} from "../hooks/use_infinite_list";

export interface IInfiniteScrollListProps<ListItemValue> {
    infiniteList: IInfiniteList<ListItemValue>;
    listComponent: React.FC<IInfiniteScrollListWrapProps>;
    itemComponent: React.FC<IInfiniteScrollItemProps<ListItemValue>>;
    handleIsIntersecting: (isIntersecting: boolean) => void;
    loader?: ReactNode;
    emptyListInfo?: ReactNode;
    listIntersectionContainerId?: string;
}

export interface IInfiniteScrollListWrapProps {
    children: ReactNode;
}

export interface IInfiniteScrollItemProps<ListItemValue> {
    item: ListItemValue;
    index: number;
}

export function InfiniteScrollList<ListItemValue>(props: IInfiniteScrollListProps<ListItemValue>) {
    const {infiniteList, listComponent, itemComponent, handleIsIntersecting, loader, emptyListInfo, listIntersectionContainerId} = props;
    const listFooter = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!listFooter.current) {
            return;
        }

        const option = {
            root: listIntersectionContainerId ? document.getElementById(listIntersectionContainerId) : null,
            rootMargin: "30px",
            threshold: 0
        };

        const observer = new IntersectionObserver(([e]) => {
            handleIsIntersecting(e.isIntersecting);
        }, option);

        observer.observe(listFooter.current);

        return () => {
            if (!listFooter.current) {
                return;
            }

            observer.unobserve(listFooter.current);
        };
    }, [listIntersectionContainerId]);

    const fullListIsLoadedButNoItems = !infiniteList.canLoadMore && infiniteList.items.length === 0;

    return (
        <>
            {listComponent({
                children: (
                    <>
                        {infiniteList.items.map((item, i) => (
                            <Fragment key={`ili${i}`}>{itemComponent({item: item.data, index: i})}</Fragment>
                        ))}
                    </>
                )
            })}

            <div ref={listFooter}>
                {infiniteList.canLoadMore && loader}
                {infiniteList.canLoadMore && !loader && <ListLoader />}
                {fullListIsLoadedButNoItems && emptyListInfo}
                {fullListIsLoadedButNoItems && !emptyListInfo && <NoMoreItems />}
            </div>
        </>
    );
}

//  Sub-components
function ListLoader() {
    return (
        <div css={flexAbsoluteCenter}>
            <Loader size="md" />
        </div>
    );
}

function NoMoreItems() {
    const theme = useTheme();

    return (
        <div css={[noMoreItems]}>
            <BrandSearchIcon size="6" wrapperSize="6" wrapperColor={theme.colors.primary} wrapperType="circle" css={[pb(2), mb(4)]} />
            <Text align="center" variant="headline_5" mb="0.8rem">
                Nie znaleziono elementów spełniających wybrane kryteria
            </Text>
            <Text align="center" variant="body_copy_2">
                Zmień parametry/filtry aby uzyskać więcej wyników
            </Text>
        </div>
    );
}

const noMoreItems = css`
    background-color: #fff;
    position: relative;
    padding: 4.8rem 1.6rem 3.2rem;
    flex-direction: column;
    ${flexAbsoluteCenter};
    ${borderRadius(2)};
`;
import React, {Fragment, ReactNode, useEffect, useRef} from "react";
import {css, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mb, pb} from "@pg-design/helpers-css";
import {BrandSearchIcon} from "@pg-design/icons";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {IInfiniteList} from "../hooks/use_infinite_list";

export interface IInfiniteScrollListProps<ListItemValue> {
    infiniteList: IInfiniteList<ListItemValue>;
    listComponent: React.FC<IInfiniteScrollListWrapProps>;
    itemComponent: React.FC<IInfiniteScrollItemProps<ListItemValue>>;
    handleIsIntersecting: (isIntersecting: boolean) => void;
    loader?: ReactNode;
    emptyListInfo?: ReactNode;
    listIntersectionContainerId?: string;
}

export interface IInfiniteScrollListWrapProps {
    children: ReactNode;
}

export interface IInfiniteScrollItemProps<ListItemValue> {
    item: ListItemValue;
    index: number;
}

export function InfiniteScrollList<ListItemValue>(props: IInfiniteScrollListProps<ListItemValue>) {
    const {infiniteList, listComponent, itemComponent, handleIsIntersecting, loader, emptyListInfo, listIntersectionContainerId} = props;
    const listFooter = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!listFooter.current) {
            return;
        }

        const option = {
            root: listIntersectionContainerId ? document.getElementById(listIntersectionContainerId) : null,
            rootMargin: "30px",
            threshold: 0
        };

        const observer = new IntersectionObserver(([e]) => {
            handleIsIntersecting(e.isIntersecting);
        }, option);

        observer.observe(listFooter.current);

        return () => {
            if (!listFooter.current) {
                return;
            }

            observer.unobserve(listFooter.current);
        };
    }, [listIntersectionContainerId]);

    const fullListIsLoadedButNoItems = !infiniteList.canLoadMore && infiniteList.items.length === 0;

    return (
        <>
            {listComponent({
                children: (
                    <>
                        {infiniteList.items.map((item, i) => (
                            <Fragment key={`ili${i}`}>{itemComponent({item: item.data, index: i})}</Fragment>
                        ))}
                    </>
                )
            })}

            <div ref={listFooter}>
                {infiniteList.canLoadMore && loader}
                {infiniteList.canLoadMore && !loader && <ListLoader />}
                {fullListIsLoadedButNoItems && emptyListInfo}
                {fullListIsLoadedButNoItems && !emptyListInfo && <NoMoreItems />}
            </div>
        </>
    );
}

//  Sub-components
function ListLoader() {
    return (
        <div css={flexAbsoluteCenter}>
            <Loader size="md" />
        </div>
    );
}

function NoMoreItems() {
    const theme = useTheme();

    return (
        <div css={[noMoreItems]}>
            <BrandSearchIcon size="6" wrapperSize="6" wrapperColor={theme.colors.primary} wrapperType="circle" css={[pb(2), mb(4)]} />
            <Text align="center" variant="headline_5" mb="0.8rem">
                Nie znaleziono elementów spełniających wybrane kryteria
            </Text>
            <Text align="center" variant="body_copy_2">
                Zmień parametry/filtry aby uzyskać więcej wyników
            </Text>
        </div>
    );
}

const noMoreItems = css`
    background-color: #fff;
    position: relative;
    padding: 4.8rem 1.6rem 3.2rem;
    flex-direction: column;
    ${flexAbsoluteCenter};
    ${borderRadius(2)};
`;
