import React from "react";
import {useDispatch} from "react-redux";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, onDesktop, pv} from "@pg-design/helpers-css";
import {FiltersIcon, HorizontalListIcon, PropertyPlanIcon, SortIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {useAppSelector} from "../../../store/hooks";
import {setOfferModalType} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";

interface IProps {
    openSort: () => void;
    openFilters: () => void;
    modalName: IOfferModalName;
}

export const OfferModalButtons = (props: IProps) => {
    const theme = useTheme();
    const {isMobile} = useUserDevice();
    const dispatch = useDispatch();
    const propertyListType = useAppSelector((state) => state.offerModals[props.modalName].type);
    const {modalName, openFilters, openSort} = props;

    return (
        <div css={wrapper}>
            <div css={tabButtons}>
                <StyledButton isActive={propertyListType === "tiles"} onClick={() => dispatch(setOfferModalType({modalName, type: "tiles"}))}>
                    <PropertyPlanIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" />
                    <Text color={theme.colors.secondary} variant="button_medium">
                        Rzuty
                    </Text>
                </StyledButton>
                <StyledButton isActive={propertyListType === "table"} onClick={() => dispatch(setOfferModalType({modalName, type: "table"}))}>
                    <HorizontalListIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" />
                    <Text color={theme.colors.secondary} variant="button_medium">
                        Lista
                    </Text>
                </StyledButton>
            </div>
            {isMobile && (
                <div css={queryButtons}>
                    <StyledButton isActive onClick={openFilters}>
                        <FiltersIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" fill={theme.colors.secondary} />
                    </StyledButton>
                    <StyledButton isActive onClick={openSort}>
                        <SortIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" fill={theme.colors.secondary} />
                    </StyledButton>
                </div>
            )}
        </div>
    );
};

const wrapper = css`
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    ${pv(2)};

    ${onDesktop(css`
        ${pv()};
        column-gap: ${calculateRemSize(2)};
    `)}
`;

const tabButtons = css`
    ${flex("center")};
    column-gap: ${calculateRemSize(4)};
    ${onDesktop(css`
        flex: 2 1 auto;
        justify-content: flex-end;
    `)}
`;

interface ButtonProps {
    isActive: boolean;
}

const StyledButton = styled.button<ButtonProps>`
    ${flex("center")};
    column-gap: ${calculateRemSize(1)};
    ${(props) =>
        !props.isActive &&
        css`
            opacity: 0.5;
        `}
`;

const queryButtons = css`
    ${flex("center")};
    column-gap: ${calculateRemSize(3)};
`;
import React from "react";
import {useDispatch} from "react-redux";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, onDesktop, pv} from "@pg-design/helpers-css";
import {FiltersIcon, HorizontalListIcon, PropertyPlanIcon, SortIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {useAppSelector} from "../../../store/hooks";
import {setOfferModalType} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";

interface IProps {
    openSort: () => void;
    openFilters: () => void;
    modalName: IOfferModalName;
}

export const OfferModalButtons = (props: IProps) => {
    const theme = useTheme();
    const {isMobile} = useUserDevice();
    const dispatch = useDispatch();
    const propertyListType = useAppSelector((state) => state.offerModals[props.modalName].type);
    const {modalName, openFilters, openSort} = props;

    return (
        <div css={wrapper}>
            <div css={tabButtons}>
                <StyledButton isActive={propertyListType === "tiles"} onClick={() => dispatch(setOfferModalType({modalName, type: "tiles"}))}>
                    <PropertyPlanIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" />
                    <Text color={theme.colors.secondary} variant="button_medium">
                        Rzuty
                    </Text>
                </StyledButton>
                <StyledButton isActive={propertyListType === "table"} onClick={() => dispatch(setOfferModalType({modalName, type: "table"}))}>
                    <HorizontalListIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" />
                    <Text color={theme.colors.secondary} variant="button_medium">
                        Lista
                    </Text>
                </StyledButton>
            </div>
            {isMobile && (
                <div css={queryButtons}>
                    <StyledButton isActive onClick={openFilters}>
                        <FiltersIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" fill={theme.colors.secondary} />
                    </StyledButton>
                    <StyledButton isActive onClick={openSort}>
                        <SortIcon size="2.4" wrapperSize="2.4" wrapperColor="transparent" fill={theme.colors.secondary} />
                    </StyledButton>
                </div>
            )}
        </div>
    );
};

const wrapper = css`
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    ${pv(2)};

    ${onDesktop(css`
        ${pv()};
        column-gap: ${calculateRemSize(2)};
    `)}
`;

const tabButtons = css`
    ${flex("center")};
    column-gap: ${calculateRemSize(4)};
    ${onDesktop(css`
        flex: 2 1 auto;
        justify-content: flex-end;
    `)}
`;

interface ButtonProps {
    isActive: boolean;
}

const StyledButton = styled.button<ButtonProps>`
    ${flex("center")};
    column-gap: ${calculateRemSize(1)};
    ${(props) =>
        !props.isActive &&
        css`
            opacity: 0.5;
        `}
`;

const queryButtons = css`
    ${flex("center")};
    column-gap: ${calculateRemSize(3)};
`;
