import {IPolylineCoordinates} from "../types/IPolylineCoordinates";

interface ILeg {
    steps: {
        path: {
            lat: () => number;
            lng: () => number;
        }[];
    }[];
}

export const getPolylineCoords = (leg: ILeg) => {
    return leg.steps.reduce((acc, step) => {
        step.path.forEach((path) => {
            acc.push({lat: path.lat(), lng: path.lng()});
        });

        return acc;
    }, [] as IPolylineCoordinates[]);
};
import {IPolylineCoordinates} from "../types/IPolylineCoordinates";

interface ILeg {
    steps: {
        path: {
            lat: () => number;
            lng: () => number;
        }[];
    }[];
}

export const getPolylineCoords = (leg: ILeg) => {
    return leg.steps.reduce((acc, step) => {
        step.path.forEach((path) => {
            acc.push({lat: path.lat(), lng: path.lng()});
        });

        return acc;
    }, [] as IPolylineCoordinates[]);
};
