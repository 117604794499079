import {useRef, useState} from "react";

import {IInfiniteScrollItemProps, IInfiniteScrollListProps, InfiniteScrollList, useInfiniteList} from "@pg-design/infinite-list";

import {ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {PropertiesTable} from "../../../property/components/properties_table/PropertiesTable";
import {PropertiesTableRow} from "../../../property/components/properties_table/PropertiesTableRow";
import {PropertyPlanModal} from "../../../property/components/property_plan_modal/PropertyPlanModal";
import {IPropertySortableField} from "../../../property/interfaces/IPropertySortableField";
import {IPropertySortOption} from "../../../property/interfaces/IPropertySortOption";
import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ShowPropertyPlanView, showPropertyPlanView} from "../../../tracking/algolytics_hits/click_actions/show_property_plan_click";
import {IUseOfferDetailPropertiesQuery} from "../../hooks/use_offer_modal_properties";
import {loadNextPage, setOfferModalSort} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    modalName: IOfferModalName;
    propertiesQuery: IUseOfferDetailPropertiesQuery;
    offer: IOfferModalOffer;
    intersectionContainerId: string;
}

export function OfferModalPropertyTable(props: IProps) {
    const {modalName, propertiesQuery, offer, intersectionContainerId} = props;
    const dispatch = useAppDispatch();
    const sourceSection = useAppSelector((state) => state.offerModals[modalName].sourceSection);
    const sortOption = useAppSelector((state) => state.offerModals[modalName].sort);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const infiniteList = useInfiniteList(
        propertiesQuery.queryData,
        () => {
            dispatch(loadNextPage({modalName}));
        },
        isIntersecting,
        propertiesQuery.isUninitialized
    );
    const {items: properties, totalPageCount} = infiniteList;

    const propertiesInListAmount = properties.length;
    const [planModalOpened, setPlanModalOpened] = useState(false);

    const previousPropertyIndexRef = useRef(0);
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState<number>(0);

    const setPrevProperty = (currentIndex: number) => {
        previousPropertyIndexRef.current = currentIndex;
        setCurrentPropertyIndex(currentIndex - 1);
    };

    const setNextProperty = (currentIndex: number) => {
        previousPropertyIndexRef.current = currentIndex;
        setCurrentPropertyIndex(currentIndex + 1);
    };

    const handlers = {
        clickPlan: (index: number) => {
            setCurrentPropertyIndex(index);
            setPlanModalOpened(true);
        },
        handleClose: () => {
            setPlanModalOpened(false);
        },
        handleNextClick: async (index: number) => {
            const nextPropertyIsOnNextPage = currentPropertyIndex + 1 === propertiesInListAmount;
            if (nextPropertyIsOnNextPage) {
                dispatch(loadNextPage({modalName}));

                setNextProperty(index);
            } else {
                setNextProperty(index);
            }
        },
        handlePrevClick: async (index: number) => {
            setPrevProperty(index);
        },
        toggleFieldSort: (fieldName: IPropertySortableField) => {
            if (!sortOption || (sortOption && sortOption.fieldName !== fieldName)) {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "desc"
                        }
                    })
                );
            }

            if (sortOption && sortOption.fieldName === fieldName && sortOption.value === "desc") {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "asc"
                        }
                    })
                );
            }

            if (sortOption && sortOption.fieldName === fieldName && sortOption.value === "asc") {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "desc"
                        }
                    })
                );
            }
        }
    };

    /**
     * The only case when propertyOnList is undefined, is when:
     *  - we set initial index, when query is not finished yet
     *  - we set new index, but still waiting for query request state to change

     * TODO: Reconsider this approach
     */
    const propertyOnList = properties[currentPropertyIndex];
    const currentProperty = propertyOnList ? properties[currentPropertyIndex] : properties[previousPropertyIndexRef.current];

    const trackedData = {
        offer: {
            geo_point: {
                coordinates: offer.geo_point.coordinates
            },
            id: offer.id,
            name: offer.name,
            type: offer.type,
            region: {
                country: offer.region.country,
                full_name: offer.region.full_name,
                id: offer.region.id
            }
        },
        vendor: {
            id: offer.vendor.id,
            name: offer.vendor.name,
            slug: offer.vendor.slug
        }
    };

    const onPlanButtonClick = () => {
        showPropertyPlanView(ShowPropertyPlanView.PLAN_IMAGE_DOWNLOAD, trackedData.offer, trackedData.vendor);
    };

    return (
        <>
            {currentProperty && (
                <PropertyPlanModal
                    isOpen={planModalOpened}
                    property={currentProperty.data}
                    offer={offer}
                    currentPropertyIndex={currentPropertyIndex}
                    currentPage={currentProperty.page}
                    propertiesOnCurrentPage={properties.length}
                    pageCount={totalPageCount}
                    pageIsLoading={propertiesQuery.isLoading}
                    handleClose={handlers.handleClose}
                    handleNextClick={handlers.handleNextClick}
                    handlePrevClick={handlers.handlePrevClick}
                    onPlanDownloadClick={onPlanButtonClick}
                />
            )}
            <InfiniteScrollList
                infiniteList={infiniteList}
                handleIsIntersecting={(isIntersecting) => {
                    setIsIntersecting(isIntersecting);
                }}
                listComponent={(props) => {
                    const {children} = props;
                    const sort = useAppSelector((state) => state.offerModals[modalName].sort);

                    return (
                        <PropertiesTable sort={sort} toggleSort={handlers.toggleFieldSort}>
                            {children}
                        </PropertiesTable>
                    );
                }}
                itemComponent={getItemComponent(offer, handlers.clickPlan, sortOption, sourceSection ? sourceSection : undefined)}
                listIntersectionContainerId={intersectionContainerId}
            />
        </>
    );
}

/*
    Utils
 */

function getItemComponent(
    offer: IOfferModalOffer,
    onPlanClick: (index: number) => void,
    sort: IPropertySortOption | null,
    sourceSection?: ApplicationSourceSection
): IInfiniteScrollListProps<IPropertyListProperty>["itemComponent"] {
    return (props: IInfiniteScrollItemProps<IPropertyListProperty>) => {
        const {item, index} = props;

        return (
            <PropertiesTableRow
                property={item}
                index={index}
                offer={offer}
                sort={sort}
                handlePlanClick={() => {
                    onPlanClick(index);
                }}
                sourceSection={sourceSection}
            />
        );
    };
}
import {useRef, useState} from "react";

import {IInfiniteScrollItemProps, IInfiniteScrollListProps, InfiniteScrollList, useInfiniteList} from "@pg-design/infinite-list";

import {ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {PropertiesTable} from "../../../property/components/properties_table/PropertiesTable";
import {PropertiesTableRow} from "../../../property/components/properties_table/PropertiesTableRow";
import {PropertyPlanModal} from "../../../property/components/property_plan_modal/PropertyPlanModal";
import {IPropertySortableField} from "../../../property/interfaces/IPropertySortableField";
import {IPropertySortOption} from "../../../property/interfaces/IPropertySortOption";
import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ShowPropertyPlanView, showPropertyPlanView} from "../../../tracking/algolytics_hits/click_actions/show_property_plan_click";
import {IUseOfferDetailPropertiesQuery} from "../../hooks/use_offer_modal_properties";
import {loadNextPage, setOfferModalSort} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    modalName: IOfferModalName;
    propertiesQuery: IUseOfferDetailPropertiesQuery;
    offer: IOfferModalOffer;
    intersectionContainerId: string;
}

export function OfferModalPropertyTable(props: IProps) {
    const {modalName, propertiesQuery, offer, intersectionContainerId} = props;
    const dispatch = useAppDispatch();
    const sourceSection = useAppSelector((state) => state.offerModals[modalName].sourceSection);
    const sortOption = useAppSelector((state) => state.offerModals[modalName].sort);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const infiniteList = useInfiniteList(
        propertiesQuery.queryData,
        () => {
            dispatch(loadNextPage({modalName}));
        },
        isIntersecting,
        propertiesQuery.isUninitialized
    );
    const {items: properties, totalPageCount} = infiniteList;

    const propertiesInListAmount = properties.length;
    const [planModalOpened, setPlanModalOpened] = useState(false);

    const previousPropertyIndexRef = useRef(0);
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState<number>(0);

    const setPrevProperty = (currentIndex: number) => {
        previousPropertyIndexRef.current = currentIndex;
        setCurrentPropertyIndex(currentIndex - 1);
    };

    const setNextProperty = (currentIndex: number) => {
        previousPropertyIndexRef.current = currentIndex;
        setCurrentPropertyIndex(currentIndex + 1);
    };

    const handlers = {
        clickPlan: (index: number) => {
            setCurrentPropertyIndex(index);
            setPlanModalOpened(true);
        },
        handleClose: () => {
            setPlanModalOpened(false);
        },
        handleNextClick: async (index: number) => {
            const nextPropertyIsOnNextPage = currentPropertyIndex + 1 === propertiesInListAmount;
            if (nextPropertyIsOnNextPage) {
                dispatch(loadNextPage({modalName}));

                setNextProperty(index);
            } else {
                setNextProperty(index);
            }
        },
        handlePrevClick: async (index: number) => {
            setPrevProperty(index);
        },
        toggleFieldSort: (fieldName: IPropertySortableField) => {
            if (!sortOption || (sortOption && sortOption.fieldName !== fieldName)) {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "desc"
                        }
                    })
                );
            }

            if (sortOption && sortOption.fieldName === fieldName && sortOption.value === "desc") {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "asc"
                        }
                    })
                );
            }

            if (sortOption && sortOption.fieldName === fieldName && sortOption.value === "asc") {
                dispatch(
                    setOfferModalSort({
                        modalName,
                        sort: {
                            fieldName,
                            value: "desc"
                        }
                    })
                );
            }
        }
    };

    /**
     * The only case when propertyOnList is undefined, is when:
     *  - we set initial index, when query is not finished yet
     *  - we set new index, but still waiting for query request state to change

     * TODO: Reconsider this approach
     */
    const propertyOnList = properties[currentPropertyIndex];
    const currentProperty = propertyOnList ? properties[currentPropertyIndex] : properties[previousPropertyIndexRef.current];

    const trackedData = {
        offer: {
            geo_point: {
                coordinates: offer.geo_point.coordinates
            },
            id: offer.id,
            name: offer.name,
            type: offer.type,
            region: {
                country: offer.region.country,
                full_name: offer.region.full_name,
                id: offer.region.id
            }
        },
        vendor: {
            id: offer.vendor.id,
            name: offer.vendor.name,
            slug: offer.vendor.slug
        }
    };

    const onPlanButtonClick = () => {
        showPropertyPlanView(ShowPropertyPlanView.PLAN_IMAGE_DOWNLOAD, trackedData.offer, trackedData.vendor);
    };

    return (
        <>
            {currentProperty && (
                <PropertyPlanModal
                    isOpen={planModalOpened}
                    property={currentProperty.data}
                    offer={offer}
                    currentPropertyIndex={currentPropertyIndex}
                    currentPage={currentProperty.page}
                    propertiesOnCurrentPage={properties.length}
                    pageCount={totalPageCount}
                    pageIsLoading={propertiesQuery.isLoading}
                    handleClose={handlers.handleClose}
                    handleNextClick={handlers.handleNextClick}
                    handlePrevClick={handlers.handlePrevClick}
                    onPlanDownloadClick={onPlanButtonClick}
                />
            )}
            <InfiniteScrollList
                infiniteList={infiniteList}
                handleIsIntersecting={(isIntersecting) => {
                    setIsIntersecting(isIntersecting);
                }}
                listComponent={(props) => {
                    const {children} = props;
                    const sort = useAppSelector((state) => state.offerModals[modalName].sort);

                    return (
                        <PropertiesTable sort={sort} toggleSort={handlers.toggleFieldSort}>
                            {children}
                        </PropertiesTable>
                    );
                }}
                itemComponent={getItemComponent(offer, handlers.clickPlan, sortOption, sourceSection ? sourceSection : undefined)}
                listIntersectionContainerId={intersectionContainerId}
            />
        </>
    );
}

/*
    Utils
 */

function getItemComponent(
    offer: IOfferModalOffer,
    onPlanClick: (index: number) => void,
    sort: IPropertySortOption | null,
    sourceSection?: ApplicationSourceSection
): IInfiniteScrollListProps<IPropertyListProperty>["itemComponent"] {
    return (props: IInfiniteScrollItemProps<IPropertyListProperty>) => {
        const {item, index} = props;

        return (
            <PropertiesTableRow
                property={item}
                index={index}
                offer={offer}
                sort={sort}
                handlePlanClick={() => {
                    onPlanClick(index);
                }}
                sourceSection={sourceSection}
            />
        );
    };
}
