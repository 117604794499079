import {IPropertyListProperty} from "../../../../property/types/PropertyListProperty";
import {gtmEventPropertyListClick} from "../../../../tracking/google_tag_manager/gtm_event_property_list";
import {IOfferModalOffer} from "../../../types/OfferModalOffer";
import {OfferModalPropertyCard} from "./OfferModalPropertyCard";

interface IProps {
    property: IPropertyListProperty;
    offer: IOfferModalOffer;
}

export function OfferModalPropertiesListItem(props: IProps) {
    const {property, offer} = props;

    const onClick = () => {
        gtmEventPropertyListClick("rzut");
    };

    return <OfferModalPropertyCard property={property} offer={offer} onClick={onClick} />;
}
import {IPropertyListProperty} from "../../../../property/types/PropertyListProperty";
import {gtmEventPropertyListClick} from "../../../../tracking/google_tag_manager/gtm_event_property_list";
import {IOfferModalOffer} from "../../../types/OfferModalOffer";
import {OfferModalPropertyCard} from "./OfferModalPropertyCard";

interface IProps {
    property: IPropertyListProperty;
    offer: IOfferModalOffer;
}

export function OfferModalPropertiesListItem(props: IProps) {
    const {property, offer} = props;

    const onClick = () => {
        gtmEventPropertyListClick("rzut");
    };

    return <OfferModalPropertyCard property={property} offer={offer} onClick={onClick} />;
}
