import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../app/rp_reducer";
import {restoreUserPoi} from "../actions/manage_user_poi";
import {POI_DISTANCE_DEFAULT_VALUE} from "../constants/pois_defaults";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

interface IHook {
    checkedPoiTypes: PoiType[];
    onEndPoiEditing: () => void;
    onStartPoiEditing: () => void;
    setCheckedPoiTypes: (checkedPoiTypes: PoiType[]) => void;
    poiDistance: number;
    setPoiDistance: (newDistance: number) => void;
}

/*
 * On mobile we have special business requirement - user is able to cancel changes - and we need to restore previous state.
 * In order to meet the requirement we need to make state snapshot before any change - then use it when needed.
 */
export const usePoiMapObjectsState = (initialPoiTypes?: PoiType[]): IHook => {
    const dispatch = useDispatch();
    const {isMobile} = useUserDevice();

    const [checkedPoiTypes, setCheckedPoiTypes] = useState<PoiType[]>(initialPoiTypes ?? [PoiType.TRANSPORT]);
    const [poiDistance, setPoiDistance] = useState(POI_DISTANCE_DEFAULT_VALUE);

    const checkedPoiTypesSnapshot = useRef<PoiType[]>([]);
    const userPoiSnapshot = useRef<IUserPoi[]>([]);

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const onStartPoiEditing = () => {
        if (isMobile) {
            checkedPoiTypesSnapshot.current = checkedPoiTypes;
            userPoiSnapshot.current = userPoi;
        }
    };

    const onEndPoiEditing = () => {
        if (isMobile) {
            dispatch(restoreUserPoi(userPoiSnapshot.current));

            setCheckedPoiTypes(checkedPoiTypesSnapshot.current);

            checkedPoiTypesSnapshot.current = [];
            userPoiSnapshot.current = [];
        }
    };

    return {
        checkedPoiTypes,
        onEndPoiEditing,
        onStartPoiEditing,
        setCheckedPoiTypes,
        poiDistance,
        setPoiDistance
    };
};
import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../app/rp_reducer";
import {restoreUserPoi} from "../actions/manage_user_poi";
import {POI_DISTANCE_DEFAULT_VALUE} from "../constants/pois_defaults";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

interface IHook {
    checkedPoiTypes: PoiType[];
    onEndPoiEditing: () => void;
    onStartPoiEditing: () => void;
    setCheckedPoiTypes: (checkedPoiTypes: PoiType[]) => void;
    poiDistance: number;
    setPoiDistance: (newDistance: number) => void;
}

/*
 * On mobile we have special business requirement - user is able to cancel changes - and we need to restore previous state.
 * In order to meet the requirement we need to make state snapshot before any change - then use it when needed.
 */
export const usePoiMapObjectsState = (initialPoiTypes?: PoiType[]): IHook => {
    const dispatch = useDispatch();
    const {isMobile} = useUserDevice();

    const [checkedPoiTypes, setCheckedPoiTypes] = useState<PoiType[]>(initialPoiTypes ?? [PoiType.TRANSPORT]);
    const [poiDistance, setPoiDistance] = useState(POI_DISTANCE_DEFAULT_VALUE);

    const checkedPoiTypesSnapshot = useRef<PoiType[]>([]);
    const userPoiSnapshot = useRef<IUserPoi[]>([]);

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const onStartPoiEditing = () => {
        if (isMobile) {
            checkedPoiTypesSnapshot.current = checkedPoiTypes;
            userPoiSnapshot.current = userPoi;
        }
    };

    const onEndPoiEditing = () => {
        if (isMobile) {
            dispatch(restoreUserPoi(userPoiSnapshot.current));

            setCheckedPoiTypes(checkedPoiTypesSnapshot.current);

            checkedPoiTypesSnapshot.current = [];
            userPoiSnapshot.current = [];
        }
    };

    return {
        checkedPoiTypes,
        onEndPoiEditing,
        onStartPoiEditing,
        setCheckedPoiTypes,
        poiDistance,
        setPoiDistance
    };
};
