import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {borderRadius, mb, mt, p} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {IRPStore} from "../../app/rp_reducer";
import {hitRailTransportRoute} from "../../offer/tracking/offer_map_events";
import {setActivePoi, setActivePoiDirections, setActivePoiRoute} from "../actions/set_poi_travel_directions";
import {useOsmPoiRoutes} from "../hooks/use_osm_poi_routes";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {hitGtmBusRouteClick} from "../tracking/routes_gtm_events";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IPublicTransportType} from "../types/IPublicTransportType";
import {IPoiTravelProps, PoiTravelMode} from "./PoiTravelMode";
import {RegionTransportRoutes} from "./RegionTransportRoutes";

export interface IProps extends IPoiTravelProps {
    onClose?: () => void;
}

export const PoiTravelModeInfoWindow = (props: IProps) => {
    const {onClose, poi, poiType, ...forwardProps} = props;

    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const activePoi = useSelector((state: IRPStore) => state.maps.travelDirections.activePoi);
    const activePoiRoute = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiRoute);
    const offerId = useSelector((state: IRPStore) => state.offerDetail.offer?.id);
    const propertyId = useSelector((state: IRPStore) => state.property.property?.id);
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    useEffect(() => {
        if (!props.calcTravelDataOnOpen) {
            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_POI_CLICK, label: props.poiType});
            poiAnalytics.algolytics.poiClick(viewType, props.poiType, isMobile && activePoi ? activePoi : poi, offerId, propertyId);
        }
    }, [props.poi, props.calcTravelDataOnOpen, activePoi]);

    const {hasAnyPoiRoutes, poiRoutesGrouped} = useOsmPoiRoutes(poiType, poi.id);

    const onInfoWindowClose = () => {
        onClose?.();
        // Remove polyline - if any - from map
        dispatch(setActivePoiDirections(null));
        dispatch(setActivePoi(null, null));
    };

    const onTransportRouteClick = (poiRoute: IPublicTransportRoute) => {
        dispatch(setActivePoiRoute(poiRoute.id === activePoiRoute?.id ? null : poiRoute));
        if (poiRoute.type === IPublicTransportType.BUS) {
            hitGtmBusRouteClick();
        } else {
            hitRailTransportRoute();
        }
    };

    /*
     * On mobile we have one common place to render POI InfoWindow (instead of container per POI).
     * We want to render InfoWindow on mobile only when `activePoi` is defined.
     */
    if (isMobile && !activePoi) {
        return null;
    }

    return (
        <div css={poiTravelModeInfoWindow}>
            <span css={closePosition} onClick={onInfoWindowClose}>
                <CloseIcon size="1.6" />
            </span>

            <Text as="div" variant="headline_6">
                {poi.name}
            </Text>

            {!!poi?.tags?.address && (
                <div css={subHeading} className="bt">
                    {poi.tags.address}
                </div>
            )}

            {hasAnyPoiRoutes && poiRoutesGrouped ? (
                <div>
                    <Text as="div" variant="info_txt_2" css={[mt(2), mb(1)]}>
                        Komunikacja
                    </Text>
                    <RegionTransportRoutes
                        busRoutes={poiRoutesGrouped?.bus}
                        railwayRoutes={poiRoutesGrouped?.train}
                        subwayRoutes={poiRoutesGrouped?.subway}
                        tramRoutes={poiRoutesGrouped?.tram}
                        activePoiRoute={activePoiRoute}
                        onTransportRouteClick={onTransportRouteClick}
                    />

                    <Text as="div" variant="info_txt_2" css={[mt(2), mb(1)]}>
                        Czas dojazdu
                    </Text>
                </div>
            ) : null}

            <div css={mt(1)}>
                <PoiTravelMode poi={poi} poiType={poiType} {...forwardProps} />
            </div>
        </div>
    );
};

const poiTravelModeInfoWindow = (theme: Theme) => css`
    background-color: #fff;
    position: relative;
    min-width: 24rem;
    ${borderRadius(2)};
    ${p(2)};

    @media (max-width: ${theme.breakpoints.md}) {
        padding-right: 1.5rem;
    }
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        right: 1.5rem;
    }
`;

const subHeading = css`
    font-size: 0.8rem;
    line-height: 1.8rem;
`;
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {borderRadius, mb, mt, p} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {IRPStore} from "../../app/rp_reducer";
import {hitRailTransportRoute} from "../../offer/tracking/offer_map_events";
import {setActivePoi, setActivePoiDirections, setActivePoiRoute} from "../actions/set_poi_travel_directions";
import {useOsmPoiRoutes} from "../hooks/use_osm_poi_routes";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {hitGtmBusRouteClick} from "../tracking/routes_gtm_events";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IPublicTransportType} from "../types/IPublicTransportType";
import {IPoiTravelProps, PoiTravelMode} from "./PoiTravelMode";
import {RegionTransportRoutes} from "./RegionTransportRoutes";

export interface IProps extends IPoiTravelProps {
    onClose?: () => void;
}

export const PoiTravelModeInfoWindow = (props: IProps) => {
    const {onClose, poi, poiType, ...forwardProps} = props;

    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const activePoi = useSelector((state: IRPStore) => state.maps.travelDirections.activePoi);
    const activePoiRoute = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiRoute);
    const offerId = useSelector((state: IRPStore) => state.offerDetail.offer?.id);
    const propertyId = useSelector((state: IRPStore) => state.property.property?.id);
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    useEffect(() => {
        if (!props.calcTravelDataOnOpen) {
            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_POI_CLICK, label: props.poiType});
            poiAnalytics.algolytics.poiClick(viewType, props.poiType, isMobile && activePoi ? activePoi : poi, offerId, propertyId);
        }
    }, [props.poi, props.calcTravelDataOnOpen, activePoi]);

    const {hasAnyPoiRoutes, poiRoutesGrouped} = useOsmPoiRoutes(poiType, poi.id);

    const onInfoWindowClose = () => {
        onClose?.();
        // Remove polyline - if any - from map
        dispatch(setActivePoiDirections(null));
        dispatch(setActivePoi(null, null));
    };

    const onTransportRouteClick = (poiRoute: IPublicTransportRoute) => {
        dispatch(setActivePoiRoute(poiRoute.id === activePoiRoute?.id ? null : poiRoute));
        if (poiRoute.type === IPublicTransportType.BUS) {
            hitGtmBusRouteClick();
        } else {
            hitRailTransportRoute();
        }
    };

    /*
     * On mobile we have one common place to render POI InfoWindow (instead of container per POI).
     * We want to render InfoWindow on mobile only when `activePoi` is defined.
     */
    if (isMobile && !activePoi) {
        return null;
    }

    return (
        <div css={poiTravelModeInfoWindow}>
            <span css={closePosition} onClick={onInfoWindowClose}>
                <CloseIcon size="1.6" />
            </span>

            <Text as="div" variant="headline_6">
                {poi.name}
            </Text>

            {!!poi?.tags?.address && (
                <div css={subHeading} className="bt">
                    {poi.tags.address}
                </div>
            )}

            {hasAnyPoiRoutes && poiRoutesGrouped ? (
                <div>
                    <Text as="div" variant="info_txt_2" css={[mt(2), mb(1)]}>
                        Komunikacja
                    </Text>
                    <RegionTransportRoutes
                        busRoutes={poiRoutesGrouped?.bus}
                        railwayRoutes={poiRoutesGrouped?.train}
                        subwayRoutes={poiRoutesGrouped?.subway}
                        tramRoutes={poiRoutesGrouped?.tram}
                        activePoiRoute={activePoiRoute}
                        onTransportRouteClick={onTransportRouteClick}
                    />

                    <Text as="div" variant="info_txt_2" css={[mt(2), mb(1)]}>
                        Czas dojazdu
                    </Text>
                </div>
            ) : null}

            <div css={mt(1)}>
                <PoiTravelMode poi={poi} poiType={poiType} {...forwardProps} />
            </div>
        </div>
    );
};

const poiTravelModeInfoWindow = (theme: Theme) => css`
    background-color: #fff;
    position: relative;
    min-width: 24rem;
    ${borderRadius(2)};
    ${p(2)};

    @media (max-width: ${theme.breakpoints.md}) {
        padding-right: 1.5rem;
    }
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        right: 1.5rem;
    }
`;

const subHeading = css`
    font-size: 0.8rem;
    line-height: 1.8rem;
`;
