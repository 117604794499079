import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes/";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IResponse";
import {IGetPropertyListPayload} from "../types/GetPropertyListPayload";
import {IPropertyListProperty} from "../types/PropertyListProperty";
const propertyListUrl = apiV2ListLink.property.list(Scenario.PROPERTY_LIST);

export interface IGetPropertyListResponse extends IPaginatedResponse {
    results: IPropertyListProperty[];
}

export const getPropertyListApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getPropertyList: build.query<IGetPropertyListResponse, IGetPropertyListPayload>({
            query: (params) => ({
                url: propertyListUrl,
                params
            })
        })
    })
});

export const {getPropertyList} = getPropertyListApi.endpoints;

export const {useGetPropertyListQuery} = getPropertyListApi;
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes/";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IResponse";
import {IGetPropertyListPayload} from "../types/GetPropertyListPayload";
import {IPropertyListProperty} from "../types/PropertyListProperty";
const propertyListUrl = apiV2ListLink.property.list(Scenario.PROPERTY_LIST);

export interface IGetPropertyListResponse extends IPaginatedResponse {
    results: IPropertyListProperty[];
}

export const getPropertyListApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getPropertyList: build.query<IGetPropertyListResponse, IGetPropertyListPayload>({
            query: (params) => ({
                url: propertyListUrl,
                params
            })
        })
    })
});

export const {getPropertyList} = getPropertyListApi.endpoints;

export const {useGetPropertyListQuery} = getPropertyListApi;
