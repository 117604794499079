import {ReactNode, useMemo} from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";
import {convertToCountryLatLngLiteral, PoiType} from "@pg-mono/open-street-map";

import {MapOfferSummaryTooltip} from "../../offer/components/MapOfferSummaryTooltip";
import {IOfferListMapOffer} from "../../offer/types/IOfferListMapOffer";
import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {setActivePoi, setActivePoiDirections} from "../actions/set_poi_travel_directions";

import primaryPinWithShadowImg from "../../maps/images/pins/pins_clean/primary_pin_with_shadow.svg";

interface IProps {
    offers?: (IOfferListMapOffer | IOfferListOffer)[];
    iconUrl?: string;
    iconSizes?: [number, number];
    renderer?: (offer: IOfferListMapOffer | IOfferListOffer) => ReactNode;
    onClick?: (offer: IOfferListMapOffer | IOfferListOffer) => void;
}

export const useTransformOfferIntoMapMarker = (props: IProps): IMarker[] => {
    const {offers, iconUrl, iconSizes, renderer, onClick} = props;
    const dispatch = useDispatch();

    const transformOfferIntoMapMarker = (offer: IOfferListMapOffer | IOfferListOffer): IMarker => ({
        id: offer.id,
        coords: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
        popup: () => <MapOfferSummaryTooltip type="vertical" offerDetails={offer} />,
        icon: {
            url: iconUrl || primaryPinWithShadowImg,
            sizes: iconSizes || [24, 24],
            renderer: renderer ? () => renderer?.(offer) : undefined
        },
        poiType: PoiType.OFFERS,
        onClick: () => {
            onClick?.(offer);
            dispatch(setActivePoi(null, null));
            dispatch(setActivePoiDirections(null));
        }
    });

    return useMemo(() => {
        if (!offers) {
            return [];
        }

        return offers.map(transformOfferIntoMapMarker);
    }, [offers]);
};
import {ReactNode, useMemo} from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";
import {convertToCountryLatLngLiteral, PoiType} from "@pg-mono/open-street-map";

import {MapOfferSummaryTooltip} from "../../offer/components/MapOfferSummaryTooltip";
import {IOfferListMapOffer} from "../../offer/types/IOfferListMapOffer";
import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {setActivePoi, setActivePoiDirections} from "../actions/set_poi_travel_directions";

import primaryPinWithShadowImg from "../../maps/images/pins/pins_clean/primary_pin_with_shadow.svg";

interface IProps {
    offers?: (IOfferListMapOffer | IOfferListOffer)[];
    iconUrl?: string;
    iconSizes?: [number, number];
    renderer?: (offer: IOfferListMapOffer | IOfferListOffer) => ReactNode;
    onClick?: (offer: IOfferListMapOffer | IOfferListOffer) => void;
}

export const useTransformOfferIntoMapMarker = (props: IProps): IMarker[] => {
    const {offers, iconUrl, iconSizes, renderer, onClick} = props;
    const dispatch = useDispatch();

    const transformOfferIntoMapMarker = (offer: IOfferListMapOffer | IOfferListOffer): IMarker => ({
        id: offer.id,
        coords: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
        popup: () => <MapOfferSummaryTooltip type="vertical" offerDetails={offer} />,
        icon: {
            url: iconUrl || primaryPinWithShadowImg,
            sizes: iconSizes || [24, 24],
            renderer: renderer ? () => renderer?.(offer) : undefined
        },
        poiType: PoiType.OFFERS,
        onClick: () => {
            onClick?.(offer);
            dispatch(setActivePoi(null, null));
            dispatch(setActivePoiDirections(null));
        }
    });

    return useMemo(() => {
        if (!offers) {
            return [];
        }

        return offers.map(transformOfferIntoMapMarker);
    }, [offers]);
};
