import React from "react";
import {useDispatch} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {calculateRemSize, flex, onDesktop, w100} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {IInfiniteListQuery} from "@pg-design/infinite-list";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {useAppSelector} from "../../../store/hooks";
import {hideOfferModal, navToNextModal, navToPrevModal, setModalQueryParams} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    offersQuery: IInfiniteListQuery<IOfferModalOffer>;
    selectedOffer: IOfferModalOffer;
    modalName: IOfferModalName;
}

const offersPluralize = pluralize(["inwestycja", "inwestycje", "inwestycji"]);

export const OfferModalOfferSwitcher = (props: IProps) => {
    const {offersQuery, modalName} = props;
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const page = offersQuery.page;
    const pageSize = offersQuery.pageSize;
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);

    const offersStartingPosition = page > 1 ? (page - 1) * pageSize : 0;
    const offersWithPosition = offersQuery.data.map((offer, index) => ({id: offer.id, position: offersStartingPosition + index, data: offer}));
    const dispatch = useDispatch();
    const selectedOffer = offersWithPosition.find((offer) => offer.id === offerId);

    if (!selectedOffer) {
        throw new Error(`Offer ${offerId} is not available in query`);
    }

    const nextOfferPosition = selectedOffer.position + 1;
    const nextOffer = offersWithPosition.find((offer) => offer.position === nextOfferPosition);
    const prevOfferPosition = selectedOffer.position - 1;
    const prevOffer = offersWithPosition.find((offer) => offer.position === prevOfferPosition);

    const previousPagesCount = page > 1 ? offersStartingPosition : 0;
    const endOfPageCount = previousPagesCount + pageSize + 1;
    const totalCount = offersQuery.count;
    const readablePosition = selectedOffer.position + 1;

    const showNextIcon = readablePosition < totalCount;
    const showPrevIcon = readablePosition > 1;
    const isSingleOffer = totalCount === 1;

    const handlers = {
        close: () => {
            dispatch(hideOfferModal({modalName}));
        },
        navToNext: () => {
            if (nextOfferPosition + 1 === endOfPageCount) {
                dispatch(
                    setModalQueryParams({
                        modalName,
                        listingPage: page + 1,
                        listingPageSize: pageSize
                    })
                );
            }

            if (nextOffer) {
                dispatch(navToNextModal({modalName, offerId: nextOffer.id}));
            }
        },
        navToPrev: () => {
            if (prevOfferPosition + 1 === previousPagesCount) {
                dispatch(
                    setModalQueryParams({
                        modalName,
                        listingPage: page - 1,
                        listingPageSize: pageSize
                    })
                );
            }

            if (prevOffer) {
                dispatch(navToPrevModal({modalName, offerId: prevOffer.id}));
            }
        }
    };

    return (
        <div css={wrapper}>
            {!isSingleOffer && (
                <button type="button" disabled={!showPrevIcon} onClick={handlers.navToPrev} css={navArrowLeft}>
                    <ChevronLeftIcon
                        size={isMobile ? "1.6" : "3.6"}
                        wrapperSize={isMobile ? "3.2" : "6.4"}
                        fill={showPrevIcon ? theme.colors.secondary : theme.colors.gray[700]}
                        wrapperColor={showPrevIcon ? theme.colors.primary : theme.colors.gray[100]}
                    />
                </button>
            )}
            <Text variant="body_copy_2" color={!isMobile ? "white" : undefined} align={!isMobile ? "center" : undefined} css={navTitle}>
                {readablePosition}/{totalCount} {offersPluralize(totalCount)}
            </Text>
            {!isSingleOffer && (
                <button type="button" disabled={!showNextIcon} onClick={handlers.navToNext} css={navArrowRight}>
                    <ChevronRightIcon
                        size={isMobile ? "1.6" : "3.6"}
                        wrapperSize={isMobile ? "3.2" : "6.4"}
                        fill={showNextIcon ? theme.colors.secondary : theme.colors.gray[700]}
                        wrapperColor={showNextIcon ? theme.colors.primary : theme.colors.gray[100]}
                    />
                </button>
            )}
        </div>
    );
};

const wrapper = css`
    position: fixed;
    inset: auto 0 0;
    background-color: #fff;
    ${w100};
    ${flex("center", "center")};
    gap: ${calculateRemSize(3)};
    min-height: ${calculateRemSize(6)};
    max-height: ${calculateRemSize(6)};
    ${elevation(1)};

    ${onDesktop(css`
        background-color: transparent;
        ${elevation(0)};
        position: initial;
        min-height: 0;
        max-height: 0;
    `)}
`;

const navTitle = css`
    ${onDesktop(css`
        position: absolute;
        inset: 0 0 auto 0;
        transform: translateY(-100%);
        line-height: ${calculateRemSize(5)};
    `)}
`;

const navArrowRight = css`
    ${onDesktop(css`
        position: absolute;
        z-index: 20;
        inset: 50% -${calculateRemSize(9)} auto auto;
        transform: translate(100%, -100%);
    `)}
`;

const navArrowLeft = css`
    ${onDesktop(css`
        position: absolute;
        z-index: 20;
        inset: 50% auto auto -${calculateRemSize(9)};
        transform: translate(-100%, -100%);
    `)}
`;
import React from "react";
import {useDispatch} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {calculateRemSize, flex, onDesktop, w100} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {IInfiniteListQuery} from "@pg-design/infinite-list";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {useAppSelector} from "../../../store/hooks";
import {hideOfferModal, navToNextModal, navToPrevModal, setModalQueryParams} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    offersQuery: IInfiniteListQuery<IOfferModalOffer>;
    selectedOffer: IOfferModalOffer;
    modalName: IOfferModalName;
}

const offersPluralize = pluralize(["inwestycja", "inwestycje", "inwestycji"]);

export const OfferModalOfferSwitcher = (props: IProps) => {
    const {offersQuery, modalName} = props;
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const page = offersQuery.page;
    const pageSize = offersQuery.pageSize;
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);

    const offersStartingPosition = page > 1 ? (page - 1) * pageSize : 0;
    const offersWithPosition = offersQuery.data.map((offer, index) => ({id: offer.id, position: offersStartingPosition + index, data: offer}));
    const dispatch = useDispatch();
    const selectedOffer = offersWithPosition.find((offer) => offer.id === offerId);

    if (!selectedOffer) {
        throw new Error(`Offer ${offerId} is not available in query`);
    }

    const nextOfferPosition = selectedOffer.position + 1;
    const nextOffer = offersWithPosition.find((offer) => offer.position === nextOfferPosition);
    const prevOfferPosition = selectedOffer.position - 1;
    const prevOffer = offersWithPosition.find((offer) => offer.position === prevOfferPosition);

    const previousPagesCount = page > 1 ? offersStartingPosition : 0;
    const endOfPageCount = previousPagesCount + pageSize + 1;
    const totalCount = offersQuery.count;
    const readablePosition = selectedOffer.position + 1;

    const showNextIcon = readablePosition < totalCount;
    const showPrevIcon = readablePosition > 1;
    const isSingleOffer = totalCount === 1;

    const handlers = {
        close: () => {
            dispatch(hideOfferModal({modalName}));
        },
        navToNext: () => {
            if (nextOfferPosition + 1 === endOfPageCount) {
                dispatch(
                    setModalQueryParams({
                        modalName,
                        listingPage: page + 1,
                        listingPageSize: pageSize
                    })
                );
            }

            if (nextOffer) {
                dispatch(navToNextModal({modalName, offerId: nextOffer.id}));
            }
        },
        navToPrev: () => {
            if (prevOfferPosition + 1 === previousPagesCount) {
                dispatch(
                    setModalQueryParams({
                        modalName,
                        listingPage: page - 1,
                        listingPageSize: pageSize
                    })
                );
            }

            if (prevOffer) {
                dispatch(navToPrevModal({modalName, offerId: prevOffer.id}));
            }
        }
    };

    return (
        <div css={wrapper}>
            {!isSingleOffer && (
                <button type="button" disabled={!showPrevIcon} onClick={handlers.navToPrev} css={navArrowLeft}>
                    <ChevronLeftIcon
                        size={isMobile ? "1.6" : "3.6"}
                        wrapperSize={isMobile ? "3.2" : "6.4"}
                        fill={showPrevIcon ? theme.colors.secondary : theme.colors.gray[700]}
                        wrapperColor={showPrevIcon ? theme.colors.primary : theme.colors.gray[100]}
                    />
                </button>
            )}
            <Text variant="body_copy_2" color={!isMobile ? "white" : undefined} align={!isMobile ? "center" : undefined} css={navTitle}>
                {readablePosition}/{totalCount} {offersPluralize(totalCount)}
            </Text>
            {!isSingleOffer && (
                <button type="button" disabled={!showNextIcon} onClick={handlers.navToNext} css={navArrowRight}>
                    <ChevronRightIcon
                        size={isMobile ? "1.6" : "3.6"}
                        wrapperSize={isMobile ? "3.2" : "6.4"}
                        fill={showNextIcon ? theme.colors.secondary : theme.colors.gray[700]}
                        wrapperColor={showNextIcon ? theme.colors.primary : theme.colors.gray[100]}
                    />
                </button>
            )}
        </div>
    );
};

const wrapper = css`
    position: fixed;
    inset: auto 0 0;
    background-color: #fff;
    ${w100};
    ${flex("center", "center")};
    gap: ${calculateRemSize(3)};
    min-height: ${calculateRemSize(6)};
    max-height: ${calculateRemSize(6)};
    ${elevation(1)};

    ${onDesktop(css`
        background-color: transparent;
        ${elevation(0)};
        position: initial;
        min-height: 0;
        max-height: 0;
    `)}
`;

const navTitle = css`
    ${onDesktop(css`
        position: absolute;
        inset: 0 0 auto 0;
        transform: translateY(-100%);
        line-height: ${calculateRemSize(5)};
    `)}
`;

const navArrowRight = css`
    ${onDesktop(css`
        position: absolute;
        z-index: 20;
        inset: 50% -${calculateRemSize(9)} auto auto;
        transform: translate(100%, -100%);
    `)}
`;

const navArrowLeft = css`
    ${onDesktop(css`
        position: absolute;
        z-index: 20;
        inset: 50% auto auto -${calculateRemSize(9)};
        transform: translate(-100%, -100%);
    `)}
`;
