import {IUserPoi} from "../types/IUserPoi";

const manageUserPoi = "poi/USER_POI";

export const manageUserPoiActionTypes = {
    addUserPoi: `${manageUserPoi}_ADD_USER_POI`,
    editUserPoi: `${manageUserPoi}_EDIT_USER_POI`,
    removeUserPoi: `${manageUserPoi}_REMOVE_USER_POI`,
    restoreUserPoi: `${manageUserPoi}_RESTORE_USER_POI`
};

export const addUserPoi = (poi: IUserPoi) => {
    return {type: manageUserPoiActionTypes.addUserPoi, payload: {poi}};
};

export const editUserPoi = (id: number, poi: IUserPoi) => {
    return {type: manageUserPoiActionTypes.editUserPoi, payload: {id, poi}};
};

export const removeUserPoi = (id: number) => {
    return {type: manageUserPoiActionTypes.removeUserPoi, payload: {id}};
};

export const restoreUserPoi = (pois: IUserPoi[]) => {
    return {type: manageUserPoiActionTypes.restoreUserPoi, payload: {pois}};
};
import {IUserPoi} from "../types/IUserPoi";

const manageUserPoi = "poi/USER_POI";

export const manageUserPoiActionTypes = {
    addUserPoi: `${manageUserPoi}_ADD_USER_POI`,
    editUserPoi: `${manageUserPoi}_EDIT_USER_POI`,
    removeUserPoi: `${manageUserPoi}_REMOVE_USER_POI`,
    restoreUserPoi: `${manageUserPoi}_RESTORE_USER_POI`
};

export const addUserPoi = (poi: IUserPoi) => {
    return {type: manageUserPoiActionTypes.addUserPoi, payload: {poi}};
};

export const editUserPoi = (id: number, poi: IUserPoi) => {
    return {type: manageUserPoiActionTypes.editUserPoi, payload: {id, poi}};
};

export const removeUserPoi = (id: number) => {
    return {type: manageUserPoiActionTypes.removeUserPoi, payload: {id}};
};

export const restoreUserPoi = (pois: IUserPoi[]) => {
    return {type: manageUserPoiActionTypes.restoreUserPoi, payload: {pois}};
};
