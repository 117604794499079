import {css, useTheme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {CenteredImage} from "@pg-design/centered-image";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, mb, mr, p} from "@pg-design/helpers-css";
import {LikeIcon, LockOutlineIcon, PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, priceFormat} from "@pg-mono/string-utils";

import {useResponsiveLinkTarget} from "../../../../hooks/use_responsive_link_target";
import {IPropertyListProperty} from "../../../../property/types/PropertyListProperty";
import {getPropertyBadgeType} from "../../../../property/utils/get_property_badge_type";
import {isPropertyWithDiscountPrice} from "../../../../property/utils/is_property_with_discount_price";
import {Currency} from "../../../../types/Currency";
import {getCurrency} from "../../../../utils/get_currency";
import {IOfferModalOffer} from "../../../types/OfferModalOffer";
import {OfferPriceType} from "../../../types/OfferPriceType";
import {OfferType} from "../../../types/OfferType";

interface IProps {
    property: IPropertyListProperty;
    offer: IOfferModalOffer;
    className?: string;
    onClick?: () => void;
}

export function OfferModalPropertyCard(props: IProps) {
    const {property, offer, className, onClick} = props;
    const theme = useTheme();
    const target = useResponsiveLinkTarget();

    const propertyLink = rpAppLink.property.detail.base({
        vendorSlug: offer.vendor.slug,
        offerSlug: offer.slug,
        offerId: offer.id,
        propertyId: property.id
    });

    const propertyParams = [
        {
            name: "Metraż",
            text: areaFormat(property.area, {precision: 2})
        },
        {
            name: "Pokoje",
            text: property.rooms
        },
        {
            name: offer.type === OfferType.HOUSE ? "Piętra" : "Piętro",
            text: offer.type === OfferType.HOUSE ? property.floors : property.floor === 0 ? "parter" : property.floor
        }
    ];

    const hasDiscountPrice = isPropertyWithDiscountPrice(property);
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;
    const priceType = property.offer.currency === Currency.EURO && property.offer.price_type === OfferPriceType.NETTO ? " netto" : "";

    if (hasDiscountPrice || (forceShowPrice && property.price > 0)) {
        propertyParams.push({
            name: "Cena",
            text: priceFormat(property.price, {unit: `${getCurrency({currency: property.offer.currency})}`})
        });
    }

    const planImage = property.plan_image?.p_img_375x250 ? property.plan_image.p_img_375x250 : undefined;
    const badgeType = getPropertyBadgeType(property);

    return (
        <a href={propertyLink} data-testId={OFFER_TEST_IDS.OFFER_VIEW.PROPERTY_BOX(property.id.toString())} target={target} onClick={onClick} css={linkStyle}>
            <div css={propertyCard} className={className}>
                <div css={badgeWrap}>
                    {badgeType === "reservation" && (
                        <Badge
                            variant="label_danger"
                            icon={(props) => (
                                <LockOutlineIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text as="span" strong variant="info_txt_1">
                                Rezerwacja
                            </Text>
                        </Badge>
                    )}
                    {badgeType === "promotion" && (
                        <Badge
                            variant="label_info"
                            icon={(props) => (
                                <PercentIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text variant="info_txt_2" as="span" strong>
                                Promocja
                            </Text>
                        </Badge>
                    )}
                    {badgeType === "recommended" && (
                        <Badge
                            variant="label_success"
                            icon={(props) => (
                                <LikeIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text as="span" strong variant="info_txt_1">
                                Polecane
                            </Text>
                        </Badge>
                    )}
                </div>
                {property.external_plan_url ? (
                    <CenteredImage
                        css={externalPlanImageStyle}
                        src={property.external_plan_url}
                        alt={offer.name}
                        width={375}
                        height={250}
                        breakpoints={[
                            {mediaWidth: "0", width: "304px", height: "272px"},
                            {mediaWidth: theme.breakpoints.sm, width: "232px", height: "200px"}
                        ]}
                    />
                ) : (
                    <CenteredImage
                        css={planImageStyle}
                        src={planImage}
                        alt={offer.name}
                        width={375}
                        height={250}
                        breakpoints={[{mediaWidth: "0", height: "200px", width: "100%"}]}
                    />
                )}
                <ul css={paramsList}>
                    {propertyParams.map((item, index) => {
                        const label = item.name === "Cena" ? `${item.name}${priceType}` : item.name;
                        return (
                            <li key={`itl${index}`} css={listItem}>
                                <Text css={mb(0.5)} variant="info_txt_3" color={theme.colors.gray[700]}>
                                    {label}
                                </Text>
                                <Text variant="info_txt_1">{item.text}</Text>
                            </li>
                        );
                    })}
                </ul>
                {hasDiscountPrice && (
                    <Text variant="info_txt_3" color={theme.colors.gray[700]} align="center">
                        Najniższa cena z ostatnich 30 dni, przed obniżką: <strong>{priceFormat(property.lowest_price_within_30_days as number)}</strong>
                    </Text>
                )}
            </div>
        </a>
    );
}

//  Styles
const linkStyle = css`
    &:hover {
        color: inherit;
    }
`;

export const propertyCard = css`
    ${elevation(2)};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(2)};
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
    ${borderRadius(2)};
    ${p(2, 1)};
    height: 100%;

    &:hover {
        ${elevation(3)}
    }
`;

const badgeWrap = css`
    position: absolute;
    top: ${calculateRemSize(1)};
    left: ${calculateRemSize(2)};
    z-index: 30;
`;

const paramsList = css`
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: ${calculateRemSize(2)};
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const planImageStyle = css`
    flex: 1 0 auto;
`;

const externalPlanImageStyle = css`
    flex: 1 0 auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;
import {css, useTheme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {CenteredImage} from "@pg-design/centered-image";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, mb, mr, p} from "@pg-design/helpers-css";
import {LikeIcon, LockOutlineIcon, PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, priceFormat} from "@pg-mono/string-utils";

import {useResponsiveLinkTarget} from "../../../../hooks/use_responsive_link_target";
import {IPropertyListProperty} from "../../../../property/types/PropertyListProperty";
import {getPropertyBadgeType} from "../../../../property/utils/get_property_badge_type";
import {isPropertyWithDiscountPrice} from "../../../../property/utils/is_property_with_discount_price";
import {Currency} from "../../../../types/Currency";
import {getCurrency} from "../../../../utils/get_currency";
import {IOfferModalOffer} from "../../../types/OfferModalOffer";
import {OfferPriceType} from "../../../types/OfferPriceType";
import {OfferType} from "../../../types/OfferType";

interface IProps {
    property: IPropertyListProperty;
    offer: IOfferModalOffer;
    className?: string;
    onClick?: () => void;
}

export function OfferModalPropertyCard(props: IProps) {
    const {property, offer, className, onClick} = props;
    const theme = useTheme();
    const target = useResponsiveLinkTarget();

    const propertyLink = rpAppLink.property.detail.base({
        vendorSlug: offer.vendor.slug,
        offerSlug: offer.slug,
        offerId: offer.id,
        propertyId: property.id
    });

    const propertyParams = [
        {
            name: "Metraż",
            text: areaFormat(property.area, {precision: 2})
        },
        {
            name: "Pokoje",
            text: property.rooms
        },
        {
            name: offer.type === OfferType.HOUSE ? "Piętra" : "Piętro",
            text: offer.type === OfferType.HOUSE ? property.floors : property.floor === 0 ? "parter" : property.floor
        }
    ];

    const hasDiscountPrice = isPropertyWithDiscountPrice(property);
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;
    const priceType = property.offer.currency === Currency.EURO && property.offer.price_type === OfferPriceType.NETTO ? " netto" : "";

    if (hasDiscountPrice || (forceShowPrice && property.price > 0)) {
        propertyParams.push({
            name: "Cena",
            text: priceFormat(property.price, {unit: `${getCurrency({currency: property.offer.currency})}`})
        });
    }

    const planImage = property.plan_image?.p_img_375x250 ? property.plan_image.p_img_375x250 : undefined;
    const badgeType = getPropertyBadgeType(property);

    return (
        <a href={propertyLink} data-testId={OFFER_TEST_IDS.OFFER_VIEW.PROPERTY_BOX(property.id.toString())} target={target} onClick={onClick} css={linkStyle}>
            <div css={propertyCard} className={className}>
                <div css={badgeWrap}>
                    {badgeType === "reservation" && (
                        <Badge
                            variant="label_danger"
                            icon={(props) => (
                                <LockOutlineIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text as="span" strong variant="info_txt_1">
                                Rezerwacja
                            </Text>
                        </Badge>
                    )}
                    {badgeType === "promotion" && (
                        <Badge
                            variant="label_info"
                            icon={(props) => (
                                <PercentIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text variant="info_txt_2" as="span" strong>
                                Promocja
                            </Text>
                        </Badge>
                    )}
                    {badgeType === "recommended" && (
                        <Badge
                            variant="label_success"
                            icon={(props) => (
                                <LikeIcon fill={props.fill} css={[props.className, mr(1.5)]} wrapperColor="transparent" wrapperSize="1.6" size="1.6" />
                            )}
                        >
                            <Text as="span" strong variant="info_txt_1">
                                Polecane
                            </Text>
                        </Badge>
                    )}
                </div>
                {property.external_plan_url ? (
                    <CenteredImage
                        css={externalPlanImageStyle}
                        src={property.external_plan_url}
                        alt={offer.name}
                        width={375}
                        height={250}
                        breakpoints={[
                            {mediaWidth: "0", width: "304px", height: "272px"},
                            {mediaWidth: theme.breakpoints.sm, width: "232px", height: "200px"}
                        ]}
                    />
                ) : (
                    <CenteredImage
                        css={planImageStyle}
                        src={planImage}
                        alt={offer.name}
                        width={375}
                        height={250}
                        breakpoints={[{mediaWidth: "0", height: "200px", width: "100%"}]}
                    />
                )}
                <ul css={paramsList}>
                    {propertyParams.map((item, index) => {
                        const label = item.name === "Cena" ? `${item.name}${priceType}` : item.name;
                        return (
                            <li key={`itl${index}`} css={listItem}>
                                <Text css={mb(0.5)} variant="info_txt_3" color={theme.colors.gray[700]}>
                                    {label}
                                </Text>
                                <Text variant="info_txt_1">{item.text}</Text>
                            </li>
                        );
                    })}
                </ul>
                {hasDiscountPrice && (
                    <Text variant="info_txt_3" color={theme.colors.gray[700]} align="center">
                        Najniższa cena z ostatnich 30 dni, przed obniżką: <strong>{priceFormat(property.lowest_price_within_30_days as number)}</strong>
                    </Text>
                )}
            </div>
        </a>
    );
}

//  Styles
const linkStyle = css`
    &:hover {
        color: inherit;
    }
`;

export const propertyCard = css`
    ${elevation(2)};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(2)};
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
    ${borderRadius(2)};
    ${p(2, 1)};
    height: 100%;

    &:hover {
        ${elevation(3)}
    }
`;

const badgeWrap = css`
    position: absolute;
    top: ${calculateRemSize(1)};
    left: ${calculateRemSize(2)};
    z-index: 30;
`;

const paramsList = css`
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: ${calculateRemSize(2)};
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const planImageStyle = css`
    flex: 1 0 auto;
`;

const externalPlanImageStyle = css`
    flex: 1 0 auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;
