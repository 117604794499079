import {useRef, useState} from "react";

import {usePrevious} from "@pg-mono/hooks";
import {useUserDevice} from "@pg-mono/user-device";

import {useClickOutside} from "../../hooks/handle_click_outside";
import {useGetOfferListQuery} from "../../offer/api/get_offer_list";
import {MapOfferSummaryTooltip} from "../../offer/components/MapOfferSummaryTooltip";
import {IGetNearbyMapOffersPayloadParams} from "../../offer/types/IGetNearbyMapOffersPayloadParams";
import {IOfferListMapOffer} from "../../offer/types/IOfferListMapOffer";
import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {OfferType} from "../../offer/types/OfferType";
import {getNearbyMapOffersPayload} from "../../offer/utils/get_nearby_map_offers_payload";
import {MapDivMarker} from "../components/map_with_poi/map_div_marker/MapDivMarker";
import {MapBottomSlotWrapper} from "../components/map_with_poi/MapBottomSlotWrapper";
import {PoiType} from "../utils/PoiType";
import {useTransformOfferIntoMapMarker} from "./use_transform_offer_into_map_marker";

interface IProps {
    baseOffer: {
        id: number;
        type: OfferType;
        stats?: {
            ranges_area_min?: number;
            ranges_area_max?: number;
            rooms?: number[];
        };
    };
    distance?: number;
    showPropertyNumberOnMarker?: boolean;
}

export const useMapNearbyOffersPois = (props: IProps) => {
    const {baseOffer, showPropertyNumberOnMarker} = props;
    const [activeOffer, setActiveOffer] = useState<IOfferListMapOffer | IOfferListOffer | null>(null);
    const bottomSlotRef = useRef<HTMLDivElement | null>(null);
    const {isMobile} = useUserDevice();
    const prevActiveOffer = usePrevious(activeOffer, activeOffer);
    const outsideClickTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useClickOutside(bottomSlotRef, () => {
        outsideClickTimeoutRef.current = setTimeout(() => {
            if (prevActiveOffer === activeOffer) {
                setActiveOffer(null);
            }
        }, 300);
    });

    const nearbyMapOffersPayloadParams: IGetNearbyMapOffersPayloadParams = {
        offer: {
            id: baseOffer.id,
            type: baseOffer.type
        },
        distance: props.distance
    };

    if ("stats" in baseOffer && baseOffer.stats) {
        nearbyMapOffersPayloadParams.offer.areaMin = baseOffer.stats.ranges_area_min;
        nearbyMapOffersPayloadParams.offer.areaMax = baseOffer.stats.ranges_area_max;
    }

    if ("stats" in baseOffer && baseOffer.stats && baseOffer.stats.rooms && baseOffer.stats.rooms.length) {
        nearbyMapOffersPayloadParams.offer.roomsMin = Math.min(...baseOffer.stats.rooms);
        nearbyMapOffersPayloadParams.offer.roomsMax = Math.max(...baseOffer.stats.rooms);
    }

    const {data: mapNearbyOfferList} = useGetOfferListQuery(getNearbyMapOffersPayload(nearbyMapOffersPayloadParams));

    const onOfferClick = (offer: IOfferListMapOffer | IOfferListOffer) => {
        if (isMobile) {
            if (outsideClickTimeoutRef.current) {
                clearTimeout(outsideClickTimeoutRef.current);
            }

            setActiveOffer(offer);
        }
    };

    const offerAdditionalPois = useTransformOfferIntoMapMarker({
        offers: mapNearbyOfferList?.results,
        renderer: showPropertyNumberOnMarker ? (offer) => <MapDivMarker text={`${offer.stats?.properties_count_for_sale || ""}`} /> : undefined,
        onClick: onOfferClick
    });

    const defaultPoiTypes = [PoiType.TRANSPORT, PoiType.OFFERS];

    const mapBottomSlot = activeOffer ? (
        <MapBottomSlotWrapper ref={bottomSlotRef}>
            <MapOfferSummaryTooltip type="horizontal" offerDetails={activeOffer} onCloseClick={() => setActiveOffer(null)} />
        </MapBottomSlotWrapper>
    ) : null;

    return {
        offerAdditionalPois,
        defaultPoiTypes,
        mapBottomSlot
    };
};
import {useRef, useState} from "react";

import {usePrevious} from "@pg-mono/hooks";
import {useUserDevice} from "@pg-mono/user-device";

import {useClickOutside} from "../../hooks/handle_click_outside";
import {useGetOfferListQuery} from "../../offer/api/get_offer_list";
import {MapOfferSummaryTooltip} from "../../offer/components/MapOfferSummaryTooltip";
import {IGetNearbyMapOffersPayloadParams} from "../../offer/types/IGetNearbyMapOffersPayloadParams";
import {IOfferListMapOffer} from "../../offer/types/IOfferListMapOffer";
import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {OfferType} from "../../offer/types/OfferType";
import {getNearbyMapOffersPayload} from "../../offer/utils/get_nearby_map_offers_payload";
import {MapDivMarker} from "../components/map_with_poi/map_div_marker/MapDivMarker";
import {MapBottomSlotWrapper} from "../components/map_with_poi/MapBottomSlotWrapper";
import {PoiType} from "../utils/PoiType";
import {useTransformOfferIntoMapMarker} from "./use_transform_offer_into_map_marker";

interface IProps {
    baseOffer: {
        id: number;
        type: OfferType;
        stats?: {
            ranges_area_min?: number;
            ranges_area_max?: number;
            rooms?: number[];
        };
    };
    distance?: number;
    showPropertyNumberOnMarker?: boolean;
}

export const useMapNearbyOffersPois = (props: IProps) => {
    const {baseOffer, showPropertyNumberOnMarker} = props;
    const [activeOffer, setActiveOffer] = useState<IOfferListMapOffer | IOfferListOffer | null>(null);
    const bottomSlotRef = useRef<HTMLDivElement | null>(null);
    const {isMobile} = useUserDevice();
    const prevActiveOffer = usePrevious(activeOffer, activeOffer);
    const outsideClickTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useClickOutside(bottomSlotRef, () => {
        outsideClickTimeoutRef.current = setTimeout(() => {
            if (prevActiveOffer === activeOffer) {
                setActiveOffer(null);
            }
        }, 300);
    });

    const nearbyMapOffersPayloadParams: IGetNearbyMapOffersPayloadParams = {
        offer: {
            id: baseOffer.id,
            type: baseOffer.type
        },
        distance: props.distance
    };

    if ("stats" in baseOffer && baseOffer.stats) {
        nearbyMapOffersPayloadParams.offer.areaMin = baseOffer.stats.ranges_area_min;
        nearbyMapOffersPayloadParams.offer.areaMax = baseOffer.stats.ranges_area_max;
    }

    if ("stats" in baseOffer && baseOffer.stats && baseOffer.stats.rooms && baseOffer.stats.rooms.length) {
        nearbyMapOffersPayloadParams.offer.roomsMin = Math.min(...baseOffer.stats.rooms);
        nearbyMapOffersPayloadParams.offer.roomsMax = Math.max(...baseOffer.stats.rooms);
    }

    const {data: mapNearbyOfferList} = useGetOfferListQuery(getNearbyMapOffersPayload(nearbyMapOffersPayloadParams));

    const onOfferClick = (offer: IOfferListMapOffer | IOfferListOffer) => {
        if (isMobile) {
            if (outsideClickTimeoutRef.current) {
                clearTimeout(outsideClickTimeoutRef.current);
            }

            setActiveOffer(offer);
        }
    };

    const offerAdditionalPois = useTransformOfferIntoMapMarker({
        offers: mapNearbyOfferList?.results,
        renderer: showPropertyNumberOnMarker ? (offer) => <MapDivMarker text={`${offer.stats?.properties_count_for_sale || ""}`} /> : undefined,
        onClick: onOfferClick
    });

    const defaultPoiTypes = [PoiType.TRANSPORT, PoiType.OFFERS];

    const mapBottomSlot = activeOffer ? (
        <MapBottomSlotWrapper ref={bottomSlotRef}>
            <MapOfferSummaryTooltip type="horizontal" offerDetails={activeOffer} onCloseClick={() => setActiveOffer(null)} />
        </MapBottomSlotWrapper>
    ) : null;

    return {
        offerAdditionalPois,
        defaultPoiTypes,
        mapBottomSlot
    };
};
