import React, {Fragment, useCallback, useDeferredValue, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {h100, mh, mt, ph, w100} from "@pg-design/helpers-css";
import {FiltersIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {useIsMounted} from "@pg-mono/hooks";
import type {ICircle, IFitBoundsType, IMarker, IPolygon, IPolygon3D, IPolyline} from "@pg-mono/open-street-map";
import {convertToArrayOfLatLngLiterals, convertToCountryLatLngLiteral, LazyOpenStreetMap} from "@pg-mono/open-street-map";
import {useUserDevice} from "@pg-mono/user-device";

import {osmPublicTileUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {useOfferListMapRailTransportElements} from "../../../offer/hooks/use_offer_list_map_rail_transport_elements";
import {OfferType} from "../../../offer/types/OfferType";
import {Country} from "../../../region/types/Country";
import {ViewType} from "../../../view_type/ViewType";
import {setActivePoi, setActivePoiDirections} from "../../actions/set_poi_travel_directions";
import {useOpenStreetMapAlgolyticsTracking} from "../../hooks/use_open_street_map_algolytics_tracking";
import {useOsmPoiRouteMapElements} from "../../hooks/use_osm_poi_route_map_elements";
import {useOSMPoisMapObjects} from "../../hooks/use_osm_pois_map_objects";
import {useOsmPoisRoutesGrouped} from "../../hooks/use_osm_pois_routes_grouped";
import {usePoiMapObjectsState} from "../../hooks/use_poi_map_objects_state";
import {IPolygonDefinition} from "../../types/IPolygonDefinition";
import {PoiType} from "../../utils/PoiType";
import {MapPlacesModal} from "../MapPlacesModal";
import {OfferInfoWindow} from "../OfferInfoWindow";
import {PoiSwitcher} from "../PoiSwitcher";
import {PoiTravelModeInfoWindow} from "../PoiTravelModeInfoWindow";
import {UserPoi} from "../UserPoi";

const offer_pin = require("../../images/pins/offer_pin.svg");

interface IProps {
    viewType: ViewType;
    offer: {
        id: number;
        name: string;
        type: OfferType;
        geo_area: {
            coordinates: {
                coordinates: number[][][];
            };
        };
        geo_point: {
            coordinates: [number, number];
        };
        region: {
            country: Country;
        };
        vendor: {
            id: number;
            name: string;
            slug: string;
        };
        address: string;
    };
    polygon?: {
        coordinates: number[][][];
    };
    region?: number;
    detailPolygons?: IPolygonDefinition[];
    disablePoiSwitch?: boolean;
    disableInitiallyOpenedPoiId?: boolean;
    drawPoiDistance?: boolean;
    onDistanceChange?: (distance: number) => void;
    showTransportLines?: boolean;
    initialPoiTypes?: PoiType[];
    mobilePoiModalTriggerPosition?: "right" | "center";
    mapConfig?: {
        scrollWheelZoom?: boolean;
        fitBounds?: IFitBoundsType;
        fitBoundsDefaultZoom?: number;
        maxZoom?: number;
    };
    mapBottomSlot?: React.ReactElement | null;
    customPoiMarkers?: IMarker[];
    customMarkers?: IMarker[];
    children?: (props: {
        setCheckedPoiTypes: (checkedPoiTypes: PoiType[]) => void;
        checkedPoiTypes: PoiType[];
        map: React.ReactElement;
        poiDistance: number;
        setPoiDistance: (newDistance: number) => void;
    }) => React.ReactElement;
    clusterMarkers?: boolean;
    className?: string;
    onFullscreenClick?: () => void;
}

export const OpenStreetMapsWithPoi = (props: IProps) => {
    const {offer} = props;
    const dispatch = useDispatch();
    const {isMobile} = useUserDevice();
    const theme = useTheme();

    const [showMapPlacesModal, setShowMapPlacesModal] = useState(false);

    const {checkedPoiTypes, onEndPoiEditing, onStartPoiEditing, setCheckedPoiTypes, poiDistance, setPoiDistance} = usePoiMapObjectsState(props.initialPoiTypes);

    const handleDistanceChange = (newDistance: number) => {
        setPoiDistance(newDistance);
        props.onDistanceChange?.(newDistance);
    };

    const {userPoiMarkers, poisMarkers, poiDirectionsPolylineCoord} = useOSMPoisMapObjects(props.offer, {
        disableInitiallyOpenedPoiId: props.disableInitiallyOpenedPoiId,
        checkedPoiTypes,
        radiusInMeters: poiDistance ? poiDistance * 1000 : undefined
    });

    const osmPoisRoutesGrouped = useOsmPoisRoutesGrouped({
        longitude: offer.geo_point.coordinates[0],
        latitude: offer.geo_point.coordinates[1],
        radius: poiDistance ? poiDistance * 1000 : 3000
    });
    const showTransportLines = Boolean(props.showTransportLines && checkedPoiTypes.includes(PoiType.TRANSPORT));
    const mapElements = useOfferListMapRailTransportElements({shouldFetch: showTransportLines, routes: [osmPoisRoutesGrouped]});

    const activePoi = useSelector((state: IRPStore) => state.maps.travelDirections.activePoi);
    const activePoiType = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiType);
    const activePoiRoute = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiRoute);

    const osmAlgolyticsTracking = useOpenStreetMapAlgolyticsTracking(props.viewType);

    const {polyline: activePoiRoutePolyline, markers: activePoiRouteMarkers} = useOsmPoiRouteMapElements(offer.geo_point.coordinates, activePoiRoute);

    const offerMarkerFitBounds = useMemo(
        () => ({
            center: convertToCountryLatLngLiteral(props.offer?.geo_point.coordinates || [], props.offer?.region.country),
            radius: 500
        }),
        [props.offer?.geo_point.coordinates[0], props.offer?.geo_point.coordinates[1]]
    );

    // markers
    const createMarkerList = (): IMarker[] => {
        const {offer} = props;

        if (offer) {
            return [
                {
                    id: offer.id,
                    coords: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
                    zIndexOffset: 2,
                    icon: {
                        url: offer_pin,
                        sizes: [30, 38]
                    },
                    popup: () => <OfferInfoWindow offer={offer} />,
                    popupShowCloseButton: true,
                    onClick: () => {
                        dispatch(setActivePoi(null, null));
                        dispatch(setActivePoiDirections(null));
                    }
                }
            ];
        }

        return [];
    };

    const createPoisMarkerList = useCallback(() => {
        const flatPoiList = checkedPoiTypes.reduce((acc, poiType) => {
            return acc.concat(...(poisMarkers[poiType] || []));
        }, [] as IMarker[]);

        const customPoiMarkers = (props.customPoiMarkers || []).filter((marker) => marker.poiType && checkedPoiTypes.includes(marker.poiType));

        return [...flatPoiList, ...customPoiMarkers];
    }, [poisMarkers, checkedPoiTypes]);

    const markers: IMarker[] = useMemo(
        () => [
            ...activePoiRouteMarkers,
            ...createMarkerList(),
            ...createPoisMarkerList(),
            ...userPoiMarkers,
            ...(props.customMarkers || []),
            ...(mapElements && mapElements.markers && showTransportLines ? mapElements.markers : [])
        ],
        [
            activePoiRouteMarkers,
            props.offer?.id,
            props.offer?.geo_point.coordinates[0],
            props.offer?.geo_point.coordinates[1],
            checkedPoiTypes,
            userPoiMarkers,
            props.customMarkers,
            poisMarkers,
            showTransportLines,
            mapElements
        ]
    );

    // polygons
    const createPolygonList = () => {
        if (props.offer) {
            return convertToArrayOfLatLngLiterals(props.offer.geo_area.coordinates.coordinates, {reversedValues: true});
        } else if (props.polygon) {
            return convertToArrayOfLatLngLiterals(props.polygon.coordinates[0], {reversedValues: true});
        }
    };

    const deferredMarkers = useDeferredValue(markers);

    const polygons: IPolygon[] = useMemo(
        () => [
            {
                id: "default-polygon",
                positions: createPolygonList() || [],

                pathOptions: {
                    fillColor: "#FFCDA5",
                    color: "#23232D",
                    fillOpacity: 0.8,
                    weight: 2
                }
            },
            ...(props.detailPolygons
                ? props.detailPolygons.map(
                      (detailPolygon): IPolygon3D => ({
                          id: JSON.stringify(detailPolygon.coords),
                          positions: convertToArrayOfLatLngLiterals(detailPolygon.coords, {reversedValues: true}),
                          height: detailPolygon.height || 1,
                          popup: detailPolygon?.infoWindow?.content
                              ? () => (
                                    <Text variant="headline_4" css={[ph()]}>
                                        {`${detailPolygon?.infoWindow?.content}`}
                                    </Text>
                                )
                              : undefined,
                          pathOptions: {
                              fillColor: detailPolygon.options?.fillColor,
                              color: detailPolygon.options?.strokeColor
                          }
                      })
                  )
                : [])
        ],
        [props.offer?.id, props.offer?.geo_point.coordinates[0], props.offer?.geo_point.coordinates[1], props.polygon, props.detailPolygons]
    );

    // polylines
    const polylines: IPolyline[] = useMemo(() => {
        const userPolyline: IPolyline = {
            id: "travel",
            positions: poiDirectionsPolylineCoord,
            pathOptions: {
                color: theme.colors.danger,
                weight: 5
            }
        };

        return [
            userPolyline,
            ...(activePoiRoutePolyline ? [activePoiRoutePolyline] : []),
            ...(mapElements && mapElements.polylines && showTransportLines ? mapElements.polylines : [])
        ];
    }, [poiDirectionsPolylineCoord, activePoiRoutePolyline, showTransportLines, mapElements]);

    const circles: ICircle[] | undefined = useMemo(() => {
        return props.drawPoiDistance
            ? [
                  {
                      center: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
                      radius: poiDistance * 1000,
                      pathOptions: {
                          color: "#EBFF00",
                          opacity: 0.3
                      }
                  }
              ]
            : [];
    }, [props.drawPoiDistance, offer.geo_point.coordinates, offer.region.country, poiDistance]);

    const onShowPoiSwitcherModal = () => {
        onStartPoiEditing();
        setShowMapPlacesModal(true);
    };

    const onClosePoiSwitcherModal = (cancelChanges: boolean) => {
        if (cancelChanges) {
            onEndPoiEditing();
        }

        setShowMapPlacesModal(false);
    };

    const isMarkerPopupDisabled = useCallback((marker: IMarker) => isMobile === true && marker.id !== props.offer?.id, [isMobile, props.offer?.id]);

    const map = React.useMemo(() => {
        return (
            <>
                <LazyOpenStreetMap
                    scrollWheelZoom={props.mapConfig?.scrollWheelZoom ?? true}
                    fitBounds={props.mapConfig?.fitBounds}
                    {...osmAlgolyticsTracking}
                    minFitBounds={offerMarkerFitBounds}
                    polygons={polygons}
                    markers={deferredMarkers}
                    polylines={polylines}
                    markerShowPopupOnHover={false}
                    markerDisablePopup={isMarkerPopupDisabled}
                    maxZoom={props.mapConfig?.maxZoom || 16}
                    clusterMarkers={props.clusterMarkers}
                    tileUrl={props.offer?.region.country === Country.SPAIN ? osmPublicTileUrl : null}
                    fitBoundsDefaultZoom={props.mapConfig?.fitBoundsDefaultZoom}
                    circles={circles}
                    onMarkerInvalidCoords={(marker) => {
                        const message = "Invalid marker coords in OpenStreetMapsWithPoi";

                        notifyBugsnagClient(new Error(message), message, JSON.stringify(marker));
                    }}
                    className={props.className}
                    onFullscreenClick={props.onFullscreenClick}
                />
            </>
        );
    }, [props.mapConfig?.scrollWheelZoom, osmAlgolyticsTracking.onMapMove, osmAlgolyticsTracking.onMapInit, polygons, polylines, markers]);

    const defaultRenderer = () => (
        <>
            {props.disablePoiSwitch ? null : (
                <div css={mapPlacesPosition}>
                    <PoiSwitcher
                        onChange={setCheckedPoiTypes}
                        checkedPoiTypes={checkedPoiTypes}
                        distanceValue={poiDistance}
                        onDistanceChange={handleDistanceChange}
                    />

                    <div css={[mt(2)]}>
                        <UserPoi offer={props.offer} />
                    </div>
                </div>
            )}

            {map}
        </>
    );

    const isMounted = useIsMounted();

    if (isMounted && props.offer) {
        return (
            <div css={[w100, h100]}>
                {props.disablePoiSwitch ? null : (
                    <FiltersIcon
                        wrapperSize="4"
                        size="2.4"
                        wrapperColor={theme.colors.primary}
                        onClick={onShowPoiSwitcherModal}
                        css={[mapPlacesButton, mapPlacesButtonPosition(props.mobilePoiModalTriggerPosition)]}
                    />
                )}

                {props.children
                    ? props.children({
                          setCheckedPoiTypes,
                          checkedPoiTypes,
                          poiDistance,
                          setPoiDistance: handleDistanceChange,
                          map
                      })
                    : defaultRenderer()}

                {props.disablePoiSwitch ? null : (
                    <MapPlacesModal modalState={showMapPlacesModal} onModalClose={onClosePoiSwitcherModal}>
                        <Fragment>
                            <PoiSwitcher
                                onChange={setCheckedPoiTypes}
                                checkedPoiTypes={checkedPoiTypes}
                                distanceValue={poiDistance}
                                onDistanceChange={handleDistanceChange}
                            />
                            <UserPoi offer={props.offer} />
                        </Fragment>
                    </MapPlacesModal>
                )}

                <div css={poiTravelModeInfoWindowMobile} id="poiTravelModeInfoWindowMobile">
                    {isMobile && activePoi && activePoiType ? (
                        <PoiTravelModeInfoWindow
                            calcTravelDataOnOpen
                            poi={activePoi}
                            poiType={activePoiType}
                            targetCoords={props.offer.geo_point.coordinates}
                        />
                    ) : null}
                </div>
                {props.mapBottomSlot ? <div css={mapBottomSlotStyle}>{props.mapBottomSlot}</div> : null}
            </div>
        );
    }

    return (
        <CenterBox>
            <Loader size="lg" />
        </CenterBox>
    );
};

const buttonsZIndex = 10;

const mapPlacesPosition = (theme: Theme) => css`
    position: absolute;
    top: 2rem;
    left: 2.5rem;
    z-index: ${buttonsZIndex};

    @media (max-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapPlacesButton = (theme: Theme) => css`
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: ${buttonsZIndex};
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapPlacesButtonPosition = (position: IProps["mobilePoiModalTriggerPosition"]) => css`
    ${position === "center"
        ? css`
              right: 0;
              left: 0;
              ${mh("auto")};
          `
        : ""};
`;

const poiTravelModeInfoWindowMobile = (theme: Theme) => css`
    position: fixed;
    bottom: 1.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: calc(100% - 3.2rem);

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapBottomSlotStyle = css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;
import React, {Fragment, useCallback, useDeferredValue, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {h100, mh, mt, ph, w100} from "@pg-design/helpers-css";
import {FiltersIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {useIsMounted} from "@pg-mono/hooks";
import type {ICircle, IFitBoundsType, IMarker, IPolygon, IPolygon3D, IPolyline} from "@pg-mono/open-street-map";
import {convertToArrayOfLatLngLiterals, convertToCountryLatLngLiteral, LazyOpenStreetMap} from "@pg-mono/open-street-map";
import {useUserDevice} from "@pg-mono/user-device";

import {osmPublicTileUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {useOfferListMapRailTransportElements} from "../../../offer/hooks/use_offer_list_map_rail_transport_elements";
import {OfferType} from "../../../offer/types/OfferType";
import {Country} from "../../../region/types/Country";
import {ViewType} from "../../../view_type/ViewType";
import {setActivePoi, setActivePoiDirections} from "../../actions/set_poi_travel_directions";
import {useOpenStreetMapAlgolyticsTracking} from "../../hooks/use_open_street_map_algolytics_tracking";
import {useOsmPoiRouteMapElements} from "../../hooks/use_osm_poi_route_map_elements";
import {useOSMPoisMapObjects} from "../../hooks/use_osm_pois_map_objects";
import {useOsmPoisRoutesGrouped} from "../../hooks/use_osm_pois_routes_grouped";
import {usePoiMapObjectsState} from "../../hooks/use_poi_map_objects_state";
import {IPolygonDefinition} from "../../types/IPolygonDefinition";
import {PoiType} from "../../utils/PoiType";
import {MapPlacesModal} from "../MapPlacesModal";
import {OfferInfoWindow} from "../OfferInfoWindow";
import {PoiSwitcher} from "../PoiSwitcher";
import {PoiTravelModeInfoWindow} from "../PoiTravelModeInfoWindow";
import {UserPoi} from "../UserPoi";

const offer_pin = require("../../images/pins/offer_pin.svg");

interface IProps {
    viewType: ViewType;
    offer: {
        id: number;
        name: string;
        type: OfferType;
        geo_area: {
            coordinates: {
                coordinates: number[][][];
            };
        };
        geo_point: {
            coordinates: [number, number];
        };
        region: {
            country: Country;
        };
        vendor: {
            id: number;
            name: string;
            slug: string;
        };
        address: string;
    };
    polygon?: {
        coordinates: number[][][];
    };
    region?: number;
    detailPolygons?: IPolygonDefinition[];
    disablePoiSwitch?: boolean;
    disableInitiallyOpenedPoiId?: boolean;
    drawPoiDistance?: boolean;
    onDistanceChange?: (distance: number) => void;
    showTransportLines?: boolean;
    initialPoiTypes?: PoiType[];
    mobilePoiModalTriggerPosition?: "right" | "center";
    mapConfig?: {
        scrollWheelZoom?: boolean;
        fitBounds?: IFitBoundsType;
        fitBoundsDefaultZoom?: number;
        maxZoom?: number;
    };
    mapBottomSlot?: React.ReactElement | null;
    customPoiMarkers?: IMarker[];
    customMarkers?: IMarker[];
    children?: (props: {
        setCheckedPoiTypes: (checkedPoiTypes: PoiType[]) => void;
        checkedPoiTypes: PoiType[];
        map: React.ReactElement;
        poiDistance: number;
        setPoiDistance: (newDistance: number) => void;
    }) => React.ReactElement;
    clusterMarkers?: boolean;
    className?: string;
    onFullscreenClick?: () => void;
}

export const OpenStreetMapsWithPoi = (props: IProps) => {
    const {offer} = props;
    const dispatch = useDispatch();
    const {isMobile} = useUserDevice();
    const theme = useTheme();

    const [showMapPlacesModal, setShowMapPlacesModal] = useState(false);

    const {checkedPoiTypes, onEndPoiEditing, onStartPoiEditing, setCheckedPoiTypes, poiDistance, setPoiDistance} = usePoiMapObjectsState(props.initialPoiTypes);

    const handleDistanceChange = (newDistance: number) => {
        setPoiDistance(newDistance);
        props.onDistanceChange?.(newDistance);
    };

    const {userPoiMarkers, poisMarkers, poiDirectionsPolylineCoord} = useOSMPoisMapObjects(props.offer, {
        disableInitiallyOpenedPoiId: props.disableInitiallyOpenedPoiId,
        checkedPoiTypes,
        radiusInMeters: poiDistance ? poiDistance * 1000 : undefined
    });

    const osmPoisRoutesGrouped = useOsmPoisRoutesGrouped({
        longitude: offer.geo_point.coordinates[0],
        latitude: offer.geo_point.coordinates[1],
        radius: poiDistance ? poiDistance * 1000 : 3000
    });
    const showTransportLines = Boolean(props.showTransportLines && checkedPoiTypes.includes(PoiType.TRANSPORT));
    const mapElements = useOfferListMapRailTransportElements({shouldFetch: showTransportLines, routes: [osmPoisRoutesGrouped]});

    const activePoi = useSelector((state: IRPStore) => state.maps.travelDirections.activePoi);
    const activePoiType = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiType);
    const activePoiRoute = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiRoute);

    const osmAlgolyticsTracking = useOpenStreetMapAlgolyticsTracking(props.viewType);

    const {polyline: activePoiRoutePolyline, markers: activePoiRouteMarkers} = useOsmPoiRouteMapElements(offer.geo_point.coordinates, activePoiRoute);

    const offerMarkerFitBounds = useMemo(
        () => ({
            center: convertToCountryLatLngLiteral(props.offer?.geo_point.coordinates || [], props.offer?.region.country),
            radius: 500
        }),
        [props.offer?.geo_point.coordinates[0], props.offer?.geo_point.coordinates[1]]
    );

    // markers
    const createMarkerList = (): IMarker[] => {
        const {offer} = props;

        if (offer) {
            return [
                {
                    id: offer.id,
                    coords: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
                    zIndexOffset: 2,
                    icon: {
                        url: offer_pin,
                        sizes: [30, 38]
                    },
                    popup: () => <OfferInfoWindow offer={offer} />,
                    popupShowCloseButton: true,
                    onClick: () => {
                        dispatch(setActivePoi(null, null));
                        dispatch(setActivePoiDirections(null));
                    }
                }
            ];
        }

        return [];
    };

    const createPoisMarkerList = useCallback(() => {
        const flatPoiList = checkedPoiTypes.reduce((acc, poiType) => {
            return acc.concat(...(poisMarkers[poiType] || []));
        }, [] as IMarker[]);

        const customPoiMarkers = (props.customPoiMarkers || []).filter((marker) => marker.poiType && checkedPoiTypes.includes(marker.poiType));

        return [...flatPoiList, ...customPoiMarkers];
    }, [poisMarkers, checkedPoiTypes]);

    const markers: IMarker[] = useMemo(
        () => [
            ...activePoiRouteMarkers,
            ...createMarkerList(),
            ...createPoisMarkerList(),
            ...userPoiMarkers,
            ...(props.customMarkers || []),
            ...(mapElements && mapElements.markers && showTransportLines ? mapElements.markers : [])
        ],
        [
            activePoiRouteMarkers,
            props.offer?.id,
            props.offer?.geo_point.coordinates[0],
            props.offer?.geo_point.coordinates[1],
            checkedPoiTypes,
            userPoiMarkers,
            props.customMarkers,
            poisMarkers,
            showTransportLines,
            mapElements
        ]
    );

    // polygons
    const createPolygonList = () => {
        if (props.offer) {
            return convertToArrayOfLatLngLiterals(props.offer.geo_area.coordinates.coordinates, {reversedValues: true});
        } else if (props.polygon) {
            return convertToArrayOfLatLngLiterals(props.polygon.coordinates[0], {reversedValues: true});
        }
    };

    const deferredMarkers = useDeferredValue(markers);

    const polygons: IPolygon[] = useMemo(
        () => [
            {
                id: "default-polygon",
                positions: createPolygonList() || [],

                pathOptions: {
                    fillColor: "#FFCDA5",
                    color: "#23232D",
                    fillOpacity: 0.8,
                    weight: 2
                }
            },
            ...(props.detailPolygons
                ? props.detailPolygons.map(
                      (detailPolygon): IPolygon3D => ({
                          id: JSON.stringify(detailPolygon.coords),
                          positions: convertToArrayOfLatLngLiterals(detailPolygon.coords, {reversedValues: true}),
                          height: detailPolygon.height || 1,
                          popup: detailPolygon?.infoWindow?.content
                              ? () => (
                                    <Text variant="headline_4" css={[ph()]}>
                                        {`${detailPolygon?.infoWindow?.content}`}
                                    </Text>
                                )
                              : undefined,
                          pathOptions: {
                              fillColor: detailPolygon.options?.fillColor,
                              color: detailPolygon.options?.strokeColor
                          }
                      })
                  )
                : [])
        ],
        [props.offer?.id, props.offer?.geo_point.coordinates[0], props.offer?.geo_point.coordinates[1], props.polygon, props.detailPolygons]
    );

    // polylines
    const polylines: IPolyline[] = useMemo(() => {
        const userPolyline: IPolyline = {
            id: "travel",
            positions: poiDirectionsPolylineCoord,
            pathOptions: {
                color: theme.colors.danger,
                weight: 5
            }
        };

        return [
            userPolyline,
            ...(activePoiRoutePolyline ? [activePoiRoutePolyline] : []),
            ...(mapElements && mapElements.polylines && showTransportLines ? mapElements.polylines : [])
        ];
    }, [poiDirectionsPolylineCoord, activePoiRoutePolyline, showTransportLines, mapElements]);

    const circles: ICircle[] | undefined = useMemo(() => {
        return props.drawPoiDistance
            ? [
                  {
                      center: convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country),
                      radius: poiDistance * 1000,
                      pathOptions: {
                          color: "#EBFF00",
                          opacity: 0.3
                      }
                  }
              ]
            : [];
    }, [props.drawPoiDistance, offer.geo_point.coordinates, offer.region.country, poiDistance]);

    const onShowPoiSwitcherModal = () => {
        onStartPoiEditing();
        setShowMapPlacesModal(true);
    };

    const onClosePoiSwitcherModal = (cancelChanges: boolean) => {
        if (cancelChanges) {
            onEndPoiEditing();
        }

        setShowMapPlacesModal(false);
    };

    const isMarkerPopupDisabled = useCallback((marker: IMarker) => isMobile === true && marker.id !== props.offer?.id, [isMobile, props.offer?.id]);

    const map = React.useMemo(() => {
        return (
            <>
                <LazyOpenStreetMap
                    scrollWheelZoom={props.mapConfig?.scrollWheelZoom ?? true}
                    fitBounds={props.mapConfig?.fitBounds}
                    {...osmAlgolyticsTracking}
                    minFitBounds={offerMarkerFitBounds}
                    polygons={polygons}
                    markers={deferredMarkers}
                    polylines={polylines}
                    markerShowPopupOnHover={false}
                    markerDisablePopup={isMarkerPopupDisabled}
                    maxZoom={props.mapConfig?.maxZoom || 16}
                    clusterMarkers={props.clusterMarkers}
                    tileUrl={props.offer?.region.country === Country.SPAIN ? osmPublicTileUrl : null}
                    fitBoundsDefaultZoom={props.mapConfig?.fitBoundsDefaultZoom}
                    circles={circles}
                    onMarkerInvalidCoords={(marker) => {
                        const message = "Invalid marker coords in OpenStreetMapsWithPoi";

                        notifyBugsnagClient(new Error(message), message, JSON.stringify(marker));
                    }}
                    className={props.className}
                    onFullscreenClick={props.onFullscreenClick}
                />
            </>
        );
    }, [props.mapConfig?.scrollWheelZoom, osmAlgolyticsTracking.onMapMove, osmAlgolyticsTracking.onMapInit, polygons, polylines, markers]);

    const defaultRenderer = () => (
        <>
            {props.disablePoiSwitch ? null : (
                <div css={mapPlacesPosition}>
                    <PoiSwitcher
                        onChange={setCheckedPoiTypes}
                        checkedPoiTypes={checkedPoiTypes}
                        distanceValue={poiDistance}
                        onDistanceChange={handleDistanceChange}
                    />

                    <div css={[mt(2)]}>
                        <UserPoi offer={props.offer} />
                    </div>
                </div>
            )}

            {map}
        </>
    );

    const isMounted = useIsMounted();

    if (isMounted && props.offer) {
        return (
            <div css={[w100, h100]}>
                {props.disablePoiSwitch ? null : (
                    <FiltersIcon
                        wrapperSize="4"
                        size="2.4"
                        wrapperColor={theme.colors.primary}
                        onClick={onShowPoiSwitcherModal}
                        css={[mapPlacesButton, mapPlacesButtonPosition(props.mobilePoiModalTriggerPosition)]}
                    />
                )}

                {props.children
                    ? props.children({
                          setCheckedPoiTypes,
                          checkedPoiTypes,
                          poiDistance,
                          setPoiDistance: handleDistanceChange,
                          map
                      })
                    : defaultRenderer()}

                {props.disablePoiSwitch ? null : (
                    <MapPlacesModal modalState={showMapPlacesModal} onModalClose={onClosePoiSwitcherModal}>
                        <Fragment>
                            <PoiSwitcher
                                onChange={setCheckedPoiTypes}
                                checkedPoiTypes={checkedPoiTypes}
                                distanceValue={poiDistance}
                                onDistanceChange={handleDistanceChange}
                            />
                            <UserPoi offer={props.offer} />
                        </Fragment>
                    </MapPlacesModal>
                )}

                <div css={poiTravelModeInfoWindowMobile} id="poiTravelModeInfoWindowMobile">
                    {isMobile && activePoi && activePoiType ? (
                        <PoiTravelModeInfoWindow
                            calcTravelDataOnOpen
                            poi={activePoi}
                            poiType={activePoiType}
                            targetCoords={props.offer.geo_point.coordinates}
                        />
                    ) : null}
                </div>
                {props.mapBottomSlot ? <div css={mapBottomSlotStyle}>{props.mapBottomSlot}</div> : null}
            </div>
        );
    }

    return (
        <CenterBox>
            <Loader size="lg" />
        </CenterBox>
    );
};

const buttonsZIndex = 10;

const mapPlacesPosition = (theme: Theme) => css`
    position: absolute;
    top: 2rem;
    left: 2.5rem;
    z-index: ${buttonsZIndex};

    @media (max-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapPlacesButton = (theme: Theme) => css`
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: ${buttonsZIndex};
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapPlacesButtonPosition = (position: IProps["mobilePoiModalTriggerPosition"]) => css`
    ${position === "center"
        ? css`
              right: 0;
              left: 0;
              ${mh("auto")};
          `
        : ""};
`;

const poiTravelModeInfoWindowMobile = (theme: Theme) => css`
    position: fixed;
    bottom: 1.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: calc(100% - 3.2rem);

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const mapBottomSlotStyle = css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;
