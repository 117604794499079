import {TravelMode} from "../../property/utils/TravelMode";
import {IPoi} from "../types/IPoi";
import {IPolylineCoordinates} from "../types/IPolylineCoordinates";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

export interface IPoiDirectionsData {
    distance: number;
    duration: number;
    polylineCoords: IPolylineCoordinates[];
}

export interface IActivePoiDirections {
    id: number;
    travelMode: TravelMode;
    poiType: PoiType;
}

const SET_POI_TRAVEL_DIRECTIONS = "poi/TRAVEL_DIRECTIONS";

export const setPoiTravelDirectionsActionTypes = {
    setActivePoi: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI`,
    setActivePoiDirections: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI_DIRECTIONS`,
    setPoisDirections: `${SET_POI_TRAVEL_DIRECTIONS}_SET_POIS_DIRECTIONS`,
    setActivePoiRoute: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI_ROUTE`
};

export const setActivePoi = (poi: IPoi | IUserPoi | null, poiType: PoiType | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoi, payload: {poi, poiType}};
};

export const setActivePoiDirections = (activePoiDirections: IActivePoiDirections | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoiDirections, payload: {activePoiDirections}};
};

export const setPoisDirections = (poiDirections: {id: number; travelMode: TravelMode; data: IPoiDirectionsData}) => {
    return {type: setPoiTravelDirectionsActionTypes.setPoisDirections, payload: {poiDirections}};
};

export const setActivePoiRoute = (poiRoute: IPublicTransportRoute | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoiRoute, payload: {poiRoute}};
};
import {TravelMode} from "../../property/utils/TravelMode";
import {IPoi} from "../types/IPoi";
import {IPolylineCoordinates} from "../types/IPolylineCoordinates";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

export interface IPoiDirectionsData {
    distance: number;
    duration: number;
    polylineCoords: IPolylineCoordinates[];
}

export interface IActivePoiDirections {
    id: number;
    travelMode: TravelMode;
    poiType: PoiType;
}

const SET_POI_TRAVEL_DIRECTIONS = "poi/TRAVEL_DIRECTIONS";

export const setPoiTravelDirectionsActionTypes = {
    setActivePoi: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI`,
    setActivePoiDirections: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI_DIRECTIONS`,
    setPoisDirections: `${SET_POI_TRAVEL_DIRECTIONS}_SET_POIS_DIRECTIONS`,
    setActivePoiRoute: `${SET_POI_TRAVEL_DIRECTIONS}_SET_ACTIVE_POI_ROUTE`
};

export const setActivePoi = (poi: IPoi | IUserPoi | null, poiType: PoiType | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoi, payload: {poi, poiType}};
};

export const setActivePoiDirections = (activePoiDirections: IActivePoiDirections | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoiDirections, payload: {activePoiDirections}};
};

export const setPoisDirections = (poiDirections: {id: number; travelMode: TravelMode; data: IPoiDirectionsData}) => {
    return {type: setPoiTravelDirectionsActionTypes.setPoisDirections, payload: {poiDirections}};
};

export const setActivePoiRoute = (poiRoute: IPublicTransportRoute | null) => {
    return {type: setPoiTravelDirectionsActionTypes.setActivePoiRoute, payload: {poiRoute}};
};
