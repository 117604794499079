import React, {useEffect} from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, mt, p, pt} from "@pg-design/helpers-css-strings";
import {CalendarCheckIcon, CloseIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Highlight, Text} from "@pg-design/text-module";
import {dateTimeFormat, formatFutureDate, priceM2Format} from "@pg-mono/string-utils";

import {poiAnalytics, PoiGTMModalAction} from "../../maps/tracking/poi_analytics";
import {getCurrency} from "../../utils/get_currency";
import {useOfferLink} from "../detail/hooks/use_offer_link";
import {getOfferTypePluralize} from "../helpers/OfferType";
import {IOfferListMapOffer} from "../types/IOfferListMapOffer";
import {IOfferListOffer} from "../types/IOfferListOffer";

interface IProps {
    offerDetails: IOfferListMapOffer | IOfferListOffer;
    type: "horizontal" | "vertical";
    onCloseClick?: () => void;
}

export const MapOfferSummaryTooltip = (props: IProps) => {
    const theme = useTheme();
    const {name, stats, region, vendor, type} = props.offerDetails;
    const currency = getCurrency({country: region.country});
    const propertiesCount = stats?.properties_count_for_sale || 0;
    const constructionEndDate = "construction_date_range" in props.offerDetails && props.offerDetails?.construction_date_range?.upper;
    const mainImage = "main_image" in props.offerDetails ? props.offerDetails.main_image?.m_img_375x211 : undefined;
    const offerUrl = useOfferLink(props.offerDetails);
    const isHorizontal = props.type === "horizontal";

    useEffect(() => {
        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_INVESTMENT_MARKER_CLICK, label: "investment"});
    }, []);

    const handleWrapperClick = () => {
        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_INVESTMENT_SELECT, label: "investment"});
        window.open(offerUrl, "_blank");
    };

    const handleCloseClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onCloseClick?.();
    };

    return (
        <div css={isHorizontal ? wrapperHorizontalStyle : wrapperVerticalStyle} onClick={handleWrapperClick}>
            {mainImage ? (
                <Image
                    imageStyle={{
                        objectFit: "cover"
                    }}
                    width={isHorizontal ? "135px" : "100%"}
                    height={isHorizontal ? "96px" : "100px"}
                    src={mainImage}
                    css={isHorizontal ? mainImageHorizontalWrapperStyle : mainImageVerticalWrapperStyle}
                    alt={`${name} - zdjęcie`}
                />
            ) : null}
            <div css={isHorizontal ? descriptionWrapperHorizontalStyle : descriptionWrapperVerticalStyle}>
                {isHorizontal ? (
                    <div css={closeIconStyle} onClick={handleCloseClick}>
                        <CloseIcon size="1.6" />
                    </div>
                ) : null}
                <Text as="div" variant="info_txt_1">
                    {name}
                </Text>
                <Text as="div" variant="info_txt_3" color={theme.colors.gray[600]}>
                    {vendor.name}
                </Text>

                {stats && "ranges_price_m2_min" in stats && stats?.ranges_price_m2_min ? (
                    <Text as="div" variant="info_txt_2" css={priceWrapperStyle}>
                        <Highlight>od {priceM2Format(stats.ranges_price_m2_min, {unit: currency})}</Highlight>
                    </Text>
                ) : null}

                <div css={pictogramsWrapperStyle}>
                    {propertiesCount > 0 ? (
                        <div css={pictogramWrapperStyle}>
                            <PropertyPlanIcon size="1.2" />
                            <Text as="span" variant="info_txt_3">
                                {propertiesCount} {getOfferTypePluralize(propertiesCount, type)}
                            </Text>
                        </div>
                    ) : null}
                    {constructionEndDate ? (
                        <div css={pictogramWrapperStyle}>
                            <CalendarCheckIcon size="1.2" />
                            <Text as="span" variant="info_txt_3">
                                {formatFutureDate(constructionEndDate, dateTimeFormat.quarterLong)}
                            </Text>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const wrapperVerticalStyle = css`
    min-width: 20rem;
    cursor: pointer;
`;

const wrapperHorizontalStyle = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
`;

const descriptionWrapperVerticalStyle = css`
    position: relative;
    ${p(1)};
`;

const descriptionWrapperHorizontalStyle = css`
    position: relative;
    ${p(1)};
    width: 100%;
`;

const priceWrapperStyle = css`
    ${mt(0.5)};
`;

const pictogramsWrapperStyle = css`
    ${pt(1)};
    display: flex;
    justify-content: space-between;
`;

const pictogramWrapperStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${calculateRemSize(0.5)};
`;

const mainImageVerticalWrapperStyle = css`
    width: 100%;
`;

const mainImageHorizontalWrapperStyle = css`
    flex: 0 0 135px;
`;

const closeIconStyle = css`
    ${p(1)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
`;
import React, {useEffect} from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, mt, p, pt} from "@pg-design/helpers-css-strings";
import {CalendarCheckIcon, CloseIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Highlight, Text} from "@pg-design/text-module";
import {dateTimeFormat, formatFutureDate, priceM2Format} from "@pg-mono/string-utils";

import {poiAnalytics, PoiGTMModalAction} from "../../maps/tracking/poi_analytics";
import {getCurrency} from "../../utils/get_currency";
import {useOfferLink} from "../detail/hooks/use_offer_link";
import {getOfferTypePluralize} from "../helpers/OfferType";
import {IOfferListMapOffer} from "../types/IOfferListMapOffer";
import {IOfferListOffer} from "../types/IOfferListOffer";

interface IProps {
    offerDetails: IOfferListMapOffer | IOfferListOffer;
    type: "horizontal" | "vertical";
    onCloseClick?: () => void;
}

export const MapOfferSummaryTooltip = (props: IProps) => {
    const theme = useTheme();
    const {name, stats, region, vendor, type} = props.offerDetails;
    const currency = getCurrency({country: region.country});
    const propertiesCount = stats?.properties_count_for_sale || 0;
    const constructionEndDate = "construction_date_range" in props.offerDetails && props.offerDetails?.construction_date_range?.upper;
    const mainImage = "main_image" in props.offerDetails ? props.offerDetails.main_image?.m_img_375x211 : undefined;
    const offerUrl = useOfferLink(props.offerDetails);
    const isHorizontal = props.type === "horizontal";

    useEffect(() => {
        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_INVESTMENT_MARKER_CLICK, label: "investment"});
    }, []);

    const handleWrapperClick = () => {
        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_INVESTMENT_SELECT, label: "investment"});
        window.open(offerUrl, "_blank");
    };

    const handleCloseClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onCloseClick?.();
    };

    return (
        <div css={isHorizontal ? wrapperHorizontalStyle : wrapperVerticalStyle} onClick={handleWrapperClick}>
            {mainImage ? (
                <Image
                    imageStyle={{
                        objectFit: "cover"
                    }}
                    width={isHorizontal ? "135px" : "100%"}
                    height={isHorizontal ? "96px" : "100px"}
                    src={mainImage}
                    css={isHorizontal ? mainImageHorizontalWrapperStyle : mainImageVerticalWrapperStyle}
                    alt={`${name} - zdjęcie`}
                />
            ) : null}
            <div css={isHorizontal ? descriptionWrapperHorizontalStyle : descriptionWrapperVerticalStyle}>
                {isHorizontal ? (
                    <div css={closeIconStyle} onClick={handleCloseClick}>
                        <CloseIcon size="1.6" />
                    </div>
                ) : null}
                <Text as="div" variant="info_txt_1">
                    {name}
                </Text>
                <Text as="div" variant="info_txt_3" color={theme.colors.gray[600]}>
                    {vendor.name}
                </Text>

                {stats && "ranges_price_m2_min" in stats && stats?.ranges_price_m2_min ? (
                    <Text as="div" variant="info_txt_2" css={priceWrapperStyle}>
                        <Highlight>od {priceM2Format(stats.ranges_price_m2_min, {unit: currency})}</Highlight>
                    </Text>
                ) : null}

                <div css={pictogramsWrapperStyle}>
                    {propertiesCount > 0 ? (
                        <div css={pictogramWrapperStyle}>
                            <PropertyPlanIcon size="1.2" />
                            <Text as="span" variant="info_txt_3">
                                {propertiesCount} {getOfferTypePluralize(propertiesCount, type)}
                            </Text>
                        </div>
                    ) : null}
                    {constructionEndDate ? (
                        <div css={pictogramWrapperStyle}>
                            <CalendarCheckIcon size="1.2" />
                            <Text as="span" variant="info_txt_3">
                                {formatFutureDate(constructionEndDate, dateTimeFormat.quarterLong)}
                            </Text>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const wrapperVerticalStyle = css`
    min-width: 20rem;
    cursor: pointer;
`;

const wrapperHorizontalStyle = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
`;

const descriptionWrapperVerticalStyle = css`
    position: relative;
    ${p(1)};
`;

const descriptionWrapperHorizontalStyle = css`
    position: relative;
    ${p(1)};
    width: 100%;
`;

const priceWrapperStyle = css`
    ${mt(0.5)};
`;

const pictogramsWrapperStyle = css`
    ${pt(1)};
    display: flex;
    justify-content: space-between;
`;

const pictogramWrapperStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${calculateRemSize(0.5)};
`;

const mainImageVerticalWrapperStyle = css`
    width: 100%;
`;

const mainImageHorizontalWrapperStyle = css`
    flex: 0 0 135px;
`;

const closeIconStyle = css`
    ${p(1)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
`;
