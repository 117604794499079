export const POI_DISTANCE_VALUES = [1, 3, 5];

export const POI_DISTANCE_DEFAULT_VALUE = 3;

export const poiDistanceSelectOptions = POI_DISTANCE_VALUES.map((value) => ({
    value,
    label: `+${value} km`
}));
export const POI_DISTANCE_VALUES = [1, 3, 5];

export const POI_DISTANCE_DEFAULT_VALUE = 3;

export const poiDistanceSelectOptions = POI_DISTANCE_VALUES.map((value) => ({
    value,
    label: `+${value} km`
}));
