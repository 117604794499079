import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {mb, ml, mt, pb, pointer} from "@pg-design/helpers-css";
import {EditIcon, TrashIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../app/rp_reducer";
import {removeUserPoi} from "../actions/manage_user_poi";
import {setActivePoiDirections} from "../actions/set_poi_travel_directions";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";
import {PoiTravelMode} from "./PoiTravelMode";

interface IProps {
    onUserPoiEdit: (poi: IUserPoi) => void;
    targetCoords?: [number, number];
}

const iconProps = {fillColor: "#ccc", size: "1.4"};

export const UserPoiList = (props: IProps) => {
    const dispatch = useDispatch();

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const onRemoveUserPoi = (id: number, name: string) => {
        dispatch(removeUserPoi(id));
        // Remove directions polyline from the map - if any was added
        dispatch(setActivePoiDirections(null));

        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.DELETE_MY_POI, label: name});
    };

    return (
        <Fragment>
            {userPoi.map((poi) => {
                return (
                    <div css={userPoiWrapper} key={poi.id}>
                        <div css={header}>
                            <div css={nameWrapper}>
                                <Text as="span" variant="body_copy_2">
                                    {poi.name}
                                </Text>
                            </div>
                            <div>
                                <span css={[ml(3), pointer]} onClick={() => props.onUserPoiEdit(poi)}>
                                    <EditIcon {...iconProps} />
                                </span>
                                <span css={[ml(3), pointer]} onClick={() => onRemoveUserPoi(poi.id, poi.name)}>
                                    <TrashIcon {...iconProps} />
                                </span>
                            </div>
                        </div>

                        <PoiTravelMode listenToActivePoiDirections poi={poi} poiType={PoiType.USER} targetCoords={props.targetCoords} />
                    </div>
                );
            })}
        </Fragment>
    );
};

const userPoiWrapper = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[300]};
    ${pb(2)};
    ${mb()};

    &:first-of-type {
        ${mt(3)};
    }

    &:last-of-type {
        ${mb(0)};
    }
`;

const header = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const nameWrapper = (theme: Theme) => css`
    word-break: break-word;
    max-width: 75%;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 70%;
    }
`;
import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {mb, ml, mt, pb, pointer} from "@pg-design/helpers-css";
import {EditIcon, TrashIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../app/rp_reducer";
import {removeUserPoi} from "../actions/manage_user_poi";
import {setActivePoiDirections} from "../actions/set_poi_travel_directions";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";
import {PoiTravelMode} from "./PoiTravelMode";

interface IProps {
    onUserPoiEdit: (poi: IUserPoi) => void;
    targetCoords?: [number, number];
}

const iconProps = {fillColor: "#ccc", size: "1.4"};

export const UserPoiList = (props: IProps) => {
    const dispatch = useDispatch();

    const userPoi = useSelector((state: IRPStore) => state.maps.userPoi);

    const onRemoveUserPoi = (id: number, name: string) => {
        dispatch(removeUserPoi(id));
        // Remove directions polyline from the map - if any was added
        dispatch(setActivePoiDirections(null));

        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.DELETE_MY_POI, label: name});
    };

    return (
        <Fragment>
            {userPoi.map((poi) => {
                return (
                    <div css={userPoiWrapper} key={poi.id}>
                        <div css={header}>
                            <div css={nameWrapper}>
                                <Text as="span" variant="body_copy_2">
                                    {poi.name}
                                </Text>
                            </div>
                            <div>
                                <span css={[ml(3), pointer]} onClick={() => props.onUserPoiEdit(poi)}>
                                    <EditIcon {...iconProps} />
                                </span>
                                <span css={[ml(3), pointer]} onClick={() => onRemoveUserPoi(poi.id, poi.name)}>
                                    <TrashIcon {...iconProps} />
                                </span>
                            </div>
                        </div>

                        <PoiTravelMode listenToActivePoiDirections poi={poi} poiType={PoiType.USER} targetCoords={props.targetCoords} />
                    </div>
                );
            })}
        </Fragment>
    );
};

const userPoiWrapper = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[300]};
    ${pb(2)};
    ${mb()};

    &:first-of-type {
        ${mt(3)};
    }

    &:last-of-type {
        ${mb(0)};
    }
`;

const header = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const nameWrapper = (theme: Theme) => css`
    word-break: break-word;
    max-width: 75%;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 70%;
    }
`;
