import {intervalToDuration} from "date-fns";

export enum TravelMode {
    DRIVING = "DRIVING",
    WALKING = "WALKING",
    TRANSIT = "TRANSIT"
}

/**
 * Format duration to readable format
 * @param value duration in seconds
 * @param minutesLabel custom label for minutes
 * @returns {string} readable duration format
 */
export const formatDuration = (value: number, minutesLabel = "min") => {
    const {days, hours, minutes} = intervalToDuration({start: 0, end: value * 1000});

    const daysToHours = days && days * 24;
    const hoursValue = daysToHours && !hours ? `${daysToHours} h` : daysToHours && hours ? `${daysToHours + hours} h` : hours ? `${hours} h` : "";
    const minutesValue = minutes ? `${minutes} ${minutesLabel}` : "";

    return `${hoursValue} ${minutesValue}`.trim();
};

export const getTravelModeWithDistanceTime = (mode: TravelMode, value: number) => {
    switch (mode) {
        case TravelMode.TRANSIT:
            return `komunikacją miejską ${formatDuration(value)}`;
        case TravelMode.DRIVING:
            return `samochodem ${formatDuration(value)}`;
        case TravelMode.WALKING:
            return `pieszo ${formatDuration(value)}`;
        default:
            throw Error("Unknown travel mode");
    }
};
import {intervalToDuration} from "date-fns";

export enum TravelMode {
    DRIVING = "DRIVING",
    WALKING = "WALKING",
    TRANSIT = "TRANSIT"
}

/**
 * Format duration to readable format
 * @param value duration in seconds
 * @param minutesLabel custom label for minutes
 * @returns {string} readable duration format
 */
export const formatDuration = (value: number, minutesLabel = "min") => {
    const {days, hours, minutes} = intervalToDuration({start: 0, end: value * 1000});

    const daysToHours = days && days * 24;
    const hoursValue = daysToHours && !hours ? `${daysToHours} h` : daysToHours && hours ? `${daysToHours + hours} h` : hours ? `${hours} h` : "";
    const minutesValue = minutes ? `${minutes} ${minutesLabel}` : "";

    return `${hoursValue} ${minutesValue}`.trim();
};

export const getTravelModeWithDistanceTime = (mode: TravelMode, value: number) => {
    switch (mode) {
        case TravelMode.TRANSIT:
            return `komunikacją miejską ${formatDuration(value)}`;
        case TravelMode.DRIVING:
            return `samochodem ${formatDuration(value)}`;
        case TravelMode.WALKING:
            return `pieszo ${formatDuration(value)}`;
        default:
            throw Error("Unknown travel mode");
    }
};
