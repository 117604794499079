import {compact} from "@pg-mono/nodash";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";
import {IOfferListOffer} from "../types/IOfferListOffer";

const offerListLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

export const getOfferList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferList: build.query<IPaginatedResponse<IOfferListOffer>, IGetOfferListPayload>({
            query: (params) => {
                return {
                    url: offerListLink,
                    params
                };
            }
        }),
        getPrivilegedOfferList: build.query<IPaginatedResponse<IOfferListOffer>, {include_offer: number[]}>({
            query: (params) => ({
                url: offerListLink,
                params
            }),
            transformResponse: (response: IPaginatedResponse<IOfferListOffer>, _meta, arg) => {
                // Sort privileged offers in the same order as in the URL
                const sortedPrivilegedOfferList = arg.include_offer.map((id) => response.results.find((privilegedOffer) => privilegedOffer.id === id));
                // `include_offer` query param (when used with `offer-list` scenario) may return `null` as a result - we need to filter out such values
                const results = compact(sortedPrivilegedOfferList);

                return {
                    results,
                    page: 1,
                    count: results.length,
                    next: null,
                    previous: null,
                    page_size: results.length
                };
            }
        })
    })
});

export const {useGetOfferListQuery, useGetPrivilegedOfferListQuery} = getOfferList;
import {compact} from "@pg-mono/nodash";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IGetOfferListPayload} from "../types/GetOfferListPayload";
import {IOfferListOffer} from "../types/IOfferListOffer";

const offerListLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

export const getOfferList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferList: build.query<IPaginatedResponse<IOfferListOffer>, IGetOfferListPayload>({
            query: (params) => {
                return {
                    url: offerListLink,
                    params
                };
            }
        }),
        getPrivilegedOfferList: build.query<IPaginatedResponse<IOfferListOffer>, {include_offer: number[]}>({
            query: (params) => ({
                url: offerListLink,
                params
            }),
            transformResponse: (response: IPaginatedResponse<IOfferListOffer>, _meta, arg) => {
                // Sort privileged offers in the same order as in the URL
                const sortedPrivilegedOfferList = arg.include_offer.map((id) => response.results.find((privilegedOffer) => privilegedOffer.id === id));
                // `include_offer` query param (when used with `offer-list` scenario) may return `null` as a result - we need to filter out such values
                const results = compact(sortedPrivilegedOfferList);

                return {
                    results,
                    page: 1,
                    count: results.length,
                    next: null,
                    previous: null,
                    page_size: results.length
                };
            }
        })
    })
});

export const {useGetOfferListQuery, useGetPrivilegedOfferListQuery} = getOfferList;
