import React, {useEffect, useRef} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Apla} from "@pg-design/apla-module";
import {elevation} from "@pg-design/elevation";
import {calculateRemSize, display, flex, flexAbsoluteCenter, h100, mb, ml, mt, onDesktop, p, pb, pt, w100} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {useAppSelector} from "../../../store/hooks";
import {OfferDetailLocationMap} from "../../detail/components/OfferDetailLocationMap";
import {createOfferLink} from "../../helpers/create_offer_link";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {OfferModalButtons} from "./OfferModalButtons";

interface IProps {
    modalName: IOfferModalName;
    offers: IOfferModalOffer[];
    paginationQuery: {
        page: number;
        pageSize: number;
    };
    totalCount: number;
    isExpanded: boolean;
    toggleExpand: () => void;
    onHeightUpdate?: (height: number) => void;
    selectedOffer: IOfferModalOffer;
    handleFiltersModalVisibility: (value: boolean) => void;
    handleSortModalVisibility: (value: boolean) => void;
}

export const OfferModalHeader = (props: IProps) => {
    const {
        modalName,
        offers,
        paginationQuery: {page, pageSize},
        isExpanded,
        toggleExpand,
        onHeightUpdate,
        handleFiltersModalVisibility,
        handleSortModalVisibility
    } = props;

    const isMobile = useIsMobile();
    const target = useResponsiveLinkTarget();
    const theme = useTheme();
    const modalRef = useRef<HTMLDivElement>(null);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);

    const offersStartingPosition = page > 1 ? (page - 1) * pageSize : 0;
    const offersWithPosition = offers.map((offer, index) => ({id: offer.id, position: offersStartingPosition + index, data: offer}));
    const selectedOffer = offersWithPosition.find((offer) => offer.id === offerId);

    if (!selectedOffer) {
        throw new Error(`Offer ${offerId} is not available in query`);
    }

    const offerDetails = selectedOffer.data;
    const offerLink = getOfferLink(offerDetails);

    useEffect(() => {
        let resizeObserver: null | ResizeObserver = null;

        if ("ResizeObserver" in window) {
            resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (onHeightUpdate && entries[0]) {
                    onHeightUpdate(entries[0].borderBoxSize?.[0]?.blockSize || entries[0].contentRect.height + entries[0].contentRect.top);
                }
            });
        }

        if (modalRef.current) {
            resizeObserver?.observe(modalRef.current);
        }

        return () => {
            if (modalRef.current) {
                resizeObserver?.unobserve(modalRef.current);
            }
        };
    }, []);

    return (
        <SystemModal.Header css={modalHeader} as="div" ref={modalRef}>
            <div css={headerStyles}>
                {offerDetails.vendor.logo && (
                    <Image css={logoImage} width="120px" height="90px" alt={`${offerDetails.vendor.name} logo`} src={offerDetails.vendor.logo.v_log_120x90} />
                )}

                <div css={w100}>
                    <Text as="h2" variant="headline_3" css={headerTextStyle}>
                        <a href={offerLink} target={target}>
                            {offerDetails.name}
                        </a>
                    </Text>

                    <Text as="address" variant="info_txt_1" css={grayText}>
                        <span className="bt">{offerDetails.address}</span>
                    </Text>
                    {!isExpanded && isMobile && (
                        <button type="button" onClick={toggleExpand} css={[flex("center"), mt(1)]}>
                            <ChevronDownIcon wrapperSize="1.6" size="1.6" wrapperColor="transparent" />
                            <Text variant="body_copy_2" css={ml(0.5)} color={theme.colors.secondary}>
                                Pokaż na mapie
                            </Text>
                        </button>
                    )}
                </div>
            </div>
            <ExpandablePanel isExpanded={isExpanded} css={elevation(0)}>
                <div css={expandablePanelWrapper}>
                    <SystemModal.Content css={expendableModalContent}>
                        <OfferDetailLocationMap
                            offer={props.selectedOffer}
                            css={offerDetailLocationMapStyle}
                            disablePoiSwitch
                            defaultZoom={15}
                            disableClusterMarkers
                        />
                    </SystemModal.Content>
                </div>
                {isExpanded && (
                    <div css={buttonWrap}>
                        <button type="button" onClick={toggleExpand}>
                            <ChevronUpIcon wrapperSize="4.8" size="2.4" wrapperColor="white" wrapperType="circle" css={elevation(2)} />
                        </button>
                    </div>
                )}
            </ExpandablePanel>
            {isMobile && (
                <OfferModalButtons
                    openFilters={() => handleFiltersModalVisibility(true)}
                    openSort={() => handleSortModalVisibility(true)}
                    modalName={modalName}
                />
            )}
            {isExpanded && <Apla variant="dark" applyOn={<div />} css={panelApla} />}
        </SystemModal.Header>
    );
};

/*
    Utils
 */
function getOfferLink(offerData: IOfferModalOffer | null) {
    if (!offerData) {
        return "";
    }

    return createOfferLink(offerData);
}

/*
    Components
 */
const ExpandablePanel = styled.div<{isExpanded: boolean}>`
    ${({theme, isExpanded}) => css`
        max-height: ${isExpanded ? "640px" : 0};
        transition: max-height ${theme.transition.timingFunction} ${theme.transition.duration};
        position: absolute;
        left: 0;
        z-index: 999;
        height: 78svh;
        width: 100%;
        background: ${theme.colors.gray[100]};
        ${elevation(1)};
        overflow: visible;
    `}
`;

const expendableModalContent = css`
    height: 100%;
    width: 100%;
    padding-bottom: ${calculateRemSize(1.5)};
    & .text-wrap {
        height: 100%;
    }
`;

const panelApla = css`
    position: fixed;
    inset: auto auto 0 0;
    width: 100%;
    height: 60%;
    z-index: 900;
`;

/*
    Styles
 */
const modalHeader = (theme: Theme) => css`
    flex: 0 0 auto;
    position: fixed;
    z-index: 40;
    width: 100%;
    ${pt(1.5)};
    ${pb(1)};
    ${mb(0)};
    background: ${theme.colors.gray[100]};
    ${elevation(1)};

    @media (min-width: ${theme.breakpoints.md}) {
        background-color: #fff;
    }

    ${onDesktop(css`
        position: static;
    `)}
`;

const headerStyles = (theme: Theme) => css`
    display: flex;
    column-gap: ${calculateRemSize(2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1.5, 0, 3, 0)};
    }
`;

const grayText = (theme: Theme) => css`
    ${p(0, 6, 0, 0)};
    color: ${theme.colors.gray[700]};
    line-height: 20px;
`;

const logoImage = (theme: Theme) => css`
    max-width: 8.6rem;
    max-height: 6.4rem;
    ${display("none")};

    & img {
        width: 100%;
        height: auto;
    }

    @media (min-width: ${theme.breakpoints.xs}) {
        max-width: 11.2rem;
        max-height: 8.4rem;
    }
`;

const buttonWrap = css`
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%, 50%);
`;

const expandablePanelWrapper = css`
    ${flexAbsoluteCenter};
    ${h100};
    overflow: hidden;
`;

const offerDetailLocationMapStyle = css`
    ${mt(2.5)}
    height: calc(100% - 20px);
`;

const headerTextStyle = css`
    ${mb(0.5)};
    max-width: calc(100% - ${calculateRemSize(3)});
`;
import React, {useEffect, useRef} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Apla} from "@pg-design/apla-module";
import {elevation} from "@pg-design/elevation";
import {calculateRemSize, display, flex, flexAbsoluteCenter, h100, mb, ml, mt, onDesktop, p, pb, pt, w100} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {useAppSelector} from "../../../store/hooks";
import {OfferDetailLocationMap} from "../../detail/components/OfferDetailLocationMap";
import {createOfferLink} from "../../helpers/create_offer_link";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {OfferModalButtons} from "./OfferModalButtons";

interface IProps {
    modalName: IOfferModalName;
    offers: IOfferModalOffer[];
    paginationQuery: {
        page: number;
        pageSize: number;
    };
    totalCount: number;
    isExpanded: boolean;
    toggleExpand: () => void;
    onHeightUpdate?: (height: number) => void;
    selectedOffer: IOfferModalOffer;
    handleFiltersModalVisibility: (value: boolean) => void;
    handleSortModalVisibility: (value: boolean) => void;
}

export const OfferModalHeader = (props: IProps) => {
    const {
        modalName,
        offers,
        paginationQuery: {page, pageSize},
        isExpanded,
        toggleExpand,
        onHeightUpdate,
        handleFiltersModalVisibility,
        handleSortModalVisibility
    } = props;

    const isMobile = useIsMobile();
    const target = useResponsiveLinkTarget();
    const theme = useTheme();
    const modalRef = useRef<HTMLDivElement>(null);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);

    const offersStartingPosition = page > 1 ? (page - 1) * pageSize : 0;
    const offersWithPosition = offers.map((offer, index) => ({id: offer.id, position: offersStartingPosition + index, data: offer}));
    const selectedOffer = offersWithPosition.find((offer) => offer.id === offerId);

    if (!selectedOffer) {
        throw new Error(`Offer ${offerId} is not available in query`);
    }

    const offerDetails = selectedOffer.data;
    const offerLink = getOfferLink(offerDetails);

    useEffect(() => {
        let resizeObserver: null | ResizeObserver = null;

        if ("ResizeObserver" in window) {
            resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (onHeightUpdate && entries[0]) {
                    onHeightUpdate(entries[0].borderBoxSize?.[0]?.blockSize || entries[0].contentRect.height + entries[0].contentRect.top);
                }
            });
        }

        if (modalRef.current) {
            resizeObserver?.observe(modalRef.current);
        }

        return () => {
            if (modalRef.current) {
                resizeObserver?.unobserve(modalRef.current);
            }
        };
    }, []);

    return (
        <SystemModal.Header css={modalHeader} as="div" ref={modalRef}>
            <div css={headerStyles}>
                {offerDetails.vendor.logo && (
                    <Image css={logoImage} width="120px" height="90px" alt={`${offerDetails.vendor.name} logo`} src={offerDetails.vendor.logo.v_log_120x90} />
                )}

                <div css={w100}>
                    <Text as="h2" variant="headline_3" css={headerTextStyle}>
                        <a href={offerLink} target={target}>
                            {offerDetails.name}
                        </a>
                    </Text>

                    <Text as="address" variant="info_txt_1" css={grayText}>
                        <span className="bt">{offerDetails.address}</span>
                    </Text>
                    {!isExpanded && isMobile && (
                        <button type="button" onClick={toggleExpand} css={[flex("center"), mt(1)]}>
                            <ChevronDownIcon wrapperSize="1.6" size="1.6" wrapperColor="transparent" />
                            <Text variant="body_copy_2" css={ml(0.5)} color={theme.colors.secondary}>
                                Pokaż na mapie
                            </Text>
                        </button>
                    )}
                </div>
            </div>
            <ExpandablePanel isExpanded={isExpanded} css={elevation(0)}>
                <div css={expandablePanelWrapper}>
                    <SystemModal.Content css={expendableModalContent}>
                        <OfferDetailLocationMap
                            offer={props.selectedOffer}
                            css={offerDetailLocationMapStyle}
                            disablePoiSwitch
                            defaultZoom={15}
                            disableClusterMarkers
                        />
                    </SystemModal.Content>
                </div>
                {isExpanded && (
                    <div css={buttonWrap}>
                        <button type="button" onClick={toggleExpand}>
                            <ChevronUpIcon wrapperSize="4.8" size="2.4" wrapperColor="white" wrapperType="circle" css={elevation(2)} />
                        </button>
                    </div>
                )}
            </ExpandablePanel>
            {isMobile && (
                <OfferModalButtons
                    openFilters={() => handleFiltersModalVisibility(true)}
                    openSort={() => handleSortModalVisibility(true)}
                    modalName={modalName}
                />
            )}
            {isExpanded && <Apla variant="dark" applyOn={<div />} css={panelApla} />}
        </SystemModal.Header>
    );
};

/*
    Utils
 */
function getOfferLink(offerData: IOfferModalOffer | null) {
    if (!offerData) {
        return "";
    }

    return createOfferLink(offerData);
}

/*
    Components
 */
const ExpandablePanel = styled.div<{isExpanded: boolean}>`
    ${({theme, isExpanded}) => css`
        max-height: ${isExpanded ? "640px" : 0};
        transition: max-height ${theme.transition.timingFunction} ${theme.transition.duration};
        position: absolute;
        left: 0;
        z-index: 999;
        height: 78svh;
        width: 100%;
        background: ${theme.colors.gray[100]};
        ${elevation(1)};
        overflow: visible;
    `}
`;

const expendableModalContent = css`
    height: 100%;
    width: 100%;
    padding-bottom: ${calculateRemSize(1.5)};
    & .text-wrap {
        height: 100%;
    }
`;

const panelApla = css`
    position: fixed;
    inset: auto auto 0 0;
    width: 100%;
    height: 60%;
    z-index: 900;
`;

/*
    Styles
 */
const modalHeader = (theme: Theme) => css`
    flex: 0 0 auto;
    position: fixed;
    z-index: 40;
    width: 100%;
    ${pt(1.5)};
    ${pb(1)};
    ${mb(0)};
    background: ${theme.colors.gray[100]};
    ${elevation(1)};

    @media (min-width: ${theme.breakpoints.md}) {
        background-color: #fff;
    }

    ${onDesktop(css`
        position: static;
    `)}
`;

const headerStyles = (theme: Theme) => css`
    display: flex;
    column-gap: ${calculateRemSize(2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1.5, 0, 3, 0)};
    }
`;

const grayText = (theme: Theme) => css`
    ${p(0, 6, 0, 0)};
    color: ${theme.colors.gray[700]};
    line-height: 20px;
`;

const logoImage = (theme: Theme) => css`
    max-width: 8.6rem;
    max-height: 6.4rem;
    ${display("none")};

    & img {
        width: 100%;
        height: auto;
    }

    @media (min-width: ${theme.breakpoints.xs}) {
        max-width: 11.2rem;
        max-height: 8.4rem;
    }
`;

const buttonWrap = css`
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%, 50%);
`;

const expandablePanelWrapper = css`
    ${flexAbsoluteCenter};
    ${h100};
    overflow: hidden;
`;

const offerDetailLocationMapStyle = css`
    ${mt(2.5)}
    height: calc(100% - 20px);
`;

const headerTextStyle = css`
    ${mb(0.5)};
    max-width: calc(100% - ${calculateRemSize(3)});
`;
