import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export const hitRailTransportRoute = () => {
    hitGoogleTagManager({
        event: "map_poi_railroad"
    });
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export const hitRailTransportRoute = () => {
    hitGoogleTagManager({
        event: "map_poi_railroad"
    });
};
