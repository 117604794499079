type Size = 100;

const height = (size: Size) => `
    height: ${size}%;
`;

export const minHeight = (size: string) => `
    min-height: ${size};
`;

export const maxHeight = (size: string) => `
    max-height: ${size};
`;

export const h100 = height(100);
type Size = 100;

const height = (size: Size) => `
    height: ${size}%;
`;

export const minHeight = (size: string) => `
    min-height: ${size};
`;

export const maxHeight = (size: string) => `
    max-height: ${size};
`;

export const h100 = height(100);
