import {propertyListSortOptions} from "../../property/constants/property_list_sort_options";
import {IPropertySortableField} from "../../property/interfaces/IPropertySortableField";
import {ISortOption} from "../../types/ISortOption";
import {SortOptionsValues} from "../detail/types/SortOptionsValues";

export const getSortValueBySortObj = (sortObj: ISortOption<IPropertySortableField> | null): SortOptionsValues => {
    const prefix = sortObj?.value === "desc" ? "-" : "";
    const fieldNameWithPrefix = `${prefix}${sortObj?.fieldName}`;
    const value = propertyListSortOptions.find((option) => option.slug === fieldNameWithPrefix)?.value;
    return value || SortOptionsValues.LOWEST_PRICE;
};
import {propertyListSortOptions} from "../../property/constants/property_list_sort_options";
import {IPropertySortableField} from "../../property/interfaces/IPropertySortableField";
import {ISortOption} from "../../types/ISortOption";
import {SortOptionsValues} from "../detail/types/SortOptionsValues";

export const getSortValueBySortObj = (sortObj: ISortOption<IPropertySortableField> | null): SortOptionsValues => {
    const prefix = sortObj?.value === "desc" ? "-" : "";
    const fieldNameWithPrefix = `${prefix}${sortObj?.fieldName}`;
    const value = propertyListSortOptions.find((option) => option.slug === fieldNameWithPrefix)?.value;
    return value || SortOptionsValues.LOWEST_PRICE;
};
