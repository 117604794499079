import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

enum RoutesGTMEvent {
    busRoute = "map_poi_bus_route"
}

export const hitGtmBusRouteClick = () => {
    hitGoogleTagManager({
        event: RoutesGTMEvent.busRoute
    });
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

enum RoutesGTMEvent {
    busRoute = "map_poi_bus_route"
}

export const hitGtmBusRouteClick = () => {
    hitGoogleTagManager({
        event: RoutesGTMEvent.busRoute
    });
};
