import React, {useEffect, useRef} from "react";
import {css} from "@emotion/react";

import {calculateRemSize, onDesktop, p, pv} from "@pg-design/helpers-css";
import {SystemModal} from "@pg-design/modal-module";
import {useIsMobile} from "@pg-mono/hooks";

import {IFiltersFormValues, PropertyListFiltersModal} from "../../../property/components/PropertyListFiltersModal";
import {PropertyListSortOptionsModal} from "../../../property/components/PropertyListSortOptionsModal";
import {propertyListSortOptions} from "../../../property/constants/property_list_sort_options";
import {IPropertySortableField} from "../../../property/interfaces/IPropertySortableField";
import {AreaFilter} from "../../../real_estate/components/filters/AreaFilter";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ISortOption} from "../../../types/ISortOption";
import {useUserPreferencesFilters} from "../../../user/hooks/use_user_preferences_filters";
import {SortOptionsValues} from "../../detail/types/SortOptionsValues";
import {useOfferModalProperties} from "../../hooks/use_offer_modal_properties";
import {offerModalInitialState} from "../../state/offer_modal_initial_state";
import {resetPropertyList, setOfferModalFilters, setOfferModalSort} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {getSortValueBySortObj} from "../../utils/get_sort_value_by_sort_obj";
import {FloorFilter} from "./FloorFilter";
import {OfferModalButtons} from "./OfferModalButtons";
import {OfferModalPropertyTable} from "./OfferModalPropertyTable";
import {OfferModalTilesList} from "./OfferModalTilesList";
import {RoomFilter} from "./RoomFilter";

interface IProps {
    handleFiltersModalVisibility: (value: boolean) => void;
    handleSortModalVisibility: (value: boolean) => void;
    filtersModalOpened: boolean;
    mobileSortModalOpened: boolean;
    modalName: IOfferModalName;
    offerDetails: IOfferModalOffer;
    hideFilters?: boolean;
    userPreferenceFilters?: ReturnType<typeof useUserPreferencesFilters>;
}

export function OfferModalList(props: IProps) {
    const {modalName, offerDetails, userPreferenceFilters, filtersModalOpened, handleFiltersModalVisibility, handleSortModalVisibility, mobileSortModalOpened} =
        props;

    const modalListRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const propertyListType = useAppSelector((state) => state.offerModals[modalName].type);
    const sortValue = useAppSelector((state) => getSortValueBySortObj(state.offerModals[modalName].sort));
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);
    const rooms = useAppSelector((state) => state.offerModals[modalName].filters.rooms);

    const offerDetailProperties = useOfferModalProperties(modalName, userPreferenceFilters);

    useEffect(() => {
        dispatch(resetPropertyList({modalName}));
    }, [propertyListType]);

    useEffect(() => {
        if (modalListRef.current) {
            /**
             * Reset scroll position when offer changes.
             * It may happen that scroll position is kept while switching between offers, and
             * in this scenario, we could've been deep down in property list.
             */
            modalListRef.current.scrollTop = 0;
        }
    }, [offerDetails.id]);

    const handlers = {
        saveFilters: (data: IFiltersFormValues) => {
            handleFiltersModalVisibility(false);

            const floorChoicesMap: {[index: number]: boolean} = {
                1: data.floorGround,
                2: data.floorGroundWithGarden,
                3: data.floorOneToFour,
                4: data.floorFivePlus,
                5: data.floorLast,
                6: data.floorLastWithTerrace
            };

            const floorChoices = Object.keys(floorChoicesMap)
                .filter((floorChoice) => Boolean(floorChoicesMap[parseInt(floorChoice)]))
                .map((floorChoice) => parseInt(floorChoice));

            dispatch(
                setOfferModalFilters({
                    modalName,
                    filters: {
                        area: data.area,
                        rooms: data.rooms,
                        floor_choices: floorChoices
                    }
                })
            );
        },
        openFilters: () => {
            handleFiltersModalVisibility(true);
        },
        closeFilters: () => {
            handleFiltersModalVisibility(false);
            dispatch(setOfferModalFilters({modalName, filters: offerModalInitialState.filters}));
        },
        openSort: () => {
            handleSortModalVisibility(true);
        },
        saveSort: (newValue: SortOptionsValues) => {
            if (isMobile) {
                handleSortModalVisibility(false);
            }
            const newSort = getSortObjByValue(newValue);
            dispatch(setOfferModalSort({modalName, sort: newSort}));
        },
        clearSort: () => {
            const newSort = getSortObjByValue(SortOptionsValues.LOWEST_PRICE);
            dispatch(setOfferModalSort({modalName, sort: newSort}));
            handleSortModalVisibility(false);
        },
        closeSort: () => {
            handleSortModalVisibility(false);
        }
    };

    const intersectionId = isMobile ? "offer-modal-layout" : "offer-modal-list";

    return (
        <div css={modalList}>
            <SystemModal.Content css={resetPaddingOnDesktop}>
                <div css={topBar}>
                    <div css={topBarRowA}>
                        <RoomFilter
                            css={barFilter}
                            initialValue={rooms}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {...filters, rooms: value}
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            rooms: {lower: "", upper: ""}
                                        }
                                    })
                                );
                            }}
                        />
                        <FloorFilter
                            css={barFilter}
                            id="offer-modal-floors"
                            initialValue={filters.floor_choices}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            floor_choices: value
                                        }
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            floor_choices: []
                                        }
                                    })
                                );
                            }}
                        />
                        <AreaFilter
                            css={barFilter}
                            initialValue={filters.area}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            area: value
                                        }
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            area: {lower: "", upper: ""}
                                        }
                                    })
                                );
                            }}
                        />
                    </div>
                    {!isMobile && <OfferModalButtons openFilters={handlers.openFilters} openSort={handlers.openSort} modalName={modalName} />}
                </div>
            </SystemModal.Content>
            <div id="offer-modal-list" css={listWrap} ref={modalListRef}>
                {propertyListType === "tiles" && (
                    <OfferModalTilesList
                        modalName={modalName}
                        offer={offerDetails}
                        propertiesQuery={offerDetailProperties}
                        intersectionContainerId={intersectionId}
                    />
                )}
                {propertyListType === "table" && (
                    <OfferModalPropertyTable
                        modalName={modalName}
                        propertiesQuery={offerDetailProperties}
                        offer={offerDetails}
                        intersectionContainerId={intersectionId}
                    />
                )}
            </div>
            <PropertyListFiltersModal isOpen={filtersModalOpened} handleClose={handlers.closeFilters} handleConfirm={handlers.saveFilters} />
            <PropertyListSortOptionsModal
                onClear={handlers.clearSort}
                onSave={handlers.saveSort}
                onClose={handlers.closeSort}
                isOpen={mobileSortModalOpened}
                defaultValue={sortValue || SortOptionsValues.LOWEST_PRICE}
            />
        </div>
    );
}

const getSortObjByValue = (value: number): ISortOption<IPropertySortableField> | null => {
    const option = propertyListSortOptions.find((option) => option.value === value);
    if (!option) {
        return null;
    }

    const isDesc = option.slug.includes("-");
    if (isDesc) {
        return {
            fieldName: option.slug.slice(1) as IPropertySortableField,
            value: "desc"
        };
    }

    return {
        fieldName: option.slug as IPropertySortableField,
        value: "asc"
    };
};

/*
    Styles
 */
const modalList = css`
    width: 100%;
    position: relative;

    ${onDesktop(css`
        display: flex;
        flex-direction: column;
    `)}
`;

const listWrap = css`
    ${onDesktop(css`
        overflow-y: auto;
        overflow-x: hidden;

        /*
          A hacky way to achieve horizontal overflow: visible (for shadows)
         */
        margin: 0 -${calculateRemSize(2)};
        padding: 0 ${calculateRemSize(2)};
    `)}
`;

const topBar = css`
    display: flex;
    justify-content: space-between;
    ${pv(2)}

    ${onDesktop(css`
        display: flex;
        flex-direction: column;
        row-gap: ${calculateRemSize(3)};
    `)}
`;

const topBarRowA = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        flex: 1 1 auto;
        column-gap: ${calculateRemSize(2)};
    `)}
`;

const barFilter = css`
    flex: 1 1 33%;
    min-width: 0;
`;

const resetPaddingOnDesktop = css`
    display: none;
    ${onDesktop(css`
        display: block;
        ${p(0)}
    `)}
`;
import React, {useEffect, useRef} from "react";
import {css} from "@emotion/react";

import {calculateRemSize, onDesktop, p, pv} from "@pg-design/helpers-css";
import {SystemModal} from "@pg-design/modal-module";
import {useIsMobile} from "@pg-mono/hooks";

import {IFiltersFormValues, PropertyListFiltersModal} from "../../../property/components/PropertyListFiltersModal";
import {PropertyListSortOptionsModal} from "../../../property/components/PropertyListSortOptionsModal";
import {propertyListSortOptions} from "../../../property/constants/property_list_sort_options";
import {IPropertySortableField} from "../../../property/interfaces/IPropertySortableField";
import {AreaFilter} from "../../../real_estate/components/filters/AreaFilter";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ISortOption} from "../../../types/ISortOption";
import {useUserPreferencesFilters} from "../../../user/hooks/use_user_preferences_filters";
import {SortOptionsValues} from "../../detail/types/SortOptionsValues";
import {useOfferModalProperties} from "../../hooks/use_offer_modal_properties";
import {offerModalInitialState} from "../../state/offer_modal_initial_state";
import {resetPropertyList, setOfferModalFilters, setOfferModalSort} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {getSortValueBySortObj} from "../../utils/get_sort_value_by_sort_obj";
import {FloorFilter} from "./FloorFilter";
import {OfferModalButtons} from "./OfferModalButtons";
import {OfferModalPropertyTable} from "./OfferModalPropertyTable";
import {OfferModalTilesList} from "./OfferModalTilesList";
import {RoomFilter} from "./RoomFilter";

interface IProps {
    handleFiltersModalVisibility: (value: boolean) => void;
    handleSortModalVisibility: (value: boolean) => void;
    filtersModalOpened: boolean;
    mobileSortModalOpened: boolean;
    modalName: IOfferModalName;
    offerDetails: IOfferModalOffer;
    hideFilters?: boolean;
    userPreferenceFilters?: ReturnType<typeof useUserPreferencesFilters>;
}

export function OfferModalList(props: IProps) {
    const {modalName, offerDetails, userPreferenceFilters, filtersModalOpened, handleFiltersModalVisibility, handleSortModalVisibility, mobileSortModalOpened} =
        props;

    const modalListRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const propertyListType = useAppSelector((state) => state.offerModals[modalName].type);
    const sortValue = useAppSelector((state) => getSortValueBySortObj(state.offerModals[modalName].sort));
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);
    const rooms = useAppSelector((state) => state.offerModals[modalName].filters.rooms);

    const offerDetailProperties = useOfferModalProperties(modalName, userPreferenceFilters);

    useEffect(() => {
        dispatch(resetPropertyList({modalName}));
    }, [propertyListType]);

    useEffect(() => {
        if (modalListRef.current) {
            /**
             * Reset scroll position when offer changes.
             * It may happen that scroll position is kept while switching between offers, and
             * in this scenario, we could've been deep down in property list.
             */
            modalListRef.current.scrollTop = 0;
        }
    }, [offerDetails.id]);

    const handlers = {
        saveFilters: (data: IFiltersFormValues) => {
            handleFiltersModalVisibility(false);

            const floorChoicesMap: {[index: number]: boolean} = {
                1: data.floorGround,
                2: data.floorGroundWithGarden,
                3: data.floorOneToFour,
                4: data.floorFivePlus,
                5: data.floorLast,
                6: data.floorLastWithTerrace
            };

            const floorChoices = Object.keys(floorChoicesMap)
                .filter((floorChoice) => Boolean(floorChoicesMap[parseInt(floorChoice)]))
                .map((floorChoice) => parseInt(floorChoice));

            dispatch(
                setOfferModalFilters({
                    modalName,
                    filters: {
                        area: data.area,
                        rooms: data.rooms,
                        floor_choices: floorChoices
                    }
                })
            );
        },
        openFilters: () => {
            handleFiltersModalVisibility(true);
        },
        closeFilters: () => {
            handleFiltersModalVisibility(false);
            dispatch(setOfferModalFilters({modalName, filters: offerModalInitialState.filters}));
        },
        openSort: () => {
            handleSortModalVisibility(true);
        },
        saveSort: (newValue: SortOptionsValues) => {
            if (isMobile) {
                handleSortModalVisibility(false);
            }
            const newSort = getSortObjByValue(newValue);
            dispatch(setOfferModalSort({modalName, sort: newSort}));
        },
        clearSort: () => {
            const newSort = getSortObjByValue(SortOptionsValues.LOWEST_PRICE);
            dispatch(setOfferModalSort({modalName, sort: newSort}));
            handleSortModalVisibility(false);
        },
        closeSort: () => {
            handleSortModalVisibility(false);
        }
    };

    const intersectionId = isMobile ? "offer-modal-layout" : "offer-modal-list";

    return (
        <div css={modalList}>
            <SystemModal.Content css={resetPaddingOnDesktop}>
                <div css={topBar}>
                    <div css={topBarRowA}>
                        <RoomFilter
                            css={barFilter}
                            initialValue={rooms}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {...filters, rooms: value}
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            rooms: {lower: "", upper: ""}
                                        }
                                    })
                                );
                            }}
                        />
                        <FloorFilter
                            css={barFilter}
                            id="offer-modal-floors"
                            initialValue={filters.floor_choices}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            floor_choices: value
                                        }
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            floor_choices: []
                                        }
                                    })
                                );
                            }}
                        />
                        <AreaFilter
                            css={barFilter}
                            initialValue={filters.area}
                            onConfirm={(value) => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            area: value
                                        }
                                    })
                                );
                            }}
                            handleClear={() => {
                                dispatch(
                                    setOfferModalFilters({
                                        modalName,
                                        filters: {
                                            ...filters,
                                            area: {lower: "", upper: ""}
                                        }
                                    })
                                );
                            }}
                        />
                    </div>
                    {!isMobile && <OfferModalButtons openFilters={handlers.openFilters} openSort={handlers.openSort} modalName={modalName} />}
                </div>
            </SystemModal.Content>
            <div id="offer-modal-list" css={listWrap} ref={modalListRef}>
                {propertyListType === "tiles" && (
                    <OfferModalTilesList
                        modalName={modalName}
                        offer={offerDetails}
                        propertiesQuery={offerDetailProperties}
                        intersectionContainerId={intersectionId}
                    />
                )}
                {propertyListType === "table" && (
                    <OfferModalPropertyTable
                        modalName={modalName}
                        propertiesQuery={offerDetailProperties}
                        offer={offerDetails}
                        intersectionContainerId={intersectionId}
                    />
                )}
            </div>
            <PropertyListFiltersModal isOpen={filtersModalOpened} handleClose={handlers.closeFilters} handleConfirm={handlers.saveFilters} />
            <PropertyListSortOptionsModal
                onClear={handlers.clearSort}
                onSave={handlers.saveSort}
                onClose={handlers.closeSort}
                isOpen={mobileSortModalOpened}
                defaultValue={sortValue || SortOptionsValues.LOWEST_PRICE}
            />
        </div>
    );
}

const getSortObjByValue = (value: number): ISortOption<IPropertySortableField> | null => {
    const option = propertyListSortOptions.find((option) => option.value === value);
    if (!option) {
        return null;
    }

    const isDesc = option.slug.includes("-");
    if (isDesc) {
        return {
            fieldName: option.slug.slice(1) as IPropertySortableField,
            value: "desc"
        };
    }

    return {
        fieldName: option.slug as IPropertySortableField,
        value: "asc"
    };
};

/*
    Styles
 */
const modalList = css`
    width: 100%;
    position: relative;

    ${onDesktop(css`
        display: flex;
        flex-direction: column;
    `)}
`;

const listWrap = css`
    ${onDesktop(css`
        overflow-y: auto;
        overflow-x: hidden;

        /*
          A hacky way to achieve horizontal overflow: visible (for shadows)
         */
        margin: 0 -${calculateRemSize(2)};
        padding: 0 ${calculateRemSize(2)};
    `)}
`;

const topBar = css`
    display: flex;
    justify-content: space-between;
    ${pv(2)}

    ${onDesktop(css`
        display: flex;
        flex-direction: column;
        row-gap: ${calculateRemSize(3)};
    `)}
`;

const topBarRowA = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        flex: 1 1 auto;
        column-gap: ${calculateRemSize(2)};
    `)}
`;

const barFilter = css`
    flex: 1 1 33%;
    min-width: 0;
`;

const resetPaddingOnDesktop = css`
    display: none;
    ${onDesktop(css`
        display: block;
        ${p(0)}
    `)}
`;
