import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Field, FieldProps, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {mb, mt, w100} from "@pg-design/helpers-css";
import {FieldWrapper, Input} from "@pg-design/inputs-module";
import {countDistance} from "@pg-mono/geo-utils";
import {useIsMobile} from "@pg-mono/hooks";
import {convertToLatLngLiteralOfPoland} from "@pg-mono/open-street-map";

import {IRPStore} from "../../app/rp_reducer";
import {validationMessages} from "../../form/utils/validation_messages";
import {addUserPoi, editUserPoi} from "../actions/manage_user_poi";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {isValidLocationObject} from "../utils/is_valid_location_object";
import {PlacesAutocomplete} from "./PlacesAutocomplete";

const defaultInitialValues = {
    name: "",
    location: {
        label: "",
        value: "" as unknown as number,
        coordinates: [] as number[]
    }
};

export type UserPoiFormValuesType = typeof defaultInitialValues;

const validationSchema = Yup.object({
    name: Yup.string().required(validationMessages.required),
    location: Yup.mixed().test({message: validationMessages.required, test: (values) => isValidLocationObject(values)})
});

interface IProps {
    formInitialValues: UserPoiFormValuesType | null;
    onHideForm: () => void;
    targetCoords?: [number, number];
}

export const UserPoiForm = (props: IProps) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const params = useParams<{offerId?: string; propertyId?: string}>();

    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const onSubmit = (values: UserPoiFormValuesType, {resetForm, setSubmitting}: FormikHelpers<UserPoiFormValuesType>) => {
        if (props.targetCoords) {
            const {
                name,
                location: {label}
            } = values;

            const {lat, lng} = convertToLatLngLiteralOfPoland(values.location.coordinates);
            const {lng: targetLng, lat: targetLat} = convertToLatLngLiteralOfPoland(props.targetCoords);

            const distance = countDistance({lat, lng}, {lat: targetLat, lng: targetLng});

            const userPoi = {id: Date.now(), distance, lat, lng, name, tags: {address: label}};

            if (!!props.formInitialValues) {
                dispatch(editUserPoi(props.formInitialValues.location.value, userPoi));
            } else {
                dispatch(addUserPoi(userPoi));
            }

            setSubmitting(false);
            resetForm();

            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.CREATE_MY_POI_SUBMIT, label: name});
            poiAnalytics.algolytics.addMyPoi(viewType, userPoi, params.offerId, params.propertyId);

            props.onHideForm();
        }
    };

    return (
        <Formik
            initialValues={{...defaultInitialValues, ...props.formInitialValues}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                return (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div>
                            <div css={mb(2)}>
                                <Field name="name">
                                    {({field, meta}: FieldProps) => {
                                        return (
                                            <FieldWrapper message={meta.error} fieldState={!!meta.error && meta.touched ? "error" : "default"}>
                                                <Input {...field} placeholder="Nazwa miejsca" />
                                            </FieldWrapper>
                                        );
                                    }}
                                </Field>
                            </div>

                            <PlacesAutocomplete name="location" />
                        </div>

                        <Button css={[w100, mt(2)]} type="submit" disabled={formikProps.isSubmitting} variant="filled_primary">
                            {isMobile ? "Dodaj miejsce" : "Zapisz"}
                        </Button>
                        {!isMobile && (
                            <Button css={[w100, mt(2)]} variant="none_secondary" onClick={props.onHideForm}>
                                Anuluj
                            </Button>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Field, FieldProps, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {mb, mt, w100} from "@pg-design/helpers-css";
import {FieldWrapper, Input} from "@pg-design/inputs-module";
import {countDistance} from "@pg-mono/geo-utils";
import {useIsMobile} from "@pg-mono/hooks";
import {convertToLatLngLiteralOfPoland} from "@pg-mono/open-street-map";

import {IRPStore} from "../../app/rp_reducer";
import {validationMessages} from "../../form/utils/validation_messages";
import {addUserPoi, editUserPoi} from "../actions/manage_user_poi";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {isValidLocationObject} from "../utils/is_valid_location_object";
import {PlacesAutocomplete} from "./PlacesAutocomplete";

const defaultInitialValues = {
    name: "",
    location: {
        label: "",
        value: "" as unknown as number,
        coordinates: [] as number[]
    }
};

export type UserPoiFormValuesType = typeof defaultInitialValues;

const validationSchema = Yup.object({
    name: Yup.string().required(validationMessages.required),
    location: Yup.mixed().test({message: validationMessages.required, test: (values) => isValidLocationObject(values)})
});

interface IProps {
    formInitialValues: UserPoiFormValuesType | null;
    onHideForm: () => void;
    targetCoords?: [number, number];
}

export const UserPoiForm = (props: IProps) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const params = useParams<{offerId?: string; propertyId?: string}>();

    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const onSubmit = (values: UserPoiFormValuesType, {resetForm, setSubmitting}: FormikHelpers<UserPoiFormValuesType>) => {
        if (props.targetCoords) {
            const {
                name,
                location: {label}
            } = values;

            const {lat, lng} = convertToLatLngLiteralOfPoland(values.location.coordinates);
            const {lng: targetLng, lat: targetLat} = convertToLatLngLiteralOfPoland(props.targetCoords);

            const distance = countDistance({lat, lng}, {lat: targetLat, lng: targetLng});

            const userPoi = {id: Date.now(), distance, lat, lng, name, tags: {address: label}};

            if (!!props.formInitialValues) {
                dispatch(editUserPoi(props.formInitialValues.location.value, userPoi));
            } else {
                dispatch(addUserPoi(userPoi));
            }

            setSubmitting(false);
            resetForm();

            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.CREATE_MY_POI_SUBMIT, label: name});
            poiAnalytics.algolytics.addMyPoi(viewType, userPoi, params.offerId, params.propertyId);

            props.onHideForm();
        }
    };

    return (
        <Formik
            initialValues={{...defaultInitialValues, ...props.formInitialValues}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                return (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div>
                            <div css={mb(2)}>
                                <Field name="name">
                                    {({field, meta}: FieldProps) => {
                                        return (
                                            <FieldWrapper message={meta.error} fieldState={!!meta.error && meta.touched ? "error" : "default"}>
                                                <Input {...field} placeholder="Nazwa miejsca" />
                                            </FieldWrapper>
                                        );
                                    }}
                                </Field>
                            </div>

                            <PlacesAutocomplete name="location" />
                        </div>

                        <Button css={[w100, mt(2)]} type="submit" disabled={formikProps.isSubmitting} variant="filled_primary">
                            {isMobile ? "Dodaj miejsce" : "Zapisz"}
                        </Button>
                        {!isMobile && (
                            <Button css={[w100, mt(2)]} variant="none_secondary" onClick={props.onHideForm}>
                                Anuluj
                            </Button>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};
