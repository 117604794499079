import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchPublicTransportRoute} from "../actions/fetch_public_transport_route";

interface IParams {
    poiId: number;
    skipFetching?: boolean;
}

export const usePublicTransportRouteStops = (params: IParams) => {
    const poiRouteWithStops = useSelector((state: IRPStore) => state.maps.poi.poiRoute?.[params.poiId]) || null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!params.skipFetching) {
            dispatch(fetchPublicTransportRoute(params));
        }
    }, [params.poiId]);

    const poiRouteStops = useMemo(() => {
        return poiRouteWithStops?.stops || [];
    }, [poiRouteWithStops]);

    const poiRouteGeometryCoordinates = useMemo(() => {
        return poiRouteWithStops?.geometry?.coordinates || [];
    }, [poiRouteWithStops]);

    return {
        poiRouteWithStops,
        poiRouteStops,
        poiRouteGeometryCoordinates
    };
};
import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchPublicTransportRoute} from "../actions/fetch_public_transport_route";

interface IParams {
    poiId: number;
    skipFetching?: boolean;
}

export const usePublicTransportRouteStops = (params: IParams) => {
    const poiRouteWithStops = useSelector((state: IRPStore) => state.maps.poi.poiRoute?.[params.poiId]) || null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!params.skipFetching) {
            dispatch(fetchPublicTransportRoute(params));
        }
    }, [params.poiId]);

    const poiRouteStops = useMemo(() => {
        return poiRouteWithStops?.stops || [];
    }, [poiRouteWithStops]);

    const poiRouteGeometryCoordinates = useMemo(() => {
        return poiRouteWithStops?.geometry?.coordinates || [];
    }, [poiRouteWithStops]);

    return {
        poiRouteWithStops,
        poiRouteStops,
        poiRouteGeometryCoordinates
    };
};
