import React, {forwardRef, PropsWithChildren} from "react";
import {css} from "@emotion/react";

import {borderRadius, elevation, m} from "@pg-design/helpers-css-strings";

interface IProps extends PropsWithChildren {}

export const MapBottomSlotWrapper = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <div ref={ref} css={wrapperStyle}>
            {props.children}
        </div>
    );
});

const wrapperStyle = css`
    ${m(1.5, "auto")};
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 34rem;
    background: #fff;
    ${borderRadius(2)};
    overflow: hidden;
    ${elevation(4)};
`;
import React, {forwardRef, PropsWithChildren} from "react";
import {css} from "@emotion/react";

import {borderRadius, elevation, m} from "@pg-design/helpers-css-strings";

interface IProps extends PropsWithChildren {}

export const MapBottomSlotWrapper = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <div ref={ref} css={wrapperStyle}>
            {props.children}
        </div>
    );
});

const wrapperStyle = css`
    ${m(1.5, "auto")};
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 34rem;
    background: #fff;
    ${borderRadius(2)};
    overflow: hidden;
    ${elevation(4)};
`;
