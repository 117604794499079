import {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {loadGoogleMapsApi} from "@pg-mono/google-api";

import {IRPStore} from "../../app/rp_reducer";
import {TravelMode} from "../../property/utils/TravelMode";
import {setActivePoiDirections, setPoisDirections} from "../actions/set_poi_travel_directions";
import {IPoi} from "../types/IPoi";
import {IPolylineCoordinates} from "../types/IPolylineCoordinates";
import {IUserPoi} from "../types/IUserPoi";
import {getPolylineCoords} from "../utils/get_polyline_coords";
import {PoiType} from "../utils/PoiType";

export type DistanceResponseType = {type: TravelMode; duration: number; distance: number};
export type GetPoiDirectionsType = (
    poi: IPoi | IUserPoi,
    poiType: PoiType,
    targetCoords: [number, number],
    travelMode: TravelMode
) => Promise<DistanceResponseType | null>;

interface IHook {
    poiDirectionsPolylineCoord: IPolylineCoordinates[];
    getPoiDirections: GetPoiDirectionsType;
}

export const useGooglePoiTravelDirections = (): IHook => {
    const dispatch = useDispatch();

    const activePoiDirections = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiDirections);
    const poisDirections = useSelector((state: IRPStore) => state.maps.travelDirections.poisDirections);

    const getPoiDirections = useCallback(
        async (poi: IPoi | IUserPoi, poiType: PoiType, targetCoords: [number, number], travelMode: TravelMode): Promise<DistanceResponseType | null> => {
            await loadGoogleMapsApi(["routes"]);

            if (window && window.google) {
                // IMPORTANT: be aware of that this API is really expensive - consult usage with business
                const directionsService = new window.google.maps.DirectionsService();

                const directionsPromise = async (request: google.maps.DirectionsRequest): Promise<DistanceResponseType | null> => {
                    return new Promise((resolve) => {
                        directionsService.route(request, (result, status) => {
                            if (status === window.google.maps?.DirectionsStatus.OK && result) {
                                const route = result.routes[0];
                                const duration = route.legs[0].duration?.value || 0;
                                const distance = route.legs[0].distance?.value || 0;

                                let polylineCoords: IPolylineCoordinates[] = [];

                                if (route.legs) {
                                    polylineCoords = getPolylineCoords(route.legs[0]);
                                }

                                dispatch(setPoisDirections({id: poi.id, travelMode, data: {duration, distance, polylineCoords}}));
                                dispatch(setActivePoiDirections({id: poi.id, travelMode, poiType}));

                                return resolve({type: request.travelMode as TravelMode, duration, distance});
                            }
                            return resolve(null);
                        });
                    });
                };

                const [lng, lat] = targetCoords;
                const {lng: poiLng, lat: poiLat} = poi;

                return directionsPromise({
                    destination: new window.google.maps.LatLng(poiLat, poiLng),
                    origin: new window.google.maps.LatLng(lat, lng),
                    travelMode
                });
            }

            return Promise.reject("Google Maps API not defined");
        },
        []
    );

    const poiDirectionsPolylineCoord = useMemo(() => {
        return activePoiDirections ? poisDirections[activePoiDirections.id][activePoiDirections.travelMode].polylineCoords : [];
    }, [activePoiDirections, poisDirections]);

    return {
        poiDirectionsPolylineCoord,
        getPoiDirections
    };
};
import {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {loadGoogleMapsApi} from "@pg-mono/google-api";

import {IRPStore} from "../../app/rp_reducer";
import {TravelMode} from "../../property/utils/TravelMode";
import {setActivePoiDirections, setPoisDirections} from "../actions/set_poi_travel_directions";
import {IPoi} from "../types/IPoi";
import {IPolylineCoordinates} from "../types/IPolylineCoordinates";
import {IUserPoi} from "../types/IUserPoi";
import {getPolylineCoords} from "../utils/get_polyline_coords";
import {PoiType} from "../utils/PoiType";

export type DistanceResponseType = {type: TravelMode; duration: number; distance: number};
export type GetPoiDirectionsType = (
    poi: IPoi | IUserPoi,
    poiType: PoiType,
    targetCoords: [number, number],
    travelMode: TravelMode
) => Promise<DistanceResponseType | null>;

interface IHook {
    poiDirectionsPolylineCoord: IPolylineCoordinates[];
    getPoiDirections: GetPoiDirectionsType;
}

export const useGooglePoiTravelDirections = (): IHook => {
    const dispatch = useDispatch();

    const activePoiDirections = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiDirections);
    const poisDirections = useSelector((state: IRPStore) => state.maps.travelDirections.poisDirections);

    const getPoiDirections = useCallback(
        async (poi: IPoi | IUserPoi, poiType: PoiType, targetCoords: [number, number], travelMode: TravelMode): Promise<DistanceResponseType | null> => {
            await loadGoogleMapsApi(["routes"]);

            if (window && window.google) {
                // IMPORTANT: be aware of that this API is really expensive - consult usage with business
                const directionsService = new window.google.maps.DirectionsService();

                const directionsPromise = async (request: google.maps.DirectionsRequest): Promise<DistanceResponseType | null> => {
                    return new Promise((resolve) => {
                        directionsService.route(request, (result, status) => {
                            if (status === window.google.maps?.DirectionsStatus.OK && result) {
                                const route = result.routes[0];
                                const duration = route.legs[0].duration?.value || 0;
                                const distance = route.legs[0].distance?.value || 0;

                                let polylineCoords: IPolylineCoordinates[] = [];

                                if (route.legs) {
                                    polylineCoords = getPolylineCoords(route.legs[0]);
                                }

                                dispatch(setPoisDirections({id: poi.id, travelMode, data: {duration, distance, polylineCoords}}));
                                dispatch(setActivePoiDirections({id: poi.id, travelMode, poiType}));

                                return resolve({type: request.travelMode as TravelMode, duration, distance});
                            }
                            return resolve(null);
                        });
                    });
                };

                const [lng, lat] = targetCoords;
                const {lng: poiLng, lat: poiLat} = poi;

                return directionsPromise({
                    destination: new window.google.maps.LatLng(poiLat, poiLng),
                    origin: new window.google.maps.LatLng(lat, lng),
                    travelMode
                });
            }

            return Promise.reject("Google Maps API not defined");
        },
        []
    );

    const poiDirectionsPolylineCoord = useMemo(() => {
        return activePoiDirections ? poisDirections[activePoiDirections.id][activePoiDirections.travelMode].polylineCoords : [];
    }, [activePoiDirections, poisDirections]);

    return {
        poiDirectionsPolylineCoord,
        getPoiDirections
    };
};
