import React, {useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, onDesktop, p, pb} from "@pg-design/helpers-css";
import {h100, mt, w100} from "@pg-design/helpers-css-strings";
import {Image} from "@pg-design/image-module";
import {IInfiniteListQuery} from "@pg-design/infinite-list";
import {Loader} from "@pg-design/loader-module";
import {SystemModal} from "@pg-design/modal-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useRoutedModalStateSync} from "../../../hooks/use_routed_modal_state_sync";
import {MapboxInfo} from "../../../maps/components/MapboxInfo";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useUserPreferencesFilters} from "../../../user/hooks/use_user_preferences_filters";
import {hideOfferModal, navToNextModal, navToPrevModal, setModalQueryParams, setOfferModalType, showOfferModal} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {IOfferModalState} from "../../types/OfferModalState";
import {OfferModalLayout} from "../OfferModalLayout";
import {OfferModalHeader} from "./OfferModalHeader";
import {OfferModalList} from "./OfferModalList";
import {OfferModalOfferSwitcher} from "./OfferModalOfferSwitcher";

const offerPinImg = require("../../../maps/images/pins/offer_pin.svg");

interface IProps {
    /**
     * Modal name is required to identify modal in redux.
     * We may have multiple modals in the view.
     */
    modalName: IOfferModalName;
    /**
     * This offersQuery has an offer list, that was used to show offer, that triggered modal.
     * We need a "list query" to navigate to next/prev offer in the list.
     */
    offersQuery: IInfiniteListQuery<IOfferModalOffer>;
    userPreferencePropertyFilters?: ReturnType<typeof useUserPreferencesFilters>;
    hideFilters?: boolean;
    initialQueryParams?: IOfferModalQueryParams;
    onClose?: (modalConfig: Pick<IOfferModalState, "sort" | "filters" | "page">) => void;
}

export type IOfferModalQueryParams = Pick<IOfferModalState, "listingPage" | "listingPageSize">;

/**
 * OfferModal is based on redux.
 * Dispatch redux actions like showOfferDetailsModal to open modal along with some pre-set filters or source section
 */
export function OfferModal(props: IProps) {
    const {modalName, initialQueryParams, userPreferencePropertyFilters, hideFilters, offersQuery, onClose} = props;

    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const pageRef = useRef<null | number>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [headerHeight, setHeaderHeight] = useState<number | null>(null);
    const [filtersModalOpened, setFiltersModalOpened] = useState(false);
    const [mobileSortModalOpened, setMobileSortModalOpened] = useState(false);

    const isOpenStoreState = useAppSelector((state) => state.offerModals[modalName].isOpen);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);
    const sort = useAppSelector((state) => state.offerModals[modalName].sort);
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);
    const sourceSection = useAppSelector((state) => state.offerModals[modalName].sourceSection);
    const page = useAppSelector((state) => state.offerModals[modalName].page);

    const selectedOffer = offersQuery.data.find((offer) => offer.id === offerId);

    const {isOpen} = useRoutedModalStateSync({
        routeParam: "offer-modal",
        value: modalName,
        idOpenStoreState: isOpenStoreState,
        disableOpenAction: !offerId,
        removeRouteParamFromUrlOnFirstMount: true,
        setModalState: (state) => {
            if (state) {
                dispatch(
                    showOfferModal({
                        modalName,
                        offerId,
                        filters,
                        sourceSection: sourceSection || undefined
                    })
                );
            } else {
                dispatch(hideOfferModal({modalName}));
            }
        }
    });

    useEffect(() => {
        if (isOpen && initialQueryParams) {
            const {listingPage, listingPageSize} = initialQueryParams;
            dispatch(
                setModalQueryParams({
                    modalName,
                    listingPage,
                    listingPageSize
                })
            );
        }
    }, [isOpen]);

    useEffect(() => {
        const prevPage = pageRef.current;

        if (prevPage && prevPage < offersQuery.page && offersQuery.isSuccess && !offersQuery.isLoading) {
            const firstOfferId = offersQuery.data[0].id;
            dispatch(
                navToNextModal({
                    modalName,
                    offerId: firstOfferId
                })
            );
        }

        if (prevPage && prevPage > offersQuery.page && offersQuery.isSuccess && !offersQuery.isLoading) {
            const lastOfferId = offersQuery.data[offersQuery.data.length - 1].id;
            dispatch(
                navToPrevModal({
                    modalName,
                    offerId: lastOfferId
                })
            );
        }

        if (offersQuery.isSuccess && !offersQuery.isLoading) {
            pageRef.current = offersQuery.page;
        }
    }, [offersQuery.page, offersQuery.isSuccess, offersQuery.isLoading]);

    const handlers = {
        close: () => {
            if (onClose) {
                onClose({
                    filters,
                    sort,
                    page
                });
            }
            setIsExpanded(false);
            dispatch(setOfferModalType({modalName, type: "tiles"}));
            dispatch(hideOfferModal({modalName}));
        },
        onHeaderResize: (height: number) => {
            setHeaderHeight(height);
        },
        handleFiltersModalVisibility: (value: boolean) => {
            setFiltersModalOpened(value);
        },
        handleSortModalVisibility: (value: boolean) => {
            setMobileSortModalOpened(value);
        }
    };

    const modalsState = {
        handleFiltersModalVisibility: handlers.handleFiltersModalVisibility,
        handleSortModalVisibility: handlers.handleSortModalVisibility,
        filtersModalOpened,
        mobileSortModalOpened
    };

    return (
        <SystemModal css={modal} isOpen={isOpen} onModalClose={handlers.close} variant={isMobile ? "fit" : "medium"} closeButtonStyle={closeButton}>
            {offersQuery.isLoading && (
                <div css={flexAbsoluteCenter}>
                    <Loader size="md" />
                </div>
            )}
            {offersQuery.isSuccess && selectedOffer && (
                <OfferModalLayout id="offer-modal-layout" mobileImageOpened={isExpanded}>
                    <OfferModalHeader
                        modalName={modalName}
                        offers={offersQuery.data}
                        paginationQuery={{page: offersQuery.page, pageSize: offersQuery.pageSize}}
                        totalCount={offersQuery.count}
                        isExpanded={isExpanded}
                        toggleExpand={() => {
                            setIsExpanded(!isExpanded);
                        }}
                        onHeightUpdate={handlers.onHeaderResize}
                        selectedOffer={selectedOffer}
                        handleFiltersModalVisibility={handlers.handleFiltersModalVisibility}
                        handleSortModalVisibility={handlers.handleSortModalVisibility}
                    />
                    {headerHeight && (
                        <ModalContentWrap headerHeight={headerHeight}>
                            {!isMobile && (
                                <div css={imagesWrapper}>
                                    <Image
                                        css={mainImageHolder}
                                        src={selectedOffer.main_image?.m_img_750}
                                        width="352px"
                                        height="200px"
                                        alt={`${selectedOffer.vendor.name}`}
                                        imageStyle={mainImage}
                                    />
                                    <div css={mapImageWrapper}>
                                        <Image
                                            css={mapImageHolder}
                                            alt="lokalizacja inwestycji"
                                            width="100%"
                                            height="100%"
                                            src={selectedOffer.map_image?.m_img_352x647}
                                            src2x={selectedOffer.map_image?.m_img_704x1294}
                                            src3x={selectedOffer.map_image?.m_img_1056x1941}
                                            imageStyle={mapImage}
                                        />
                                        {selectedOffer.map_image?.m_img_352x647 && (
                                            <>
                                                <Image src={offerPinImg} alt="" width="30" height="38" css={offerPinStyle} />
                                                <MapboxInfo />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div css={modalContent}>
                                <OfferModalList
                                    modalName={modalName}
                                    userPreferenceFilters={userPreferencePropertyFilters}
                                    offerDetails={selectedOffer}
                                    hideFilters={hideFilters}
                                    {...modalsState}
                                />
                            </div>
                        </ModalContentWrap>
                    )}
                    <OfferModalOfferSwitcher offersQuery={offersQuery} selectedOffer={selectedOffer} modalName={modalName} />
                </OfferModalLayout>
            )}
        </SystemModal>
    );
}

/*
    Styles
 */
const modal = (theme: Theme) => css`
    inset: 0;
    background-color: ${theme.colors.gray[100]};

    ${onDesktop(css`
        inset: ${calculateRemSize(5)} auto;
        overflow: visible;
        width: 960px;
        background-color: #fff;
    `)};

    @media screen and (min-width: 1700px) {
        width: 1238px;
    }
`;

const closeButton = {
    zIndex: 50,
    inset: `${calculateRemSize(1.5)} ${calculateRemSize(1.5)} auto auto`,

    "@media (min-width: 1024px)": {
        inset: `${calculateRemSize(4)} ${calculateRemSize(3)} auto auto`
    }
};

const ModalContentWrap = styled.div<{headerHeight: number}>`
    ${({headerHeight}) => css`
        padding-top: calc(${headerHeight}px + ${calculateRemSize(1)});
        ${pb(6)};

        ${onDesktop(css`
            display: flex;
            column-gap: ${calculateRemSize(2)};
            ${p(0, 3, 4, 3)}
            overflow: visible;
            min-height: 0;
        `)}
    `}
`;

const modalContent = (theme: Theme) => css`
    flex: 1 1 auto;
    overflow: scroll;
    display: flex;
    background: ${theme.colors.gray[100]};

    @media (min-width: ${theme.breakpoints.md}) {
        background-color: #fff;
    }

    & > div {
        flex: 1;
    }

    ${onDesktop(css`
        overflow: visible;
        background: transparent;
        min-width: 0;
    `)}
`;

const mainImageHolder = css`
    min-height: 200px;
`;

const imagesWrapper = css`
    ${flex("flex-start", "flex-start")};
    ${flexDirection("column")};
    max-width: 352px;
    ${h100};
`;

const mapImageWrapper = css`
    position: relative;
    ${mt(1.5)};
    height: Calc(100% - 200px - ${calculateRemSize(1.5)});
    ${w100};
    max-height: 647px;
`;

const mapImageHolder = css`
    ${h100};
    ${w100};
`;

const offerPinStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

// CSS properties
const mainImage = {
    objectFit: "cover" as const,
    objectPosition: "bottom"
};

const mapImage = {
    objectFit: "cover" as const,
    objectPosition: "center"
};
import React, {useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, onDesktop, p, pb} from "@pg-design/helpers-css";
import {h100, mt, w100} from "@pg-design/helpers-css-strings";
import {Image} from "@pg-design/image-module";
import {IInfiniteListQuery} from "@pg-design/infinite-list";
import {Loader} from "@pg-design/loader-module";
import {SystemModal} from "@pg-design/modal-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useRoutedModalStateSync} from "../../../hooks/use_routed_modal_state_sync";
import {MapboxInfo} from "../../../maps/components/MapboxInfo";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useUserPreferencesFilters} from "../../../user/hooks/use_user_preferences_filters";
import {hideOfferModal, navToNextModal, navToPrevModal, setModalQueryParams, setOfferModalType, showOfferModal} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {IOfferModalState} from "../../types/OfferModalState";
import {OfferModalLayout} from "../OfferModalLayout";
import {OfferModalHeader} from "./OfferModalHeader";
import {OfferModalList} from "./OfferModalList";
import {OfferModalOfferSwitcher} from "./OfferModalOfferSwitcher";

const offerPinImg = require("../../../maps/images/pins/offer_pin.svg");

interface IProps {
    /**
     * Modal name is required to identify modal in redux.
     * We may have multiple modals in the view.
     */
    modalName: IOfferModalName;
    /**
     * This offersQuery has an offer list, that was used to show offer, that triggered modal.
     * We need a "list query" to navigate to next/prev offer in the list.
     */
    offersQuery: IInfiniteListQuery<IOfferModalOffer>;
    userPreferencePropertyFilters?: ReturnType<typeof useUserPreferencesFilters>;
    hideFilters?: boolean;
    initialQueryParams?: IOfferModalQueryParams;
    onClose?: (modalConfig: Pick<IOfferModalState, "sort" | "filters" | "page">) => void;
}

export type IOfferModalQueryParams = Pick<IOfferModalState, "listingPage" | "listingPageSize">;

/**
 * OfferModal is based on redux.
 * Dispatch redux actions like showOfferDetailsModal to open modal along with some pre-set filters or source section
 */
export function OfferModal(props: IProps) {
    const {modalName, initialQueryParams, userPreferencePropertyFilters, hideFilters, offersQuery, onClose} = props;

    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const pageRef = useRef<null | number>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [headerHeight, setHeaderHeight] = useState<number | null>(null);
    const [filtersModalOpened, setFiltersModalOpened] = useState(false);
    const [mobileSortModalOpened, setMobileSortModalOpened] = useState(false);

    const isOpenStoreState = useAppSelector((state) => state.offerModals[modalName].isOpen);
    const offerId = useAppSelector((state) => state.offerModals[modalName].offerId);
    const sort = useAppSelector((state) => state.offerModals[modalName].sort);
    const filters = useAppSelector((state) => state.offerModals[modalName].filters);
    const sourceSection = useAppSelector((state) => state.offerModals[modalName].sourceSection);
    const page = useAppSelector((state) => state.offerModals[modalName].page);

    const selectedOffer = offersQuery.data.find((offer) => offer.id === offerId);

    const {isOpen} = useRoutedModalStateSync({
        routeParam: "offer-modal",
        value: modalName,
        idOpenStoreState: isOpenStoreState,
        disableOpenAction: !offerId,
        removeRouteParamFromUrlOnFirstMount: true,
        setModalState: (state) => {
            if (state) {
                dispatch(
                    showOfferModal({
                        modalName,
                        offerId,
                        filters,
                        sourceSection: sourceSection || undefined
                    })
                );
            } else {
                dispatch(hideOfferModal({modalName}));
            }
        }
    });

    useEffect(() => {
        if (isOpen && initialQueryParams) {
            const {listingPage, listingPageSize} = initialQueryParams;
            dispatch(
                setModalQueryParams({
                    modalName,
                    listingPage,
                    listingPageSize
                })
            );
        }
    }, [isOpen]);

    useEffect(() => {
        const prevPage = pageRef.current;

        if (prevPage && prevPage < offersQuery.page && offersQuery.isSuccess && !offersQuery.isLoading) {
            const firstOfferId = offersQuery.data[0].id;
            dispatch(
                navToNextModal({
                    modalName,
                    offerId: firstOfferId
                })
            );
        }

        if (prevPage && prevPage > offersQuery.page && offersQuery.isSuccess && !offersQuery.isLoading) {
            const lastOfferId = offersQuery.data[offersQuery.data.length - 1].id;
            dispatch(
                navToPrevModal({
                    modalName,
                    offerId: lastOfferId
                })
            );
        }

        if (offersQuery.isSuccess && !offersQuery.isLoading) {
            pageRef.current = offersQuery.page;
        }
    }, [offersQuery.page, offersQuery.isSuccess, offersQuery.isLoading]);

    const handlers = {
        close: () => {
            if (onClose) {
                onClose({
                    filters,
                    sort,
                    page
                });
            }
            setIsExpanded(false);
            dispatch(setOfferModalType({modalName, type: "tiles"}));
            dispatch(hideOfferModal({modalName}));
        },
        onHeaderResize: (height: number) => {
            setHeaderHeight(height);
        },
        handleFiltersModalVisibility: (value: boolean) => {
            setFiltersModalOpened(value);
        },
        handleSortModalVisibility: (value: boolean) => {
            setMobileSortModalOpened(value);
        }
    };

    const modalsState = {
        handleFiltersModalVisibility: handlers.handleFiltersModalVisibility,
        handleSortModalVisibility: handlers.handleSortModalVisibility,
        filtersModalOpened,
        mobileSortModalOpened
    };

    return (
        <SystemModal css={modal} isOpen={isOpen} onModalClose={handlers.close} variant={isMobile ? "fit" : "medium"} closeButtonStyle={closeButton}>
            {offersQuery.isLoading && (
                <div css={flexAbsoluteCenter}>
                    <Loader size="md" />
                </div>
            )}
            {offersQuery.isSuccess && selectedOffer && (
                <OfferModalLayout id="offer-modal-layout" mobileImageOpened={isExpanded}>
                    <OfferModalHeader
                        modalName={modalName}
                        offers={offersQuery.data}
                        paginationQuery={{page: offersQuery.page, pageSize: offersQuery.pageSize}}
                        totalCount={offersQuery.count}
                        isExpanded={isExpanded}
                        toggleExpand={() => {
                            setIsExpanded(!isExpanded);
                        }}
                        onHeightUpdate={handlers.onHeaderResize}
                        selectedOffer={selectedOffer}
                        handleFiltersModalVisibility={handlers.handleFiltersModalVisibility}
                        handleSortModalVisibility={handlers.handleSortModalVisibility}
                    />
                    {headerHeight && (
                        <ModalContentWrap headerHeight={headerHeight}>
                            {!isMobile && (
                                <div css={imagesWrapper}>
                                    <Image
                                        css={mainImageHolder}
                                        src={selectedOffer.main_image?.m_img_750}
                                        width="352px"
                                        height="200px"
                                        alt={`${selectedOffer.vendor.name}`}
                                        imageStyle={mainImage}
                                    />
                                    <div css={mapImageWrapper}>
                                        <Image
                                            css={mapImageHolder}
                                            alt="lokalizacja inwestycji"
                                            width="100%"
                                            height="100%"
                                            src={selectedOffer.map_image?.m_img_352x647}
                                            src2x={selectedOffer.map_image?.m_img_704x1294}
                                            src3x={selectedOffer.map_image?.m_img_1056x1941}
                                            imageStyle={mapImage}
                                        />
                                        {selectedOffer.map_image?.m_img_352x647 && (
                                            <>
                                                <Image src={offerPinImg} alt="" width="30" height="38" css={offerPinStyle} />
                                                <MapboxInfo />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div css={modalContent}>
                                <OfferModalList
                                    modalName={modalName}
                                    userPreferenceFilters={userPreferencePropertyFilters}
                                    offerDetails={selectedOffer}
                                    hideFilters={hideFilters}
                                    {...modalsState}
                                />
                            </div>
                        </ModalContentWrap>
                    )}
                    <OfferModalOfferSwitcher offersQuery={offersQuery} selectedOffer={selectedOffer} modalName={modalName} />
                </OfferModalLayout>
            )}
        </SystemModal>
    );
}

/*
    Styles
 */
const modal = (theme: Theme) => css`
    inset: 0;
    background-color: ${theme.colors.gray[100]};

    ${onDesktop(css`
        inset: ${calculateRemSize(5)} auto;
        overflow: visible;
        width: 960px;
        background-color: #fff;
    `)};

    @media screen and (min-width: 1700px) {
        width: 1238px;
    }
`;

const closeButton = {
    zIndex: 50,
    inset: `${calculateRemSize(1.5)} ${calculateRemSize(1.5)} auto auto`,

    "@media (min-width: 1024px)": {
        inset: `${calculateRemSize(4)} ${calculateRemSize(3)} auto auto`
    }
};

const ModalContentWrap = styled.div<{headerHeight: number}>`
    ${({headerHeight}) => css`
        padding-top: calc(${headerHeight}px + ${calculateRemSize(1)});
        ${pb(6)};

        ${onDesktop(css`
            display: flex;
            column-gap: ${calculateRemSize(2)};
            ${p(0, 3, 4, 3)}
            overflow: visible;
            min-height: 0;
        `)}
    `}
`;

const modalContent = (theme: Theme) => css`
    flex: 1 1 auto;
    overflow: scroll;
    display: flex;
    background: ${theme.colors.gray[100]};

    @media (min-width: ${theme.breakpoints.md}) {
        background-color: #fff;
    }

    & > div {
        flex: 1;
    }

    ${onDesktop(css`
        overflow: visible;
        background: transparent;
        min-width: 0;
    `)}
`;

const mainImageHolder = css`
    min-height: 200px;
`;

const imagesWrapper = css`
    ${flex("flex-start", "flex-start")};
    ${flexDirection("column")};
    max-width: 352px;
    ${h100};
`;

const mapImageWrapper = css`
    position: relative;
    ${mt(1.5)};
    height: Calc(100% - 200px - ${calculateRemSize(1.5)});
    ${w100};
    max-height: 647px;
`;

const mapImageHolder = css`
    ${h100};
    ${w100};
`;

const offerPinStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

// CSS properties
const mainImage = {
    objectFit: "cover" as const,
    objectPosition: "bottom"
};

const mapImage = {
    objectFit: "cover" as const,
    objectPosition: "center"
};
