import {useMemo} from "react";

import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

export const useUniqPublicTransportRouteNames = (routes?: IPublicTransportRoute[] | null) => {
    return useMemo(() => {
        return (
            routes?.filter((currentRoute, currentIndex) => routes?.findIndex((foundedRoute) => currentRoute.name === foundedRoute.name) === currentIndex) || []
        );
    }, [routes]);
};
import {useMemo} from "react";

import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

export const useUniqPublicTransportRouteNames = (routes?: IPublicTransportRoute[] | null) => {
    return useMemo(() => {
        return (
            routes?.filter((currentRoute, currentIndex) => routes?.findIndex((foundedRoute) => currentRoute.name === foundedRoute.name) === currentIndex) || []
        );
    }, [routes]);
};
