import React, {useState} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, calculateRemSize, flexAbsoluteCenter, pl, pr} from "@pg-design/helpers-css-strings";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

const mapboxLogoImg = require("../images/mapbox_logo.svg");

export const MapboxInfo = () => {
    const [isMapInfoOpened, setIsMapInfoOpened] = useState(false);

    const toggleMapInfo = () => {
        setIsMapInfoOpened((prev) => !prev);
    };

    return (
        <>
            <div css={mapVendorLogoWrapperStyle}>
                <Image src={mapboxLogoImg} alt="" width="88" height="23" />
            </div>
            <div css={mapVendorInfoWrapperStyle}>
                {isMapInfoOpened && (
                    <Text variant="info_txt_3" css={mapInfoLinksStyle}>
                        <LinkWithoutHref url="https://www.mapbox.com/about/maps/" css={mapVendorInfoLinkStyle}>
                            © Mapbox
                        </LinkWithoutHref>
                        <LinkWithoutHref url="https://www.openstreetmap.org/about/" css={mapVendorInfoLinkStyle}>
                            © OpenStreetMap
                        </LinkWithoutHref>
                        <LinkWithoutHref url="https://apps.mapbox.com/feedback/" css={mapVendorInfoLinkStyle}>
                            Improve this map
                        </LinkWithoutHref>
                    </Text>
                )}
                <div css={[iconWrapperStyle, isMapInfoOpened && iconWrapperActiveStyle]} onClick={toggleMapInfo}>
                    <InfoIcon size="1.6" />
                </div>
            </div>
        </>
    );
};

const LinkWithoutHref = (props: {children: React.ReactNode; url: string; className?: string}) => {
    const onClick = () => {
        window.open(props.url, "_blank");
    };

    return (
        <div onClick={onClick} className={props.className}>
            {props.children}
        </div>
    );
};

const mapVendorLogoWrapperStyle = css`
    position: absolute;
    left: ${calculateRemSize(1.5)};
    bottom: ${calculateRemSize(1.5)};
    display: inline-flex;
`;

const mapVendorInfoWrapperStyle = css`
    position: absolute;
    right: ${calculateRemSize(1.5)};
    bottom: ${calculateRemSize(1.5)};
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    ${borderRadius(2)};
    height: ${calculateRemSize(3)};
`;

const mapInfoLinksStyle = css`
    ${pl(1)};
`;

const mapVendorInfoLinkStyle = (theme: Theme) => css`
    display: inline-flex;
    cursor: pointer;
    ${pr(0.5)};

    &:hover {
        color: ${theme.colors.highlight};
    }
`;

const iconWrapperStyle = css`
    height: ${calculateRemSize(3)};
    width: ${calculateRemSize(3)};
    ${flexAbsoluteCenter};
    cursor: pointer;
    user-select: none;
`;

const iconWrapperActiveStyle = (theme: Theme) => css`
    background-color: ${theme.colors.gray[200]};
    ${borderRadius(2)};
`;
import React, {useState} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, calculateRemSize, flexAbsoluteCenter, pl, pr} from "@pg-design/helpers-css-strings";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

const mapboxLogoImg = require("../images/mapbox_logo.svg");

export const MapboxInfo = () => {
    const [isMapInfoOpened, setIsMapInfoOpened] = useState(false);

    const toggleMapInfo = () => {
        setIsMapInfoOpened((prev) => !prev);
    };

    return (
        <>
            <div css={mapVendorLogoWrapperStyle}>
                <Image src={mapboxLogoImg} alt="" width="88" height="23" />
            </div>
            <div css={mapVendorInfoWrapperStyle}>
                {isMapInfoOpened && (
                    <Text variant="info_txt_3" css={mapInfoLinksStyle}>
                        <LinkWithoutHref url="https://www.mapbox.com/about/maps/" css={mapVendorInfoLinkStyle}>
                            © Mapbox
                        </LinkWithoutHref>
                        <LinkWithoutHref url="https://www.openstreetmap.org/about/" css={mapVendorInfoLinkStyle}>
                            © OpenStreetMap
                        </LinkWithoutHref>
                        <LinkWithoutHref url="https://apps.mapbox.com/feedback/" css={mapVendorInfoLinkStyle}>
                            Improve this map
                        </LinkWithoutHref>
                    </Text>
                )}
                <div css={[iconWrapperStyle, isMapInfoOpened && iconWrapperActiveStyle]} onClick={toggleMapInfo}>
                    <InfoIcon size="1.6" />
                </div>
            </div>
        </>
    );
};

const LinkWithoutHref = (props: {children: React.ReactNode; url: string; className?: string}) => {
    const onClick = () => {
        window.open(props.url, "_blank");
    };

    return (
        <div onClick={onClick} className={props.className}>
            {props.children}
        </div>
    );
};

const mapVendorLogoWrapperStyle = css`
    position: absolute;
    left: ${calculateRemSize(1.5)};
    bottom: ${calculateRemSize(1.5)};
    display: inline-flex;
`;

const mapVendorInfoWrapperStyle = css`
    position: absolute;
    right: ${calculateRemSize(1.5)};
    bottom: ${calculateRemSize(1.5)};
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    ${borderRadius(2)};
    height: ${calculateRemSize(3)};
`;

const mapInfoLinksStyle = css`
    ${pl(1)};
`;

const mapVendorInfoLinkStyle = (theme: Theme) => css`
    display: inline-flex;
    cursor: pointer;
    ${pr(0.5)};

    &:hover {
        color: ${theme.colors.highlight};
    }
`;

const iconWrapperStyle = css`
    height: ${calculateRemSize(3)};
    width: ${calculateRemSize(3)};
    ${flexAbsoluteCenter};
    cursor: pointer;
    user-select: none;
`;

const iconWrapperActiveStyle = (theme: Theme) => css`
    background-color: ${theme.colors.gray[200]};
    ${borderRadius(2)};
`;
