import {Country} from "../../region/types/Country";

export const getHasCountryPois = (country: Country): boolean => {
    // Don't show content for countries other than specified in the array below - we don't have POI data for other countries
    return [Country.POLAND].includes(country);
};
import {Country} from "../../region/types/Country";

export const getHasCountryPois = (country: Country): boolean => {
    // Don't show content for countries other than specified in the array below - we don't have POI data for other countries
    return [Country.POLAND].includes(country);
};
