import {useState} from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {TravelMode} from "../../property/utils/TravelMode";
import {setActivePoi} from "../actions/set_poi_travel_directions";
import {IUserPoi} from "../types/IUserPoi";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType} from "../utils/PoiType";
import {useGooglePoiTravelDirections} from "./use_google_poi_travel_directions";

const user_pin = require("../images/pins/user_pin.svg");

export const useSinglePoiMarker = (offer?: {geo_point: {coordinates: [number, number]}}) => {
    const dispatch = useDispatch();
    const {getPoiDirections} = useGooglePoiTravelDirections();

    const [markers, setMarkers] = useState<IMarker[]>([]);

    const onSinglePoiChange = (singlePoi: IUserPoi) => {
        const newPois = [
            createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates)(singlePoi, PoiType.USER, user_pin, {
                listenToActivePoiDirections: true
            })
        ];
        getPoiDirections(singlePoi, PoiType.USER, offer?.geo_point.coordinates || [0, 0], TravelMode.DRIVING);
        setMarkers(newPois);
    };

    return {
        markers,
        onSinglePoiChange
    };
};
import {useState} from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {TravelMode} from "../../property/utils/TravelMode";
import {setActivePoi} from "../actions/set_poi_travel_directions";
import {IUserPoi} from "../types/IUserPoi";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType} from "../utils/PoiType";
import {useGooglePoiTravelDirections} from "./use_google_poi_travel_directions";

const user_pin = require("../images/pins/user_pin.svg");

export const useSinglePoiMarker = (offer?: {geo_point: {coordinates: [number, number]}}) => {
    const dispatch = useDispatch();
    const {getPoiDirections} = useGooglePoiTravelDirections();

    const [markers, setMarkers] = useState<IMarker[]>([]);

    const onSinglePoiChange = (singlePoi: IUserPoi) => {
        const newPois = [
            createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates)(singlePoi, PoiType.USER, user_pin, {
                listenToActivePoiDirections: true
            })
        ];
        getPoiDirections(singlePoi, PoiType.USER, offer?.geo_point.coordinates || [0, 0], TravelMode.DRIVING);
        setMarkers(newPois);
    };

    return {
        markers,
        onSinglePoiChange
    };
};
