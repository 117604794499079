// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {setActivePoi} from "../actions/set_poi_travel_directions";
import {useGetOfferDetailPoiByTypeQuery} from "../api/get_offer_detail_poi";
import {activeTransportMarkerUrls} from "../constants/transport_marker_urls";
import {IPoi} from "../types/IPoi";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType, TransportPoiType} from "../utils/PoiType";
import {usePois} from "./use_pois";
import {useTransportPois, useTransportPoisStats} from "./use_transport_pois";

const education_pin = require("../images/pins/education_pin.svg");
const sport_pin = require("../images/pins/sport_pin.svg");
const health_pin = require("../images/pins/health_pin.svg");
const entertainment_pin = require("../images/pins/entertainment_pin.svg");
const shops_pin = require("../images/pins/shops_pin.svg");
const food_pin = require("../images/pins/food_pin.svg");

type IHook = Record<string, IMarker[]>;

export interface IUsePoisMarkersOptions {
    disableInitiallyOpenedPoiId?: boolean;
    checkedPoiTypes: PoiType[];
    radiusInMeters?: number;
}

export const usePoisMarkers = (offer: {geo_point: {coordinates: [number, number]}; id: number}, options?: IUsePoisMarkersOptions): IHook => {
    const dispatch = useDispatch();

    const {data} = useGetOfferDetailPoiByTypeQuery({
        offerId: offer?.id,
        poiTypes: options?.checkedPoiTypes
    });
    const poisFromApi = (data?.poi?.pois || {}) as Record<PoiType, IPoi[] | Record<TransportPoiType, IPoi[]>>;
    const transportPoisFromApi = (data?.poi?.pois.transport || {}) as Record<TransportPoiType, IPoi[]>;
    const {educationPois, entertainmentPois, foodPois, healthPois, sportPois, shopPois} = usePois(poisFromApi, options?.radiusInMeters);
    const {transportPois} = useTransportPois(transportPoisFromApi, {
        longitude: offer.geo_point.coordinates[0],
        latitude: offer.geo_point.coordinates[1],
        radius: options?.radiusInMeters || 3000
    });
    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates);
    const {subwayPoi, railwayPoi, tramPoi, busPoi} = useTransportPoisStats();

    const transportPoiKeys = Object.keys(transportPois || {}) as TransportPoiType[];
    // INFO: This is related to the business requirement to initially open marker InfoWindow for "best" transport POI
    const initiallyOpenedTransportPoiId = options?.disableInitiallyOpenedPoiId ? undefined : subwayPoi?.id || railwayPoi?.id || busPoi?.id || tramPoi?.id;

    const transportPoisMarkers = !!transportPois
        ? transportPoiKeys.reduce((acc, key) => {
              return acc.concat(
                  ...transportPois[key].map((poi) => {
                      if (poi.id === initiallyOpenedTransportPoiId) {
                          return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {initiallyOpened: true}, key);
                      }
                      return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {}, key);
                  })
              );
          }, [] as IMarker[])
        : [];
    const educationPoisMarkers = educationPois.map((poi) => getPoiMarker(poi, PoiType.EDUCATION, education_pin));
    const entertainmentPoisMarkers = entertainmentPois.map((poi) => getPoiMarker(poi, PoiType.ENTERTAINMENT, entertainment_pin));
    const foodPoisMarkers = foodPois.map((poi) => getPoiMarker(poi, PoiType.FOOD, food_pin));
    const healthPoisMarkers = healthPois.map((poi) => getPoiMarker(poi, PoiType.HEALTH, health_pin));
    const sportPoisMarkers = sportPois.map((poi) => getPoiMarker(poi, PoiType.SPORT, sport_pin));
    const shopPoisMarkers = shopPois.map((poi) => getPoiMarker(poi, PoiType.SHOPS, shops_pin));

    return {
        [PoiType.EDUCATION]: educationPoisMarkers,
        [PoiType.ENTERTAINMENT]: entertainmentPoisMarkers,
        [PoiType.FOOD]: foodPoisMarkers,
        [PoiType.HEALTH]: healthPoisMarkers,
        [PoiType.SHOPS]: shopPoisMarkers,
        [PoiType.SPORT]: sportPoisMarkers,
        [PoiType.TRANSPORT]: transportPoisMarkers
    };
};

export const getMarkersByPoiType = (
    poiType: PoiType,
    poi: Record<Exclude<PoiType, PoiType.TRANSPORT>, IPoi[]> & Record<PoiType.TRANSPORT, Record<TransportPoiType, IPoi[]>>,
    offer: {geo_point: {coordinates: [number, number]}; id: number}
): Record<string, IMarker[]> => {
    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => setActivePoi(poi, poiType), offer?.geo_point.coordinates);

    switch (poiType) {
        case PoiType.EDUCATION:
            const educationPoisMarkers = (poi["education"] && poi["education"].map((poi) => getPoiMarker(poi, PoiType.EDUCATION, education_pin))) || [];
            return {[PoiType.EDUCATION]: educationPoisMarkers};
        case PoiType.ENTERTAINMENT:
            const entertainmentPoisMarkers =
                (poi["entertainment"] && poi["entertainment"].map((poi) => getPoiMarker(poi, PoiType.ENTERTAINMENT, entertainment_pin))) || [];
            return {[PoiType.ENTERTAINMENT]: entertainmentPoisMarkers};
        case PoiType.FOOD:
            const foodPoisMarkers = (poi["food"] && poi["food"].map((poi) => getPoiMarker(poi, PoiType.FOOD, food_pin))) || [];
            return {[PoiType.FOOD]: foodPoisMarkers};
        case PoiType.HEALTH:
            const healthPoisMarkers = (poi["health"] && poi["health"].map((poi) => getPoiMarker(poi, PoiType.HEALTH, food_pin))) || [];
            return {[PoiType.HEALTH]: healthPoisMarkers};
        case PoiType.SHOPS:
            const shopPoisMarkers = (poi["shops"] && poi["shops"].map((poi) => getPoiMarker(poi, PoiType.SHOPS, shops_pin))) || [];
            return {[PoiType.SHOPS]: shopPoisMarkers};
        case PoiType.SPORT:
            const sportPoisMarkers = (poi["sport"] && poi["sport"].map((poi) => getPoiMarker(poi, PoiType.SPORT, sport_pin))) || [];
            return {[PoiType.SPORT]: sportPoisMarkers};
        default:
            return {};
    }
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {setActivePoi} from "../actions/set_poi_travel_directions";
import {useGetOfferDetailPoiByTypeQuery} from "../api/get_offer_detail_poi";
import {activeTransportMarkerUrls} from "../constants/transport_marker_urls";
import {IPoi} from "../types/IPoi";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType, TransportPoiType} from "../utils/PoiType";
import {usePois} from "./use_pois";
import {useTransportPois, useTransportPoisStats} from "./use_transport_pois";

const education_pin = require("../images/pins/education_pin.svg");
const sport_pin = require("../images/pins/sport_pin.svg");
const health_pin = require("../images/pins/health_pin.svg");
const entertainment_pin = require("../images/pins/entertainment_pin.svg");
const shops_pin = require("../images/pins/shops_pin.svg");
const food_pin = require("../images/pins/food_pin.svg");

type IHook = Record<string, IMarker[]>;

export interface IUsePoisMarkersOptions {
    disableInitiallyOpenedPoiId?: boolean;
    checkedPoiTypes: PoiType[];
    radiusInMeters?: number;
}

export const usePoisMarkers = (offer: {geo_point: {coordinates: [number, number]}; id: number}, options?: IUsePoisMarkersOptions): IHook => {
    const dispatch = useDispatch();

    const {data} = useGetOfferDetailPoiByTypeQuery({
        offerId: offer?.id,
        poiTypes: options?.checkedPoiTypes
    });
    const poisFromApi = (data?.poi?.pois || {}) as Record<PoiType, IPoi[] | Record<TransportPoiType, IPoi[]>>;
    const transportPoisFromApi = (data?.poi?.pois.transport || {}) as Record<TransportPoiType, IPoi[]>;
    const {educationPois, entertainmentPois, foodPois, healthPois, sportPois, shopPois} = usePois(poisFromApi, options?.radiusInMeters);
    const {transportPois} = useTransportPois(transportPoisFromApi, {
        longitude: offer.geo_point.coordinates[0],
        latitude: offer.geo_point.coordinates[1],
        radius: options?.radiusInMeters || 3000
    });
    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates);
    const {subwayPoi, railwayPoi, tramPoi, busPoi} = useTransportPoisStats();

    const transportPoiKeys = Object.keys(transportPois || {}) as TransportPoiType[];
    // INFO: This is related to the business requirement to initially open marker InfoWindow for "best" transport POI
    const initiallyOpenedTransportPoiId = options?.disableInitiallyOpenedPoiId ? undefined : subwayPoi?.id || railwayPoi?.id || busPoi?.id || tramPoi?.id;

    const transportPoisMarkers = !!transportPois
        ? transportPoiKeys.reduce((acc, key) => {
              return acc.concat(
                  ...transportPois[key].map((poi) => {
                      if (poi.id === initiallyOpenedTransportPoiId) {
                          return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {initiallyOpened: true}, key);
                      }
                      return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {}, key);
                  })
              );
          }, [] as IMarker[])
        : [];
    const educationPoisMarkers = educationPois.map((poi) => getPoiMarker(poi, PoiType.EDUCATION, education_pin));
    const entertainmentPoisMarkers = entertainmentPois.map((poi) => getPoiMarker(poi, PoiType.ENTERTAINMENT, entertainment_pin));
    const foodPoisMarkers = foodPois.map((poi) => getPoiMarker(poi, PoiType.FOOD, food_pin));
    const healthPoisMarkers = healthPois.map((poi) => getPoiMarker(poi, PoiType.HEALTH, health_pin));
    const sportPoisMarkers = sportPois.map((poi) => getPoiMarker(poi, PoiType.SPORT, sport_pin));
    const shopPoisMarkers = shopPois.map((poi) => getPoiMarker(poi, PoiType.SHOPS, shops_pin));

    return {
        [PoiType.EDUCATION]: educationPoisMarkers,
        [PoiType.ENTERTAINMENT]: entertainmentPoisMarkers,
        [PoiType.FOOD]: foodPoisMarkers,
        [PoiType.HEALTH]: healthPoisMarkers,
        [PoiType.SHOPS]: shopPoisMarkers,
        [PoiType.SPORT]: sportPoisMarkers,
        [PoiType.TRANSPORT]: transportPoisMarkers
    };
};

export const getMarkersByPoiType = (
    poiType: PoiType,
    poi: Record<Exclude<PoiType, PoiType.TRANSPORT>, IPoi[]> & Record<PoiType.TRANSPORT, Record<TransportPoiType, IPoi[]>>,
    offer: {geo_point: {coordinates: [number, number]}; id: number}
): Record<string, IMarker[]> => {
    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => setActivePoi(poi, poiType), offer?.geo_point.coordinates);

    switch (poiType) {
        case PoiType.EDUCATION:
            const educationPoisMarkers = (poi["education"] && poi["education"].map((poi) => getPoiMarker(poi, PoiType.EDUCATION, education_pin))) || [];
            return {[PoiType.EDUCATION]: educationPoisMarkers};
        case PoiType.ENTERTAINMENT:
            const entertainmentPoisMarkers =
                (poi["entertainment"] && poi["entertainment"].map((poi) => getPoiMarker(poi, PoiType.ENTERTAINMENT, entertainment_pin))) || [];
            return {[PoiType.ENTERTAINMENT]: entertainmentPoisMarkers};
        case PoiType.FOOD:
            const foodPoisMarkers = (poi["food"] && poi["food"].map((poi) => getPoiMarker(poi, PoiType.FOOD, food_pin))) || [];
            return {[PoiType.FOOD]: foodPoisMarkers};
        case PoiType.HEALTH:
            const healthPoisMarkers = (poi["health"] && poi["health"].map((poi) => getPoiMarker(poi, PoiType.HEALTH, food_pin))) || [];
            return {[PoiType.HEALTH]: healthPoisMarkers};
        case PoiType.SHOPS:
            const shopPoisMarkers = (poi["shops"] && poi["shops"].map((poi) => getPoiMarker(poi, PoiType.SHOPS, shops_pin))) || [];
            return {[PoiType.SHOPS]: shopPoisMarkers};
        case PoiType.SPORT:
            const sportPoisMarkers = (poi["sport"] && poi["sport"].map((poi) => getPoiMarker(poi, PoiType.SPORT, sport_pin))) || [];
            return {[PoiType.SPORT]: sportPoisMarkers};
        default:
            return {};
    }
};
