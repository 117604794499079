import React, {useMemo} from "react";
import {useRef} from "react";
import {css} from "@emotion/react";

import {Select} from "@pg-design/select-module";

import {POI_DISTANCE_DEFAULT_VALUE, poiDistanceSelectOptions} from "../constants/pois_defaults";

interface IPoiSwitcherDistanceProps {
    value?: number;
    onChange: (value: number) => void;
    isBorderless?: boolean;
    options?: {value: number; label: string}[];
}

export const PoiSwitcherDistance = (props: IPoiSwitcherDistanceProps) => {
    const {value, isBorderless, onChange} = props;
    const filtersWrapperRef = useRef<HTMLElement | null>(null);
    const distanceValue = typeof value !== "number" ? POI_DISTANCE_DEFAULT_VALUE : value;

    const distanceOption = useMemo(() => {
        return (props.options || poiDistanceSelectOptions).find((option) => option.value === distanceValue);
    }, [distanceValue, props.options]);

    return (
        <div css={wrapperStyle} ref={(elem) => (filtersWrapperRef.current = elem)}>
            <Select
                value={distanceOption}
                options={props.options || poiDistanceSelectOptions}
                name="poi-distance"
                onChange={(newValue) => {
                    onChange((newValue as {value: number}).value);
                }}
                isBorderless={isBorderless}
                css={selectStyle}
                menuPlacement="top"
            />
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const selectStyle = css`
    flex: 1 1 100%;
`;
import React, {useMemo} from "react";
import {useRef} from "react";
import {css} from "@emotion/react";

import {Select} from "@pg-design/select-module";

import {POI_DISTANCE_DEFAULT_VALUE, poiDistanceSelectOptions} from "../constants/pois_defaults";

interface IPoiSwitcherDistanceProps {
    value?: number;
    onChange: (value: number) => void;
    isBorderless?: boolean;
    options?: {value: number; label: string}[];
}

export const PoiSwitcherDistance = (props: IPoiSwitcherDistanceProps) => {
    const {value, isBorderless, onChange} = props;
    const filtersWrapperRef = useRef<HTMLElement | null>(null);
    const distanceValue = typeof value !== "number" ? POI_DISTANCE_DEFAULT_VALUE : value;

    const distanceOption = useMemo(() => {
        return (props.options || poiDistanceSelectOptions).find((option) => option.value === distanceValue);
    }, [distanceValue, props.options]);

    return (
        <div css={wrapperStyle} ref={(elem) => (filtersWrapperRef.current = elem)}>
            <Select
                value={distanceOption}
                options={props.options || poiDistanceSelectOptions}
                name="poi-distance"
                onChange={(newValue) => {
                    onChange((newValue as {value: number}).value);
                }}
                isBorderless={isBorderless}
                css={selectStyle}
                menuPlacement="top"
            />
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const selectStyle = css`
    flex: 1 1 100%;
`;
