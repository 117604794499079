import React from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {wrapperStyle} from "./MapDivMarker.module.css";

interface IProps {
    text: string;
}

export const MapDivMarker = (props: IProps) => {
    return (
        <Text as="div" variant="info_txt_1" className={classNames(wrapperStyle)}>
            {props.text}
        </Text>
    );
};
import React from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {wrapperStyle} from "./MapDivMarker.module.css";

interface IProps {
    text: string;
}

export const MapDivMarker = (props: IProps) => {
    return (
        <Text as="div" variant="info_txt_1" className={classNames(wrapperStyle)}>
            {props.text}
        </Text>
    );
};
