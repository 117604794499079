import React, {useState} from "react";
import {css, Theme} from "@emotion/react";

import {flex, h100, mb, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {useElementInteractionObserver} from "../../../hooks/use_element_interaction_observer";
import {OpenStreetMapsWithPoi} from "../../../maps/components/map_with_poi/OpenStreetMapsWithPoi";
import {PoiSwitcher} from "../../../maps/components/PoiSwitcher";
import {PoiSwitcherDistance} from "../../../maps/components/PoiSwitcherDistance";
import {SinglePoi} from "../../../maps/components/SinglePoi";
import {POI_DISTANCE_DEFAULT_VALUE} from "../../../maps/constants/pois_defaults";
import {useMapNearbyOffersPois} from "../../../maps/hooks/use_map_nearby_offers_pois";
import {useSinglePoiMarker} from "../../../maps/hooks/use_single_poi_marker";
import {poiAnalytics, PoiGTMModalAction} from "../../../maps/tracking/poi_analytics";
import {getHasCountryPois} from "../../../maps/utils/get_has_country_pois";
import {ViewType} from "../../../view_type/ViewType";
import {IOfferDetail} from "../../types/IOfferDetail";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    className?: string;
    offer: IOfferDetail | IOfferModalOffer;
    area?: {
        center: {
            coordinates: [number, number];
        };
        polygon: {
            coordinates: number[][][];
        };
        region: number;
    };
    property?: {
        id: number;
        for_sale: boolean;
    };
    disablePoiSwitch?: boolean;
    defaultZoom?: number;
    disableClusterMarkers?: boolean;
    onFullscreenClick?: () => void;
}

export const OfferDetailLocationMap = (props: IProps) => {
    const {offer} = props;
    const [poiDistance, setPoiDistance] = useState<number>(POI_DISTANCE_DEFAULT_VALUE);

    // we only need this to be local state, instead of global redux user's POI
    const {markers: userMarkers, onSinglePoiChange} = useSinglePoiMarker(offer);

    const hasCountryPois = getHasCountryPois(offer.region.country);

    const {offerAdditionalPois, defaultPoiTypes, mapBottomSlot} = useMapNearbyOffersPois({
        baseOffer: {
            id: props.offer.id,
            type: props.offer.type,
            stats:
                "stats" in props.offer && props.offer.stats
                    ? {
                          ranges_area_min: "ranges_area_min" in props.offer.stats ? props.offer.stats.ranges_area_min : undefined,
                          ranges_area_max: "ranges_area_max" in props.offer.stats ? props.offer.stats.ranges_area_max : undefined,
                          rooms: "rooms" in props.offer.stats ? props.offer.stats.rooms : undefined
                      }
                    : undefined
        },
        distance: poiDistance,
        showPropertyNumberOnMarker: !!props.property
    });

    const mapWrapperRef = useElementInteractionObserver<HTMLDivElement>({
        callback: () => {
            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_FIRST_INTERACTION});
        },
        once: true
    });

    return (
        <div ref={mapWrapperRef} css={mapContainerStyle} className={props.className}>
            <OpenStreetMapsWithPoi
                offer={{
                    ...props.offer,
                    geo_area: {
                        coordinates: {
                            coordinates: "geo_area" in offer ? offer.geo_area?.coordinates : []
                        }
                    }
                }}
                viewType={props.property ? ViewType.PROPERTY : ViewType.OFFER}
                polygon={props.area && props.area.polygon}
                region={props.area && props.area.region}
                disableInitiallyOpenedPoiId
                mapConfig={{
                    scrollWheelZoom: false,
                    fitBounds: true,
                    fitBoundsDefaultZoom: props.defaultZoom,
                    maxZoom: 18
                }}
                mobilePoiModalTriggerPosition="center"
                customMarkers={userMarkers}
                customPoiMarkers={offerAdditionalPois}
                clusterMarkers={!props.disableClusterMarkers}
                disablePoiSwitch={!hasCountryPois || props.disablePoiSwitch}
                initialPoiTypes={defaultPoiTypes}
                mapBottomSlot={mapBottomSlot}
                css={mapCustomStyles}
                onFullscreenClick={props.onFullscreenClick}
                drawPoiDistance
                onDistanceChange={setPoiDistance}
                showTransportLines
            >
                {({setCheckedPoiTypes, checkedPoiTypes, map, poiDistance, setPoiDistance}) => (
                    <div css={mapRendererWrapper}>
                        <div css={mapRendererMapColumn}>{map}</div>
                        {hasCountryPois && (
                            <div css={mapRendererAside}>
                                <Text as="div" variant="mini_header">
                                    Ważne miejsca
                                </Text>
                                <PoiSwitcher
                                    checkedPoiTypes={checkedPoiTypes}
                                    onChange={setCheckedPoiTypes}
                                    hideHeader
                                    disableCollapsible
                                    css={mapRendererPoiSwitcherStyle}
                                />

                                <Text as="div" variant="mini_header" css={[mt(1), mb(1)]}>
                                    Promień
                                </Text>
                                <PoiSwitcherDistance value={poiDistance} onChange={setPoiDistance} />

                                <Text as="div" variant="mini_header" css={[mt(3)]}>
                                    Moje miejsce
                                </Text>
                                <SinglePoi onChange={onSinglePoiChange} offer={offer} hideHeader disableCollapsible css={mapRendererUserPoiStyle} />
                            </div>
                        )}
                    </div>
                )}
            </OpenStreetMapsWithPoi>
        </div>
    );
};
const mapContainerStyle = (theme: Theme) => css`
    position: relative;
    height: 58rem;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 58.5rem; // to avoid scroll on map sidebar with default params
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 68rem;
    }
`;

const mapRendererWrapper = css`
    ${flex()};
    ${w100};
    ${h100};
`;

const mapRendererMapColumn = css`
    position: relative;
    flex: 1 1 100%;
`;

const mapRendererAside = css`
    display: none;

    ${onDesktop(css`
        display: block;
        background-color: #fff;
        flex: 0 0 30rem;
        ${p(3, 2)};
        overflow: auto;
    `)};
`;

const mapRendererPoiSwitcherStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
    `)};
`;

const mapRendererUserPoiStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        ${p(2, 0, 0, 0)};
    `)};
`;

const mapCustomStyles = css`
    .leaflet-div-icon {
        background-color: transparent;
        border: none;
    }
`;
import React, {useState} from "react";
import {css, Theme} from "@emotion/react";

import {flex, h100, mb, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {useElementInteractionObserver} from "../../../hooks/use_element_interaction_observer";
import {OpenStreetMapsWithPoi} from "../../../maps/components/map_with_poi/OpenStreetMapsWithPoi";
import {PoiSwitcher} from "../../../maps/components/PoiSwitcher";
import {PoiSwitcherDistance} from "../../../maps/components/PoiSwitcherDistance";
import {SinglePoi} from "../../../maps/components/SinglePoi";
import {POI_DISTANCE_DEFAULT_VALUE} from "../../../maps/constants/pois_defaults";
import {useMapNearbyOffersPois} from "../../../maps/hooks/use_map_nearby_offers_pois";
import {useSinglePoiMarker} from "../../../maps/hooks/use_single_poi_marker";
import {poiAnalytics, PoiGTMModalAction} from "../../../maps/tracking/poi_analytics";
import {getHasCountryPois} from "../../../maps/utils/get_has_country_pois";
import {ViewType} from "../../../view_type/ViewType";
import {IOfferDetail} from "../../types/IOfferDetail";
import {IOfferModalOffer} from "../../types/OfferModalOffer";

interface IProps {
    className?: string;
    offer: IOfferDetail | IOfferModalOffer;
    area?: {
        center: {
            coordinates: [number, number];
        };
        polygon: {
            coordinates: number[][][];
        };
        region: number;
    };
    property?: {
        id: number;
        for_sale: boolean;
    };
    disablePoiSwitch?: boolean;
    defaultZoom?: number;
    disableClusterMarkers?: boolean;
    onFullscreenClick?: () => void;
}

export const OfferDetailLocationMap = (props: IProps) => {
    const {offer} = props;
    const [poiDistance, setPoiDistance] = useState<number>(POI_DISTANCE_DEFAULT_VALUE);

    // we only need this to be local state, instead of global redux user's POI
    const {markers: userMarkers, onSinglePoiChange} = useSinglePoiMarker(offer);

    const hasCountryPois = getHasCountryPois(offer.region.country);

    const {offerAdditionalPois, defaultPoiTypes, mapBottomSlot} = useMapNearbyOffersPois({
        baseOffer: {
            id: props.offer.id,
            type: props.offer.type,
            stats:
                "stats" in props.offer && props.offer.stats
                    ? {
                          ranges_area_min: "ranges_area_min" in props.offer.stats ? props.offer.stats.ranges_area_min : undefined,
                          ranges_area_max: "ranges_area_max" in props.offer.stats ? props.offer.stats.ranges_area_max : undefined,
                          rooms: "rooms" in props.offer.stats ? props.offer.stats.rooms : undefined
                      }
                    : undefined
        },
        distance: poiDistance,
        showPropertyNumberOnMarker: !!props.property
    });

    const mapWrapperRef = useElementInteractionObserver<HTMLDivElement>({
        callback: () => {
            poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.MAP_FIRST_INTERACTION});
        },
        once: true
    });

    return (
        <div ref={mapWrapperRef} css={mapContainerStyle} className={props.className}>
            <OpenStreetMapsWithPoi
                offer={{
                    ...props.offer,
                    geo_area: {
                        coordinates: {
                            coordinates: "geo_area" in offer ? offer.geo_area?.coordinates : []
                        }
                    }
                }}
                viewType={props.property ? ViewType.PROPERTY : ViewType.OFFER}
                polygon={props.area && props.area.polygon}
                region={props.area && props.area.region}
                disableInitiallyOpenedPoiId
                mapConfig={{
                    scrollWheelZoom: false,
                    fitBounds: true,
                    fitBoundsDefaultZoom: props.defaultZoom,
                    maxZoom: 18
                }}
                mobilePoiModalTriggerPosition="center"
                customMarkers={userMarkers}
                customPoiMarkers={offerAdditionalPois}
                clusterMarkers={!props.disableClusterMarkers}
                disablePoiSwitch={!hasCountryPois || props.disablePoiSwitch}
                initialPoiTypes={defaultPoiTypes}
                mapBottomSlot={mapBottomSlot}
                css={mapCustomStyles}
                onFullscreenClick={props.onFullscreenClick}
                drawPoiDistance
                onDistanceChange={setPoiDistance}
                showTransportLines
            >
                {({setCheckedPoiTypes, checkedPoiTypes, map, poiDistance, setPoiDistance}) => (
                    <div css={mapRendererWrapper}>
                        <div css={mapRendererMapColumn}>{map}</div>
                        {hasCountryPois && (
                            <div css={mapRendererAside}>
                                <Text as="div" variant="mini_header">
                                    Ważne miejsca
                                </Text>
                                <PoiSwitcher
                                    checkedPoiTypes={checkedPoiTypes}
                                    onChange={setCheckedPoiTypes}
                                    hideHeader
                                    disableCollapsible
                                    css={mapRendererPoiSwitcherStyle}
                                />

                                <Text as="div" variant="mini_header" css={[mt(1), mb(1)]}>
                                    Promień
                                </Text>
                                <PoiSwitcherDistance value={poiDistance} onChange={setPoiDistance} />

                                <Text as="div" variant="mini_header" css={[mt(3)]}>
                                    Moje miejsce
                                </Text>
                                <SinglePoi onChange={onSinglePoiChange} offer={offer} hideHeader disableCollapsible css={mapRendererUserPoiStyle} />
                            </div>
                        )}
                    </div>
                )}
            </OpenStreetMapsWithPoi>
        </div>
    );
};
const mapContainerStyle = (theme: Theme) => css`
    position: relative;
    height: 58rem;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 58.5rem; // to avoid scroll on map sidebar with default params
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 68rem;
    }
`;

const mapRendererWrapper = css`
    ${flex()};
    ${w100};
    ${h100};
`;

const mapRendererMapColumn = css`
    position: relative;
    flex: 1 1 100%;
`;

const mapRendererAside = css`
    display: none;

    ${onDesktop(css`
        display: block;
        background-color: #fff;
        flex: 0 0 30rem;
        ${p(3, 2)};
        overflow: auto;
    `)};
`;

const mapRendererPoiSwitcherStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
    `)};
`;

const mapRendererUserPoiStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        ${p(2, 0, 0, 0)};
    `)};
`;

const mapCustomStyles = css`
    .leaflet-div-icon {
        background-color: transparent;
        border: none;
    }
`;
